import {Box} from '@mui/material';
import React from 'react';
import {useMediaQuery} from 'react-responsive';
// import { Container } from './styles';

const Body = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  return (
    <div style={{width: '100%', height: 'auto'}}>
      <div style={{background: '#ffffff'}}>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <h3>I. Introdução</h3>
            <p>
              Quando você usa a Irê it, você nos confia seus dados pessoais. E
              temos o compromisso de manter essa confiança. Para começar,
              queremos ajudar você a entender nossa política de privacidade.
            </p>
            <br />
            <p>
              Este artigo descreve os dados pessoais que coletamos, como eles
              são usados e compartilhados e as suas opções com relação a esses
              dados.
            </p>
            <br />
            <p>Última alteração: 23 de novembro de 2022</p>
            <p>Entrada em vigor: 29 de novembro de 2022</p>
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div className="blog-post">
            <div className="post-content">
              <h3>II. Visão Geral</h3>
              <p>
                Este artigo descreve como a Irê it coleta e usam dados pessoais.
                Este artigo se aplica a todos os usuários de apps, sites,
                recursos ou outros serviços da Irê it, em todo o território
                brasileiro. Este artigo se aplica especificamente a:
              </p>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Usuários: indivíduos que solicitam ou recebem transporte,
                    incluindo os que recebem transporte solicitado por outra
                    pessoa.
                  </li>
                  <li>
                    Motoristas e mototaxistas parceiros: indivíduos que oferecem
                    ou se inscrevem para oferecer transporte com a Irê it
                    individualmente ou por meio de empresas parceiras de
                    transporte.
                  </li>
                  <li>
                    Destinatários de entrega: indivíduos que solicitam ou
                    recebem alimentos ou outros produtos e serviços.
                  </li>
                  <li>
                    Entregadores parceiros: indivíduos que oferecem ou se
                    inscrevem para oferecer serviços de entrega ou outros
                    serviços com a Irê it.
                  </li>
                </ulist>
              </Box>
              <p>
                Este artigo também se aplica a outras coletas de dados pessoais
                pela Irê it, em conexão com os serviços da Irê it. Por exemplo,
                podemos coletar informações de contato de indivíduos que usem
                contas de clientes da Irê it motoristas & mototaxistas ou de
                proprietários ou colaboradores de empresas parceiros da Irê it
                delivery ou Irê it serviços gerais, ou podemos coletar outros
                dados pessoais em conexão com nossa tecnologia e recursos de
                mapa.
              </p>
              <br />
              <p>
                Todos os indivíduos sujeitos ao presente aviso são denominados
                “usuários” no presente aviso.
              </p>
              <br />
              <p>
                {' '}
                Nossas práticas de tratamento de dados estão sujeitas às leis
                aplicáveis nos locais em que operamos. Isso significa que as
                práticas descritas neste aviso só são aplicadas em um país ou
                região específica se as leis locais permitirem.{' '}
              </p>
              <br />
              <hr />
              <h3>Responsável pelo tratamento dos dados e transferências</h3>
              <p>
                A Irê it é a controladora dos dados pessoais coletados em
                conexão com o uso dos serviços da Irê it em todo o território
                Brasileiro. Nós processamos dados pessoais dentro e fora dos
                Estados Unidos. Os dados pessoais dos usuários fora dos Estados
                Unidos são transferidos com base em mecanismos aprovados sob as
                leis aplicáveis, como as Cláusulas Contratuais Padrão.{' '}
              </p>
            </div>
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <div>
              <h3>III. Coletas e usos de dados</h3>
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                A) Os dados que coletamos
              </h8>
              <br />
              <p>A Irê it coleta:</p>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Dados informados á Irê it pelos usuários, por exemplo
                    durante a criação da conta
                  </li>
                  <li>
                    Dados criados durante o uso de nossos serviços, como de
                    localização, utilização do app e do aparelho
                  </li>
                  <li>
                    Dados de outras fontes, como parceiros da Irê it e terceiros
                    que usam APIs da Irê it
                  </li>
                </ulist>
              </Box>
              <hr />
              <p>
                Os dados a seguir são coletados pela Irê it ou em nome dela:
              </p>
              <br />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                1. Dados informados pelos usuários. Isso inclui:
              </h8>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Perfil do usuário: coletamos dados quando os usuários criam
                    ou alteram suas contas da Irê it. Isso pode incluir nome,
                    e-mail, número de telefone, login e senha, endereço, foto do
                    perfil, informações de pagamento ou dados bancários
                    (inclusive as de confirmação de pagamento relacionadas),
                    documentos de identidade oficiais, incluindo número e imagem
                    da carteira de habilitação, data de nascimento, assinatura e
                    foto. Esses dados também incluem informações do veículo ou
                    seguro dos motoristas parceiros e parceiros de entregas e
                    configurações do usuário. Podemos usar as fotos enviadas
                    pelos motoristas parceiros e/ou parceiros de entregas para
                    confirmar suas identidades, por exemplo, por meio de
                    tecnologias de reconhecimento facial.
                  </li>
                  <li>
                    Checagem de antecedentes e confirmação de identidade:
                    coletamos informações de checagem de antecedentes e
                    confirmação de identidade de motoristas parceiros e
                    parceiros de entregas. Elas podem incluir informações como
                    histórico de direção ou antecedentes criminais (onde
                    permitido por lei) e direito de trabalhar. A coleta disso
                    pode ser feita por um prestador de serviços autorizado em
                    nome da Irê it. Coletamos também a confirmação de identidade
                    dos usuários Irê it delivery para entrega de bebidas
                    alcoólicas.
                  </li>
                  <li>
                    Dados demográficos: podemos coletar dados demográficos sobre
                    os usuários, inclusive por meio de pesquisas com usuários.
                  </li>
                  <li>
                    Conteúdo do usuário: coletamos informações que os usuários
                    enviam quando entram em contato com o suporte ao cliente da
                    Irê it, fazem avaliações ou elogios para outros usuários ou
                    restaurantes parceiros ou entram em contato com a Irê it de
                    outra forma. Isso pode incluir comentários, fotos ou outros
                    registros coletados por usuários.
                  </li>
                </ulist>
              </Box>
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                2. Dados criados durante o uso dos nossos serviços. Isso inclui:
              </h8>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Dados de localização: quando ativado pelo usuário, o recurso
                    nos permite coletar dados de localização precisos ou
                    aproximados do aparelho. Dos motoristas parceiros e parceiro
                    de entregas, a Irê it coleta esses dados quando o app da Irê
                    it motoristas & mototaxistas e Irê it entregador está sendo
                    executado em primeiro plano (app aberto e na tela) ou em
                    segundo plano (app aberto, mas que não está na tela) no
                    aparelho deles. Dos passageiros, destinatários de entrega e
                    locadores, a Irê it coleta esses dados quando o app da Irê
                    it está sendo executado em primeiro plano e em segundo plano
                    no aparelho do usuário. Passageiros, destinatários de
                    entrega e locadores podem usar os apps da Irê it sem
                    permitir que a Irê it colete dados de localização dos seus
                    aparelhos. No entanto, isso pode afetar alguns recursos
                    disponíveis nos apps da Irê it. Por exemplo, o usuário que
                    não ativar a coleta de dados de localização precisará
                    inserir manualmente seu endereço. Além disso, os dados de
                    localização coletados do aparelho de um motorista ou veículo
                    alugado durante a viagem serão conectados com a conta do
                    passageiro ou locador, mesmo se ele não tiver ativado a
                    coleta de dados de localização do seu aparelho, inclusive
                    para fins de geração de recibos, suporte ao cliente,
                    detecção de fraudes, seguro e disputas judiciais.
                  </li>
                  <li>
                    Informações de transações: coletamos informações de
                    transações relacionadas ao uso de nossos serviços, inclusive
                    o tipo de serviço solicitado ou prestado, informações de
                    entrega, a data e hora em que o serviço foi prestado, o
                    valor cobrado, a distância percorrida e a forma de
                    pagamento. Além disso, se alguém usar seu código
                    promocional, podemos associar seu nome a essa pessoa.
                  </li>
                  <li>
                    Dados de utilização: coletamos dados sobre a interação dos
                    usuários com nossos serviços. Isso inclui datas e horários
                    de acesso, recursos do app ou páginas visualizadas,
                    problemas do app e outras atividades do sistema, tipo de
                    navegador e sites ou serviços de terceiros utilizados antes
                    da interação com nossos serviços. Em alguns casos, podemos
                    fazer essa coleta por meio de cookies, pixels, tags e
                    tecnologias similares que criam e mantêm identificadores
                    únicos.
                  </li>
                  <li>
                    Dados do aparelho: podemos coletar informações sobre os
                    aparelhos usados para acessar nossos serviços, inclusive
                    modelos de hardware, endereço IP do aparelho, sistemas
                    operacionais e versões, software, idiomas preferidos,
                    identificadores exclusivos de aparelhos, identificadores de
                    publicidade, números de série, dados de movimento do
                    aparelho e dados da rede móvel.
                  </li>
                  <li>
                    Dados de comunicação: permitimos que os usuários se
                    comuniquem entre si e com a Irê it por meio de apps para
                    aparelhos móveis e sites da Irê it. Por exemplo, permitimos
                    que motoristas parceiros e passageiros, ou parceiros e
                    destinatários de entregas, liguem e enviem mensagens ou
                    outros arquivos entre si (normalmente sem divulgar os
                    números de telefone). Para possibilitar esse serviço, a Irê
                    it recebe alguns dados sobre ligações, mensagens de textos
                    ou outras comunicações, incluindo a data e hora em que elas
                    ocorreram e o conteúdo delas. A Irê it também pode usar
                    esses dados para serviços de suporte ao cliente (inclusive
                    para mediar conflitos entre usuários), para fins de
                    segurança, para melhorar nossos produtos e serviços e para
                    análises.
                  </li>
                  <li>
                    Dados dos veículos alugados: coletamos dados gerados pelos
                    veículos alugados quando estão em uso. Isso inclui data e
                    hora do uso, localização, trajeto e distância percorrida.
                    Dentro do limite permitido por lei, os dados de localização
                    coletados do veículo alugado durante a viagem serão
                    conectados à conta do locador, mesmo se ele não permitir que
                    a Irê it colete os dados de localização do seu aparelho.
                  </li>
                </ulist>
              </Box>
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                3. Dados de outras fontes. Isso inclui:
              </h8>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Feedback do usuário, como avaliações, comentários ou
                    elogios.
                  </li>
                  <li>
                    Usuários que participam dos nossos programas de indicação.
                    Por exemplo, quando um usuário indica alguém, nós recebemos
                    os dados pessoais da pessoa indicada.
                  </li>
                  <li>
                    Proprietários de conta da Irê it que solicitam serviços para
                    outros usuários, ou em nome deles, ou que permitem que
                    usuários solicitem ou recebam serviços por meio de suas
                    contas.
                  </li>
                  <li>
                    Usuários ou outras pessoas que fornecem dados relativos a
                    reclamações ou contestações.
                  </li>
                  <li>
                    Fornecedores que nos ajudam a confirmar a identidade dos
                    usuários, informações de antecedentes e qualificação para a
                    parceria, para fins de regulamentação e segurança.
                  </li>
                  <li>
                    Provedores de seguro, veículo ou serviços financeiros para
                    motoristas parceiros e/ou parceiros de entregas.
                  </li>
                  <li>
                    Empresas de transporte parceiras (para motoristas parceiros
                    e parceiros de entregas que usam nossos serviços por meio de
                    uma conta associada a tal empresa).
                  </li>
                  <li>Fontes publicamente disponíveis.</li>
                  <li>Prestadores de serviços de marketing.</li>
                </ulist>
              </Box>
              <p>
                A Irê it pode combinar os dados coletados dessas fontes com
                outros em sua posse.
              </p>
              <hr />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                B) Como usamos os dados pessoais
              </h8>
              <br />
              <p>
                A Irê it coleta e usa dados para possibilitar transporte,
                entregas e outros produtos e serviços de forma confiável e
                prática. Também usamos os dados que coletamos:
              </p>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Para melhorar a segurança dos nossos usuários e serviços
                  </li>
                  <li>Para suporte ao cliente</li>
                  <li>Para pesquisa e desenvolvimento</li>
                  <li>Para permitir a comunicação entre os usuários</li>
                  <li>
                    Para enviar comunicações, inclusive de marketing, aos
                    usuários
                  </li>
                  <li>Para utilizá-los em procedimentos jurídicos</li>
                </ulist>
              </Box>
              <p>
                A Irê it não vende nem compartilha dados pessoais do usuário com
                terceiros para marketing direto, exceto se houver consentimento
                do usuário.
              </p>
              <hr />
              <p>
                A Irê it usa os dados que coleta para os seguintes fins, entre
                outros:
              </p>
              <br />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                1. Fornecer serviços e recursos. A Irê it usa os dados que
                coleta para oferecer, personalizar, manter e melhorar nossos
                produtos e serviços.
              </h8>
              <br />
              <br />
              <p>Isso inclui usar os dados para:</p>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>Criar e alterar contas de usuários.</li>
                  <li>
                    Confirmar identidade, antecedentes e qualificação de
                    motoristas parceiros e parceiros de entregas.
                  </li>
                  <li>Viabilizar transporte, entregas e outros serviços.</li>
                  <li>
                    Oferecer, processar ou facilitar pagamentos dos nossos
                    serviços.
                  </li>
                  <li>
                    Oferecer, obter, fornecer ou facilitar soluções de seguro,
                    veículos, faturamento ou financeiras relacionadas aos nossos
                    serviços.
                  </li>
                  <li>
                    Acompanhar e compartilhar o progresso das viagens ou
                    entregas.
                  </li>
                  <li>
                    Oferecer recursos que possibilitem que os usuários
                    compartilhem informações com outras pessoas, como elogios de
                    passageiros a motoristas, comentários de destinatários de
                    entregas a restaurantes ou entregadores, indicação de amigos
                    á Irê it, divisão de valor ou compartilhamento de previsão
                    de chegada e localização com contatos.
                  </li>
                  <li>
                    Oferecer recursos para personalizar as contas dos usuários
                    da Irê it, como criação de locais favoritos, e para permitir
                    acesso rápido a destinos anteriores. Podemos, por exemplo,
                    apresentar recomendações personalizadas de restaurantes ou
                    pratos a usuários da Irê it com base em pedidos anteriores.
                  </li>
                  <li>
                    Oferecer recursos de acessibilidade que facilitem o uso de
                    nossos serviços para usuários com deficiências, tais como os
                    que permitem que motoristas com deficiência auditiva ou
                    surdez alertem os passageiros sobre sua condição, que só
                    permitem o envio de mensagens de texto pelos passageiros e
                    que habilitam o recebimento de avisos luminosos de
                    solicitação de viagem em seu aparelho, em vez de avisos
                    sonoros.
                  </li>
                  <li>
                    Realizar operações internas necessárias para prestar nossos
                    serviços, inclusive para solucionar problemas de software e
                    operacionais, conduzir análises de dados, testes e pesquisas
                    e monitorar e analisar tendências de uso e atividade.
                  </li>
                </ulist>
              </Box>
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                2. Segurança. Usamos dados pessoais para ajudar a manter a
                segurança e integridade de nossos serviços e usuários. Isso
                inclui:
              </h8>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Processo de cadastro de passageiros, motoristas parceiros e
                    parceiros de entregas, antes de permitir o uso de nossos
                    serviços e em intervalos subsequentes, inclusive análises de
                    checagem de antecedentes, quando a lei permitir, para ajudar
                    a impedir que motoristas e passageiros que representem risco
                    à segurança usem nossos serviços.
                  </li>
                  <li>
                    Uso de dados dos aparelhos de motoristas parceiros ou
                    parceiros de entregas para ajudar a identificar
                    comportamento perigoso ao volante, como excesso de
                    velocidade, frenagem e aceleração bruscas, e para
                    informá-los sobre práticas de direção mais seguras. Também
                    usamos dados dos aparelhos de parceiros de entregas para
                    confirmar o tipo de veículo que eles usam para entregar os
                    pedidos.
                  </li>
                  <li>
                    Em certas regiões, o uso de informações derivadas de fotos
                    da carteira de habilitação e outras fotos enviadas á Irê it,
                    para fins de segurança. Isso inclui o recurso de confirmação
                    de identidade em tempo real, que solicita que motoristas
                    parceiros e parceiros de entrega compartilhem uma selfie
                    antes de ficar online para ajudar a garantir que a pessoa
                    que usa o app seja a mesma da conta da Irê it que temos em
                    arquivo. Isso também inclui comparar as fotos que temos em
                    arquivo com as fotos (i) de outros usuários para impedir
                    empréstimo de identidade e (ii) de bancos de dados públicos
                    para confirmar a identidade do usuário.
                  </li>
                  <li>
                    Uso de dados do aparelho, localização, perfil, utilização e
                    outros para impedir, detectar e combater fraudes ou
                    atividades que representem risco à segurança.
                  </li>
                  <li>
                    Uso de avaliações e comentários do usuário para incentivar a
                    conformidade com nosso Código de Conduta e como
                    justificativa para desativação de motoristas parceiros e
                    parceiros de entregas com avaliações baixas ou que violaram
                    tal código.
                  </li>
                </ulist>
              </Box>
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                3. Suporte ao cliente A Irê it usa as informações que coleta
                (inclusive gravações de ligações para o suporte ao cliente, com
                aviso e consentimento do usuário) para oferecer suporte ao
                cliente, inclusive para:
              </h8>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Encaminhar perguntas ao profissional de suporte apropriado.
                  </li>
                  <li>
                    Investigar e buscar soluções para as preocupações do
                    usuário.
                  </li>
                  <li>
                    Monitorar e melhorar as respostas e os processos do nosso
                    suporte ao cliente.
                  </li>
                </ulist>
              </Box>
              <br />
              <p>
                4. Pesquisa e desenvolvimento. Podemos usar os dados que
                coletamos para teste, pesquisa, análise, desenvolvimento de
                produtos e aprendizado de máquina para melhorar a experiência do
                usuário. Isso nos permite melhorar e aprimorar a segurança de
                nossos serviços, aumentar nossa capacidade de impedir o uso dos
                nossos serviços para fins ilícitos ou inadequados, desenvolver
                novos recursos e produtos e facilitar soluções financeiras e de
                seguros associadas aos nossos serviços.
              </p>
              <br />
              <p>
                5. Permitir comunicações entre usuários. Por exemplo, um
                motorista parceiro pode enviar uma mensagem ou ligar para um
                passageiro para confirmar o local de partida, ou o passageiro
                pode entrar em contato com o motorista para recuperar um objeto
                perdido, ou um restaurante ou parceiro de entregas pode ligar
                para um destinatário de entrega com informações sobre um pedido.
              </p>
              <br />
              <p>
                6. Marketing. A Irê it pode usar os dados que coleta para
                divulgar nossos serviços a nossos usuários. Isso inclui enviar
                comunicações aos usuários sobre serviços, recursos, promoções,
                sorteios, estudos, pesquisas, notícias, notificações e eventos
                da Irê it.
              </p>
              <br />
              <p>
                Também podemos enviar comunicações a nossos usuários sobre
                produtos e serviços oferecidos por parceiros da Irê it. Por
                exemplo, se um usuário fizer um pedido pela Irê it delivery, ou
                solicitar um serviço pela Irê it serviços gerais, podemos
                oferecer recomendações, promoções ou anúncios sobre pedidos
                similares oferecidos por outros parceiros da Irê it. Embora
                possamos enviar comunicações aos usuários sobre produtos e
                serviços de parceiros da Irê it, não vendemos nem compartilhamos
                dados pessoais dos usuários com tais parceiros ou outros para
                fins de marketing direto ou publicidade, exceto com o
                consentimento dos usuários.
              </p>
              <br />
              <p>
                Podemos usar os dados que coletamos para personalizar as
                comunicações de marketing (incluindo anúncios) que enviamos,
                inclusive com base na localização do usuário, no uso anterior
                dos serviços da Irê it e nas preferências e configurações do
                usuário
              </p>
              <br />
              <p>
                Podemos também enviar comunicações aos usuários com relação a
                eleições, votações, referendos e outros processos políticos
                relacionados a nossos serviços. Por exemplo, a Irê it pode
                avisar alguns usuários por e-mail sobre medidas eleitorais ou
                legislação pendente relacionada à disponibilidade dos serviços
                da Irê it nas regiões desses usuários.
              </p>
              <br />
              <p>
                7. Comunicações sem fins de marketing. A Irê it pode usar os
                dados que coleta para gerar e enviar recibos aos usuários,
                informá-los sobre mudanças em nossos termos, serviços ou
                políticas ou enviar outras comunicações sem a finalidade de
                divulgar os serviços ou produtos da Irê it ou de seus parceiros.
              </p>
              <br />
              <p>
                8. Procedimentos e exigências legais. Podemos usar os dados
                pessoais que coletamos para investigar ou tratar de reclamações
                ou contestações relacionadas ao uso dos serviços da Irê it, ou
                de outra forma permitida pela lei aplicável, ou conforme
                solicitação de reguladores, entidades governamentais e consultas
                oficiais.
              </p>
              <br />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                9. Decisões automatizadas
              </h8>
              <br />
              <p>
                Usamos dados pessoais para tomar decisões automatizadas com
                relação ao uso dos nossos serviços. Isso inclui:
              </p>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Ativar o preço dinâmico, no qual o valor de uma viagem ou
                    preço de entrega dos pedidos da Irê it delivery é
                    determinado segundo fatores que estão sempre mudando, como o
                    tempo estimado e a distância, o trajeto previsto, o tráfego
                    estimado e o número de passageiros e motoristas que utilizam
                    a Irê it em um dado momento.
                  </li>
                  <li>
                    Localizar motoristas parceiros e parceiros de entregas
                    disponíveis para os usuários que solicitam serviços. Os
                    usuários encontram o parceiro com base na disponibilidade,
                    na proximidade e em outros fatores.
                  </li>
                  <li>
                    Determinar avaliações de motoristas parceiros e parceiros de
                    entregas e desativar motoristas parceiros, passageiros e
                    parceiros de entregas com avaliação baixa.
                  </li>
                  <li>
                    Desativar usuários que foram identificados como envolvidos
                    em fraudes ou atividades que possam prejudicar a Irê it,
                    seus usuários ou outras pessoas. Em alguns casos, como
                    quando determinarmos que um usuário está abusando do
                    programa de indicação da Irê it, esse comportamento pode
                    resultar em desativação automática.
                  </li>
                  <li>
                    Usar informações de localização de motoristas e comunicação
                    entre passageiros e motoristas parceiros para identificar
                    taxas de cancelamento recebidas ou induzidas por meio de
                    fraudes. Por exemplo, se usarmos tais informações para
                    determinar que um motorista está atrasando o embarque de um
                    passageiro para induzi-lo a cancelar a viagem, não
                    cobraremos a taxa de cancelamento do passageiro e
                    ajustaremos o valor pago ao motorista para omitir essa taxa.
                  </li>
                </ulist>
              </Box>
              <br />
              <p>
                Para contestar uma desativação resultante desses processos,
                entre em contato com o suporte ao cliente da Irê it.
              </p>
              <hr />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                C) Cookies e tecnologias de terceiros
              </h8>
              <br />
              <p>
                A Irê it e seus parceiros usam cookies e outras tecnologias de
                identificação em seus apps, sites, e-mails e anúncios online
                para os fins descritos neste aviso.
              </p>
              <hr />
              <p>
                Os cookies são pequenos arquivos de texto armazenados em
                navegadores ou aparelhos por sites, apps, mídia online e
                anúncios. A Irê it usa cookies e tecnologias similares para fins
                como:
              </p>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>Autenticar usuários</li>
                  <li>Lembrar preferências e configurações de usuários</li>
                  <li>Determinar a popularidade de um conteúdo</li>
                  <li>Divulgar e medir a eficácia de campanhas de marketing</li>
                  <li>
                    Analisar tendências e tráfego e ter um entendimento geral
                    dos comportamentos online e dos interesses das pessoas que
                    interagem com nossos serviços
                  </li>
                </ulist>
              </Box>
              <p>
                Podemos também permitir que outros prestem serviços de medição e
                análise de público para nós, que mostrem anúncios na Internet em
                nosso nome e que monitorem e relatem o desempenho desses
                anúncios. Essas entidades podem usar cookies, web beacons, SDKs
                e outras tecnologias para identificar os aparelhos em que os
                visitantes usam nossos sites e outros serviços e sites online.
              </p>
              <hr />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                D) Compartilhamento e divulgação de dados
              </h8>
              <br />
              <p>
                Alguns dos produtos, serviços e recursos da Irê it exigem o
                compartilhamento de dados com outros usuários ou a pedido do
                usuário. Podemos também compartilhar dados com nossas afiliadas,
                subsidiárias e parceiras, por motivos jurídicos ou em conexão
                com reclamações ou conflitos.
              </p>
              <hr />
              <p>A Irê it pode compartilhar os dados que coleta:</p>
              <br />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                1. Com outros usuários
              </h8>
              <br />
              <p>Isso inclui compartilhar:</p>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    O nome, avaliação e local de partida e/ou destino dos
                    passageiros com os motoristas parceiros.
                  </li>
                  <li>
                    O nome dos passageiros com outros usuários em uma viagem
                    compartilhada. Os passageiros em viagens compartilhadas
                    também podem ver o local de destino dos outros usuários.
                  </li>
                  <li>
                    Nome, endereço de entrega e informações de pedido dos
                    destinatários de entregas com o parceiro de entregas e o
                    restaurante. Podemos também compartilhar avaliações e
                    comentários, ou outras informações no limite imposto pela
                    lei, com o restaurante parceiro e o parceiro de entregas.
                  </li>
                  <li>
                    Dos motoristas parceiros e parceiros de entregas, podemos
                    compartilhar os dados a seguir com os passageiros,
                    destinatários de entregas e restaurantes parceiros: nome e
                    foto; marca, modelo, cor, placa e foto do veículo;
                    localização (antes e durante a viagem); avaliação média por
                    usuários; número total de viagens; informações de contato
                    (dependendo das leis aplicáveis); e perfil do motorista
                    parceiro ou parceiro de entregas, inclusive elogios e outros
                    comentários enviados por outros usuários. Também oferecemos
                    recibos aos passageiros e destinatários de entregas,
                    contendo informações como detalhamento dos valores cobrados,
                    nome do motorista parceiro ou parceiro de entregas, foto,
                    mapa do trajeto e outras informações dessa natureza exigidas
                    em faturas no país ou região em que o motorista parceiro ou
                    parceiro de entregas opera.
                  </li>
                  <li>
                    Dos participantes do programa de indicação da Irê it,
                    compartilhamos certos dados pessoais de usuários indicados,
                    como total de viagens, com o usuário que fez a indicação, no
                    limite pertinente para a determinação do valor promocional
                    por indicação.
                  </li>
                </ulist>
              </Box>
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                2. Mediante solicitação do usuário
              </h8>
              <br />
              <p>Isso inclui compartilhamento de dados com:</p>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Outras pessoas mediante solicitação do usuário. Por exemplo,
                    compartilhamos a previsão de chegada e localização do
                    usuário com um amigo quando solicitado por esse usuário ou
                    as informações de viagem de um usuário quando ele divide o
                    valor com um amigo.
                  </li>
                  <li>
                    Parceiros comerciais da Irê it. Por exemplo, se um usuário
                    solicitou um serviço por meio de uma parceria ou oferta
                    promocional feita por um terceiro, a Irê it pode
                    compartilhar certos dados com esse terceiro. Isso pode
                    incluir, por exemplo, outros serviços, plataformas, apps ou
                    sites que se integram com nossas APIs; fornecedores de
                    veículo ou prestadores de serviços; aqueles com uma API ou
                    serviço com o qual nos integramos; ou restaurantes parceiros
                    ou outros parceiros comerciais da Irê it e seus usuários com
                    conexão com promoções, concursos ou serviços especializados.
                  </li>
                </ulist>
              </Box>
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                3. Com o público em geral
              </h8>
              <br />
              <p>
                Perguntas ou comentários enviados por usuários em fóruns
                públicos, como blogs e páginas de redes sociais da Irê it, estão
                disponíveis ao público, inclusive dados pessoais que aparecem
                nessas perguntas ou comentários.
              </p>
              <br />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                4. Com o proprietário da conta da Irê it
              </h8>
              <br />
              <p>
                Se um usuário solicitar transporte ou fizer um pedido com uma
                conta de propriedade de outra pessoa, poderemos compartilhar as
                informações desse pedido ou viagem, inclusive dados de
                localização em tempo real, com o proprietário da conta. Por
                exemplo, isso ocorre quando:
              </p>
              <br />
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Um passageiro usa o perfil da Irê it para Empresas do seu
                    empregador ao fazer viagens organizadas pela central Irê it
                  </li>
                  <li>
                    Um motorista parceiro ou parceiro de entregas usa uma conta
                    de propriedade de uma empresa de transporte parceira ou
                    restaurante parceiro ou associada a ela
                  </li>
                  <li>
                    Um passageiro faz uma viagem organizada por um amigo ou em
                    um Perfil Familiar
                  </li>
                </ulist>
              </Box>
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                5. Com as subsidiárias e afiliadas da Irê it
              </h8>
              <br />
              <p>
                Compartilhamos dados com nossas subsidiárias e afiliadas para
                nos ajudar a prestar nossos serviços ou conduzir processamento
                de dados em nosso nome. Por exemplo, a Irê it processa ou
                armazena dados nos Estados Unidos em nome de suas subsidiárias e
                afiliadas internacionais.
              </p>
              <br />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                {' '}
                Com prestadores de serviço e parceiros comerciais da Irê it
              </h8>
              <br />
              <p>
                A Irê it fornece dados a fornecedores, consultores, parceiros de
                marketing, empresas de pesquisa e outros prestadores de serviços
                ou parceiros comerciais. Eles incluem:
              </p>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>Processadores e facilitadores de pagamentos</li>
                  <li>
                    Provedores de checagem de antecedentes e confirmação de
                    identidade (apenas motoristas parceiros e parceiros de
                    entregas)
                  </li>
                  <li>Provedores de armazenamento em nuvem</li>
                  <li>
                    Parceiros e provedores de plataforma de marketing, inclusive
                    serviços de publicidade em redes sociais
                  </li>
                  <li>Provedores de análise de dados</li>
                  <li>
                    Parceiros de pesquisa, inclusive os responsáveis por
                    pesquisas ou projetos em parceria com a Irê it ou em nome
                    dela
                  </li>
                  <li>
                    Fornecedores que ajudam a Irê it a melhorar a segurança dos
                    seus apps
                  </li>
                  <li>
                    Consultores, advogados, contadores e outros prestadores de
                    serviços profissionais
                  </li>
                  <li>Parceiros de veículos</li>
                  <li>Parceiros de seguros e financiamentos</li>
                  <li>Aeroportos</li>
                  <li>Lime e outros prestadores de serviços locais</li>
                  <li>Restaurantes parceiros e/ou seus provedores de PDV</li>
                  <li>
                    Fornecedores de soluções para veículos ou terceiros que
                    sejam fornecedores de veículos
                  </li>
                </ulist>
              </Box>
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                7. Por motivos legais ou no caso de contestações
              </h8>
              <br />
              <p>
                A Irê it pode compartilhar dados pessoais de usuários se
                acreditar que é obrigatório pela lei aplicável, regulamentos,
                licença de operação ou contrato, processo jurídico ou
                solicitação governamental, ou nos casos em que a divulgação seja
                apropriada, por outros motivos, devido a preocupações com a
                segurança ou similares. Isso inclui o compartilhamento de dados
                pessoais com agentes de autoridades policiais/jurídicas, agentes
                de saúde pública e outras autoridades governamentais, aeroportos
                (se exigido pelas autoridades aeroportuárias como condição para
                operar nas instalações do aeroporto), ou outros terceiros
                conforme o necessário para aplicar nossos Termos e Condições,
                contratos de usuário ou outras políticas; para proteger os
                direitos ou a propriedade da Irê it ou os direitos, a segurança
                ou as propriedades de outros; ou em caso de reclamação ou
                conflito com relação ao uso dos nossos serviços. Se você usar o
                cartão de crédito de outra pessoa, podemos ser obrigados por lei
                a compartilhar com o proprietário seus dados pessoais, incluindo
                informações de viagem ou pedido.
              </p>
              <br />
              <p>
                Isso inclui também o compartilhamento de dados pessoais com
                terceiros associados, ou durante negociações de qualquer
                incorporação, venda de ativos da empresa, consolidação ou
                reestruturação, financiamento ou aquisição de todo ou parte de
                nossos negócios por outra empresa.
              </p>
              <br />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                8. Com consentimento
              </h8>
              <br />
              <p>
                A Irê it pode compartilhar os dados pessoais de um usuário de
                outras maneiras diferentes das descritas neste aviso, se
                notificarmos o usuário e ele consentir com o compartilhamento.
              </p>
              <hr />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                E) Retenção e exclusão de dados
              </h8>
              <br />
              <p>
                A Irê it retém dados de perfil do usuário, transações e outros
                dados pessoais enquanto o usuário mantiver sua conta da Irê it.
              </p>
              <br />
              <p>
                A Irê it pode reter alguns dados do usuário após receber a
                solicitação de exclusão da conta se necessário, como para
                cumprir requisitos legais.
              </p>
              <br />
              <hr />
              <p>
                A Irê it reterá o perfil do usuário, transações e outras
                informações pessoais enquanto o usuário mantiver sua conta da
                Irê it. A Irê it também armazenará informações do veículo e da
                verificação de antecedentes (dentro dos limites permitidos por
                lei) dos motoristas parceiros e parceiros de entregas, enquanto
                eles mantiverem a conta da Irê it.
              </p>
              <br />
              <p>
                Usuários e destinatários de entregas podem solicitar a exclusão
                da sua conta pela Central de Suporte da Irê it. Depois de tal
                solicitação, a Irê it excluirá os dados que não é obrigada a
                armazenar para fins de regulamentos, impostos, seguro, disputas
                judiciais ou outras exigências legais. Por exemplo, a Irê it
                reterá dados de localização, dispositivo e utilização para esses
                fins por pelo menos 7 anos. Durante esse período, ela também
                poderá utilizá-los para fins de segurança, prevenção e detecção
                de fraudes, pesquisa e desenvolvimento. Em algumas
                circunstâncias, pode ser que a Irê it não consiga excluir uma
                conta de usuário, por exemplo, se houver valores pendentes na
                conta ou alguma reclamação ou conflito ainda sem resolução.
                Depois que esse problema for resolvido, a Irê it excluirá a
                conta da forma descrita acima.
              </p>
              <br />
              <p>
                A Irê it pode também reter certas informações se for necessário
                para fins de segurança e prevenção de fraudes. Por exemplo, se a
                Irê it desativar a conta de um usuário por causa de um
                comportamento inseguro ou incidentes ligados à segurança, poderá
                reter certas informações sobre essa conta para evitar que tal
                usuário abra uma nova conta Irê it no futuro.
              </p>
              <hr />
              <h8 style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>
                F) Bases para o processamento
              </h8>
              <br />
              <p>
                Coletamos e usamos dados pessoais apenas quando temos bases
                legais para isso. Isso inclui processamento de dados pessoais
                para prestar os serviços e oferecer os recursos solicitados,
                para fins de interesses legítimos da Irê it ou de terceiros,
                para cumprir obrigações legais ou com base em consentimento.
              </p>
              <hr />
              <p>
                Coletamos e usamos dados pessoais apenas quando temos uma ou
                mais bases legais para isso. Estas bases dependem da localização
                dos usuários, mas geralmente incluem processamento de dados
                pessoais:
              </p>
              <br />
              <p>
                a. Para prestar os serviços e oferecer os recursos solicitados
              </p>
              <br />
              <p>
                Para prestar nossos serviços, precisamos coletar e usar certos
                dados pessoais. Isso inclui:
              </p>
              <Box
                boxShadow={3}
                borderRadius={2}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <ulist>
                  <li>
                    Dados do perfil de usuário, usados para estabelecer e manter
                    contas de usuários; confirmar a identidade do usuário; nos
                    comunicar com usuários sobre suas viagens, pedidos e contas;
                    e permitir que os usuários façam pagamentos ou recebam
                    ganhos
                  </li>
                  <li>
                    Informações de antecedentes, usadas para confirmar a
                    qualificação para ser um motorista parceiro ou parceiro de
                    entregas
                  </li>
                  <li>
                    Dados de localização do motorista parceiro e parceiro de
                    entregas, usados para acompanhar viagens e ajudar com a
                    navegação
                  </li>
                  <li>
                    Dados de utilização, necessários para manter, otimizar e
                    melhorar os serviços da Irê it, inclusive para determinar
                    promoções, conectar motoristas e passageiros e calcular
                    custos de viagens e ganhos dos motoristas parceiros
                  </li>
                  <li>Informações relacionadas com o suporte ao cliente</li>
                </ulist>
              </Box>
              <p>
                b. Para fins dos interesses legítimos da Irê it e de outras
                partes
              </p>
              <br />
              <p>
                Isso inclui usar dados pessoais para manter e melhorar a
                segurança de nossos usuários. Por exemplo, usamos dados pessoais
                para evitar o uso de nossos serviços por usuários que tenham
                apresentado comportamentos inapropriados ou perigosos, como ao
                guardar dados de usuários banidos para evitar que eles usem os
                apps da Irê it. Também utilizamos dados de uso para evitar que
                os passageiros façam viagens com motoristas com maior risco de
                conflito (ex.: porque antes foram alvos de queixas de outros
                usuários).
              </p>
              <br />
              <p>
                Isso também inclui fins como combate a fraudes; melhoria dos
                nossos serviços, marketing direto, pesquisa e desenvolvimento; e
                aplicação dos Termos e Condições da Irê it.
              </p>
              <br />
              <p>
                Além disso, isso inclui usar dados pessoais no limite necessário
                aos interesses de outras pessoas ou do público em geral, como
                quando relacionados à ações judiciais ou comunicações de
                sinistro e para proteção de direitos e segurança de outros.
              </p>
              <br />
              <p>c. Para cumprir as obrigações jurídicas da Irê it</p>
              <br />
              <p>
                Por exemplo, a Irê it está sujeita a leis e regulamentações em
                diversas cidades que exigem que ele colete e armazene dados
                sobre as viagens de nossos usuários e forneça cópias de tais
                dados ao governo e outras autoridades. Coletamos e usamos dados
                pessoais para cumprir tais leis.
              </p>
              <br />
              <p>
                A Irê it também pode compartilhar dados com autoridades
                policiais/jurídicas com relação a atos criminosos ou ameaças à
                segurança pública ou solicitações de terceiros em conformidade
                com processos judiciais.
              </p>
              <br />
              <p>d. Com consentimento</p>
              <br />
              <p>
                A Irê it pode coletar e usar dados pessoais com base no
                consentimento do usuário. Por exemplo, coletamos dados pessoais
                por meio de pesquisas voluntárias. As respostas a essas
                pesquisas são coletadas com seu consentimento e serão excluídas
                assim que não forem mais necessárias ao propósito para o qual
                foram coletadas.
              </p>
              <br />
              <p>
                Um usuário que consentiu com a coleta e uso de seus dados
                pessoais pode revogar o consentimento quando quiser. No entanto,
                o usuário não poderá usar nenhum serviço ou recurso que exija a
                coleta ou uso desses dados pessoais.
              </p>
            </div>
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <h3>IV. Opções e transparência</h3>
            <p>
              A Irê it permite que os usuários acessem e controlem os dados que
              a Irê it coleta, inclusive por meio de:
            </p>
            <Box
              boxShadow={3}
              borderRadius={2}
              style={{
                marginLeft: 10,
                marginRight: 10,
                padding: 10,
                marginBottom: 10,
              }}
            >
              <ulist>
                <li>Configurações no app</li>
                <li>Permissões do aparelho</li>
                <li>Páginas de avaliações no app</li>
                <li>No recebimento de comunicações de marketing</li>
              </ulist>
            </Box>
            <p>
              A Irê it também permite que os usuários solicitem acesso ao seus
              dados ou cópias dos mesmos, alterações ou atualizações em suas
              contas, exclusão de suas contas ou que a Irê it restrinja seu
              processamento de dados pessoais do usuário.
            </p>
            <hr />
            <p>A. Configurações da conta</p>
            <br />
            <p>
              Os menus de configuração do app da Irê it para passageiros
              oferecem a eles a capacidade de configurar ou alterar as
              preferências de compartilhamento de localização e as preferências
              para receber notificações da Irê it no aparelho. Informações sobre
              essas configurações, procedimento para definir ou alterar essas
              configurações e o efeito de desativá-las são descritos abaixo.
            </p>
            <br />
            <p>• Dados de localização</p>
            <br />
            <p>
              A Irê it usa os serviços de localização do aparelho do passageiro
              para que seja mais fácil solicitar uma viagem quando ele precisar.
              Os dados de localização nos ajudam a aprimorar serviços, como
              partidas, navegação e suporte ao cliente.
            </p>
            <br />
            <p>
              Pelo menu Configurações {'>'} Apps {'>'} Gerenciar Apps {'>'}{' '}
              Permissões do app {'>'} Irê it, os passageiros podem ativar ou
              desativar a coleta de dados de localização dos aparelhos por parte
              da Irê it. Os passageiros e destinatários de entregas podem usar
              as configurações em seus aparelhos para permitir ou cancelar a
              permissão de tais coletas.
            </p>
            <br />
            <p>• Compartilhar a localização em tempo real (passageiros)</p>
            <br />
            <p>
              Passageiros que permitem que a Irê it colete dados de localização
              do aparelho também podem permitir que a Irê it compartilhe sua
              localização com o motorista, desde a solicitação até o início da
              viagem. Isso pode ajudar a melhorar os embarques para motoristas e
              passageiros, especialmente em áreas muito movimentadas.
            </p>
            <br />
            <p>
              Os passageiros podem ativar ou desativar esse recurso permitindo
              ou negando a permissão da localização atual do aparelho pelo menu
              Configurações {'>'} Apps {'>'} Gerenciar Apps {'>'} Permissões do
              app {'>'} Irê it
            </p>
            <br />
            <p>• Compartilhar a localização em tempo real (passageiros)</p>
            <br />
            <p>
              Os passageiros que permitirem que a Irê it colete dados de
              localização do seu aparelho também podem ativar o recurso
              “Compartilhamento de dados para emergência”, que compartilha dados
              com serviços de emergência da polícia, bombeiros e ambulâncias.
              Esses dados incluem a localização aproximada no momento em que a
              ligação de emergência foi feita; informações de marca, modelo, cor
              e placa do carro; o nome e número de telefone do passageiro;
              locais de partida e destino; e o nome do motorista.
            </p>
            <br />
            <p>
              Por questões de segurança, esse recurso não pode ser desativado
            </p>
            <br />
            <p>• Avisos: atualizações de conta e viagem</p>
            <br />
            <p>
              A Irê it envia aos usuários avisos sobre o status da viagem e
              atualizações relacionadas à atividade em sua conta. Essas
              notificações são necessárias para o uso do app da Irê it e não
              podem ser desativadas.{' '}
            </p>
            <br />
            <p>• Avisos: descontos e notícias</p>
            <br />
            <p>
              Os usuários podem permitir que a Irê it envie notificações push
              sobre descontos e notícias da Irê it. As notificações push podem
              ser ativadas ou desativadas no menu Configurações {'>'} Apps {'>'}{' '}
              Gerenciar Apps {'>'} Irê it {'>'} Notificações.
            </p>
            <br />
            <p>B. Permissões do aparelho</p>
            <br />
            <p>
              A maioria das plataformas de aparelhos móveis (iOS, Android, etc.)
              definiu que certos tipos de dados do aparelho não podem ser
              acessados por apps sem a permissão do proprietário. Essas
              plataformas usam diferentes formas de obtenção dessas permissões.
              Os aparelhos iOS notificam os usuários na primeira vez que o app
              da Irê it solicita permissão para acessar certos tipos de dados e
              oferecem aos usuários a opção de conceder ou recusá-la. Os
              aparelhos Android notificam os usuários sobre as permissões que o
              app da Irê it pede antes de você usá-lo pela primeira vez. Ao usar
              o app, você concede tal permissão.
            </p>
            <br />
            <p>C. Visualização de avaliações</p>
            <br />
            <p>
              Depois de cada viagem, os motoristas e passageiros podem avaliar
              uns aos outros em uma escala de 1 a 5. A média dessas avaliações é
              associada à conta do usuário e exibida aos outros usuários para os
              quais eles oferecem ou dos quais recebem serviços. Por exemplo, os
              motoristas podem ver a avaliação dos passageiros dos quais recebem
              solicitações de viagem, e os passageiros podem ver a avaliação
              desses motoristas.
            </p>
            <br />
            <p>
              Esse sistema de avaliação recíproca faz com que cada pessoa seja
              responsável por seu comportamento. A responsabilidade ajuda a
              criar um ambiente respeitoso e seguro para motoristas e
              passageiros. Os passageiros podem ver a média de avaliações deles
              no menu principal do app da Irê it. Os motoristas podem ver a
              média de avaliações deles ao abrir o app de parceiro.
            </p>
            <br />
            <p>
              Os parceiros de entregas também são avaliados pelos destinatários
              das entregas e restaurantes parceiros.{' '}
            </p>
            <br />
            <p>D. Não recebimento de comunicações de marketing</p>
            <br />
            <p>
              Os usuários podem optar por não receber e-mails promocionais da
              Irê it entrando em contato com o suporte através do email
              suporte@ireit.com.br. Os usuários também podem seguir as
              instruções de cancelamento de assinatura contidas nessas mensagens
              para optar por não receber e-mails e outras mensagens da Irê it.
              Podemos ainda enviar outros tipos de mensagens, como recibos de
              viagens ou informações da conta, aos usuários que optaram por
              cancelar as comunicações promocionais.
            </p>
            <br />
            <p>E. Solicitações de dados do usuário</p>
            <br />
            <p>
              A Irê it oferece várias maneiras para os usuários conhecerem e
              controlarem seus dados e enviarem dúvidas e comentários sobre o
              tratamento deles pela Irê it. Para fazer uma solicitação, basta
              enviar um email para o suporte@ireit.com.br.
            </p>
            <div className="uli single-widget">
              <ulist>
                <li>
                  Acesso aos dados: os usuários podem pedir uma explicação sobre
                  os dados que coletamos e como eles são usados.
                </li>
                <li>
                  Recebimento de dados: os usuários podem solicitar uma cópia
                  dos dados que a Irê it coleta deles com consentimento ou
                  conforme necessário para prestar nossos serviços.
                </li>
                <li>
                  Alteração ou atualização de dados: os usuários podem editar
                  nome, número de telefone, endereço de e-mail, forma de
                  pagamento e foto associados à sua conta ao acessar o menu
                  “Configurações” nos apps da Irê it ou pelo portal do motorista
                  parceiro. Eles também podem pedir para que a Irê it altere ou
                  atualize seus dados, inclusive nos casos em que acreditarem
                  que eles estejam errados ou incompletos.
                </li>
                <li>
                  Exclusão de dados: os usuários podem solicitar a exclusão da
                  sua conta quando quiserem, entrando em contato pelo email
                  suporte@ireit.com.br.
                </li>
                <li>
                  Objeções, restrições e reclamações: os usuários podem
                  solicitar a interrupção ou a limitação do nosso uso de todos
                  os seus dados pessoais ou de parte deles. A Irê it pode
                  continuar a processar dados após tal objeção ou solicitação,
                  no limite exigido ou permitido por lei.
                </li>
              </ulist>
            </div>
            <p>
              Além disso, dependendo da localização, os usuários podem ter o
              direito de registrar uma reclamação relacionada ao tratamento dos
              seus dados pessoais junto à autoridade de proteção de dados do seu
              país.
            </p>
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
            marginBottom: 40,
          }}
        >
          <div>
            <h3>V. Alterações deste artigo</h3>
            <p2>
              Ocasionalmente, podemos alterar este artigo. Usar nossos serviços
              depois de uma alteração constitui um consentimento com o artigo
              alterado, até o limite permitido por lei.
            </p2>
            <br />
            <hr />
            <p>
              Ocasionalmente, podemos alterar este artigo. Se fizermos
              alterações significativas, notificaremos com antecedência os
              usuários sobre elas pelos apps da Irê it ou outros meios, como
              e-mail. Recomendamos que os usuários revejam periodicamente este
              artigo para saber as informações mais recentes sobre nossas
              práticas de privacidade.
            </p>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Body;
