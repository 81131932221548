import React from 'react';
import {useMediaQuery} from 'react-responsive';
import helpimage from '../../Assets/mesa-header-bg.jpg';

const Hearder = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  return (
    <div>
      {isVerySmall || isSmall ? (
        <div
          style={{
            width: '100%',
            height: 300,
            marginTop: 60,
            marginBottom: 0,
            display: 'flex',
            backgroundImage: `url(${helpimage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              width: '100%',
              fontSize: 36,
              color: '#FFFFFF',
              marginTop: 5,
            }}
          >
            Central de ajuda
          </p>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: 650,
            marginTop: 10,
            display: 'flex',
            backgroundImage: `url(${helpimage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            alignItems: 'flex-start',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              width: '100%',
              fontSize: 48,
              color: '#FFFFFF',
              marginTop: 60,
            }}
          >
            Central de ajuda
          </p>
        </div>
      )}
    </div>
  );
};

export default Hearder;
