/* eslint-disable react/prop-types */
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {Box} from '@mui/system';
import React, {useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import JsxParser from 'react-jsx-parser';
import {Link} from 'react-router-dom';

const Body = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  const [currentAppList, setCurrentAppList] = useState([]);
  const [currentVgList, setCurrentVgList] = useState([]);
  const [currentPdList, setCurrentPdList] = useState([]);
  const [currentMMList, setCurrentMMList] = useState([]);
  const [currentLojasList, setCurrentLojasList] = useState([]);

  useEffect(() => {
    setCurrentAppList([
      {
        _id: 1,
        bopen: false,
        question: 'Como faço para utilizar os serviços da Irê it?',
        anwser:
          'Para utilizar os serviços de carona, delivery e entregas da Irê it. Basta baixar nosso app na <Link style={{color: "#ff6600", fontWeight: "bold", textDecoration: "none"}} to="/users">Play store</Link> para dispositivos android ou na <Link style={{color: "#ff6600", fontWeight: "bold", textDecoration: "none"}} to="/users">Apple Store</Link> para dispositovs IOS.',
      },
      {
        _id: 2,
        bopen: false,
        question: 'Quais dados são necessários para cadastrar na Irê it?',
        anwser:
          'Dados obrigatorios: <p style={{fontWeight: "bold"}}> CPF, Celular </p> E um desses itens: <p style={{fontWeight: "bold"}}> Email, Conta do facebook, Conta Apple. </p> Ainda pedimos alguns dados adicionais como: Data de nascimento, Nome e genêro. Caso você forneça esses dados adicionais, a Irê it irá entender o seu perfil e irá moldar nossos serviços da melhor forma para você aproveitar o máximo da nossa plataforma.',
      },
      {
        _id: 3,
        bopen: false,
        question: 'Posso alterar ou apagar meus dados?',
        anwser:
          'Os dados pessoais podem ser apagados permanentemente após sua conta ser deletada. Caso queira mudar os dados, basta acessar seu perfil dentro do App Irê it.',
      },
      {
        _id: 4,
        bopen: false,
        question: 'Alguns serviços da Irê it não aparecem para mim.',
        anwser:
          'Os serviços da Irê it são indepedentes, algumas cidades ou regiões podem ter apenas um dos serviços da Irê it como ao mesmo tempo pode ter todos. Mas caso queira que algum serviço específico seja implementado em sua cidade, faça o seguinte: <p style={{fontWeight: "bold"}}> Abra o app Irê it - Vá ao menu ajuda - Tenho uma Ideia para melhorar o App - Descreva o serviço que você deseja e a cidade. </p> Assim a Irê it irá analisar e nossa equipe irá fazer o melhor para levarmos o serviço para você.',
      },
    ]);
    setCurrentVgList([
      {
        _id: 1,
        bopen: false,
        question: 'Tive problemas em minha viagem. O que fazer?',
        anwser:
          'Após a viagem ser finalizada, você pode ir em seu histórico de viagens e clicar na viagem. Após isso, clique no botão "Reportar problema" e descrever o que aconteceu. A Irê it irá entrar em contato por email com você para os próximos passos.',
      },
      {
        _id: 2,
        bopen: false,
        question: 'O que são viagens com paradas?',
        anwser:
          'As viagens com paradas são aquelas que você deseja passar em mais de um lugar. Mas atenção, cada viagem permite apenas 3 minutos em cada parada, utilize ela apenas em casos que você não irá demorar muito em um lugar, ou o motorista poderá cancelar a viagem.',
      },
      {
        _id: 3,
        bopen: false,
        question: 'Apareceu uma cobrança indevida da Irê it no meu cartão?',
        anwser:
          'Entre em contato em nosso <Link style={{color: "#25D366", fontWeight: "bold", textDecoration: "none"}} to="/users">whatsapp</Link>, informe o valor que foi cobrado. A Irê it irá indentificar em que serviço a transação foi feita. Caso não haja, o valor será extornado.',
      },
    ]);
    setCurrentPdList([
      {
        _id: 1,
        bopen: false,
        question: 'Como faço para cancelar o pedido?',
        anwser:
          'A Irê it oferece um prazo de 10 minutos para o restaurante aceitar o pedido. Após esse tempo aparecerá um botão para você cancelar o seu pedido. Caso o restaurante tenha começado a preparar, você precisa entrar em contato com o estabelecimento. E caso o pedido esteja demorando muito acima do prazo dado pelo restaurante, entre em contato com o nosso time pelo <Link style={{color: "#25D366", fontWeight: "bold", textDecoration: "none"}} to="/users">whatsapp</Link>.',
      },
      {
        _id: 2,
        bopen: false,
        question: 'Tive um problema com o meu pedido, o que eu faço?',
        anwser:
          'Caso seu pedido tenha chegado com algum problema, entre em contato com a nossa equipe e descreva o que ocorreu.',
      },
      {
        _id: 3,
        bopen: false,
        question: 'O entregado me pediu um código? Onde eu encontro?',
        anwser:
          'Para garantir que o pedido foi entregue, a Irê it oferece um código na pagina do pedido no app. Lá você pode encontrar o campo "Codigo de entrega", você informa ele para o entregador garantir que o pedido foi entregue a você.',
      },
      {
        _id: 4,
        bopen: false,
        question: 'Meu pedido está atrasado. O que eu faço?',
        anwser:
          'A Irê it fornece o contato do restaurante no app. Caso você entrando em contato e mesmo assim não resolvendo. Fale com a nossa equipe.',
      },
    ]);
    setCurrentMMList([
      {
        _id: 1,
        bopen: false,
        question: 'Quais são os requisitos para dirigir pelo app da Irê it?',
        anwser:
          'Você deve ter acesso a um veículo conforme o padrão uber e enviar os documentos necessários (CNH), inclusive uma carteira de habilitação válida. Os motoristas também devem passar pelo processo de segurança e ter pelo menos um ano de experiência com carteira de habilitação.',
      },
      {
        _id: 2,
        bopen: false,
        question: 'Preciso ter meu próprio carro?',
        anwser:
          'A Irê it busca sempre parceria com as locadoras para oferecer o melhor preço para você. Entre em contato com a nossa equipe para sabe a locadora mais próximo a você.',
      },
      {
        _id: 3,
        bopen: false,
        question: 'Posso dirigir em outros aplicativos?',
        anwser: 'Sim. A Irê it não possui restrições de exclusividade.',
      },
      {
        _id: 4,
        bopen: false,
        question: 'Como é feito o repasse das viagens?',
        anwser:
          'O valor é repassado semanalmente. A Irê it fecha a fatura da semana no domingo. E repassa o valor na quarta-feira da semana caso o valor de viagens pagas pelo app forem maior que presencialmente. Caso contrário a Irê it irá enviar um boleto para seu email e telefone cadastrado.',
      },
      {
        _id: 5,
        bopen: false,
        question: 'Como saber meu ganho em uma viagem?',
        anwser:
          'Vá em histórico e clique na viagem, lá você poderá acessar detalhes do pagamento da corrida. A Irê it tambem oferece telas para você acompanhar seus ganhos semanais e mensais.',
      },
      {
        _id: 6,
        bopen: false,
        question: 'Posso digir com carro e moto na mesma conta?',
        anwser:
          'Sim, você pode dirigir, fazer entregas e muito mais pela Irê it. Basta adicionar o veículo e selecionar ele. E dependendo do tipo de veículo, você poderá escolher as categorias que irá ficar online.',
      },
    ]);
    setCurrentLojasList([
      {
        _id: 1,
        bopen: false,
        question: 'Como cadastrar a minha loja na Irê it?',
        anwser:
          'Acesse a pagina <Link style={{color: "#FF6600", fontWeight: "bold", textDecoration: "none"}} to="/users">Seja nosso parceiro</Link> e preencha o formulário. A Irê it irá entrar em contato com você nas próximas 24 horas.',
      },
    ]);
  }, []);

  const handleClick = (i, id, open, list, setList) => {
    let list2 = list.map(item => {
      if (item._id === id) {
        return {...item, bopen: open};
      } else {
        return item;
      }
    });
    setList(list2);
  };

  const Listquestion = ({list, setList}) => {
    if (list.length > 0) {
      let mylist = list.map((item, index) => {
        return (
          <div
            key={item._id}
            style={{
              width: '100%',
              borderRadius: 30,
              marginTop: 25,
            }}
          >
            <Box
              boxShadow={3}
              style={{
                width: '100%',
                backgroundColor: '#ffffff',
              }}
            >
              <ListItem
                style={{
                  backgroundColor: '#f8f8f8',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemText
                  style={{
                    width: isVerySmall || isSmall ? '90%' : '70%',
                    textOverflow: 'ellipsis',
                    color: '#393939',
                  }}
                  primaryTypographyProps={{fontWeight: 'bold'}}
                  primary={item.question}
                />
                {list.find(o => o._id === item._id).bopen ? (
                  <IconButton
                    onClick={() =>
                      handleClick(
                        index,
                        item._id,
                        !list.find(o => o._id === item._id).bopen,
                        list,
                        setList,
                      )
                    }
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                  >
                    <ExpandLess />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      handleClick(
                        index,
                        item._id,
                        !list.find(o => o._id === item._id).bopen,
                        list,
                        setList,
                      )
                    }
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                  >
                    <ExpandMore />
                  </IconButton>
                )}
              </ListItem>
              <Collapse
                style={{background: '#ffffff'}}
                in={list.find(o => o._id === item._id).bopen}
                unmountOnExit
              >
                <div
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    padding: 10,
                  }}
                >
                  <JsxParser components={{Link}} jsx={item.anwser} />
                </div>
              </Collapse>
            </Box>
          </div>
        );
      });
      return mylist;
    } else {
      return null;
    }
  };

  return (
    <div style={{overflowX: 'hidden'}}>
      {isVerySmall || isSmall ? (
        <div>
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 20,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Aplicativo Irê it
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentAppList} setList={setCurrentAppList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Viagens
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentVgList} setList={setCurrentVgList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Pedidos
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentPdList} setList={setCurrentPdList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Motoristas, Mototaxistas e Entregadores
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentMMList} setList={setCurrentMMList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Lojas
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion
                list={currentLojasList}
                setList={setCurrentLojasList}
              />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Ainda precisa de ajuda?
            </p>
            <Divider style={{marginTop: 10}} />
            <p
              style={{
                width: '100%',
                textAlign: 'left',
                marginTop: 15,
                fontSize: 26,
                color: '#393939',
                marginBottom: 10,
              }}
            >
              Será um prazer te ajudar! A Irê it possui diversos canais para dar
              suporte para cada serviço ofertado.
            </p>
            <Link
              style={{
                color: '#25D366',
                fontWeight: 'bold',
                textDecoration: 'none',
                fontSize: 18,
                textAlign: 'center',
              }}
              to="/users"
            >
              Whatapp: (74) 99999-9999
            </Link>
            <Divider style={{marginTop: 20}} />
            <p
              style={{
                width: '90%',
                textAlign: 'left',
                marginTop: 20,
                fontSize: 20,
                marginBottom: 0,
                color: '#393939',
              }}
            >
              Email para usúarios:
            </p>
            <Button
              style={{
                height: 30,
                marginTop: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 16,
                justifyContent: 'flex-start',
              }}
              sx={{
                color: '#ff6600',
                ':hover': {
                  color: '#06448e',
                },
              }}
              onClick={() =>
                window.open(
                  'mailto:suporte@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              suporte@ireit.com.br
            </Button>
            <p
              style={{
                width: '90%',
                textAlign: 'left',
                marginTop: 10,
                fontSize: 20,
                marginBottom: 0,
                color: '#393939',
              }}
            >
              Email para os estabelecimentos:
            </p>
            <Button
              style={{
                height: 30,
                marginTop: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 16,
                justifyContent: 'flex-start',
              }}
              sx={{
                color: '#ff6600',
                ':hover': {
                  color: '#06448e',
                },
              }}
              onClick={() =>
                window.open(
                  'mailto:lojas@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              lojas@ireit.com.br
            </Button>
            <p
              style={{
                width: '90%',
                textAlign: 'left',
                marginTop: 10,
                fontSize: 20,
                marginBottom: 0,
                color: '#393939',
              }}
            >
              Email para motoristas, mototaxistas e entregadores:
            </p>
            <Button
              style={{
                height: 30,
                marginTop: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 16,
                justifyContent: 'flex-start',
                marginBottom: 30,
              }}
              sx={{
                color: '#ff6600',
                ':hover': {
                  color: '#06448e',
                },
              }}
              onClick={() =>
                window.open(
                  'mailto:parceiros@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              parceiros@ireit.com.br
            </Button>
          </div>
        </div>
      ) : (
        <div style={{backgroundColor: '#ffffff', width: '100%'}}>
          <div
            style={{
              width: 'auto',
              marginLeft: 50,
              marginRight: 50,
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Aplicativo Irê it
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentAppList} setList={setCurrentAppList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Viagens
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentVgList} setList={setCurrentVgList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Pedidos
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentPdList} setList={setCurrentPdList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Motoristas, Mototaxistas e Entregadores
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentMMList} setList={setCurrentMMList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Lojas
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion
                list={currentLojasList}
                setList={setCurrentLojasList}
              />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'left',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Ainda precisa de ajuda?
            </p>
            <Divider />
            <p
              style={{
                width: '50%',
                textAlign: 'left',
                marginTop: 10,
                fontSize: 26,
                color: '#393939',
                marginBottom: 10,
              }}
            >
              Será um prazer te ajudar! A Irê it possui diversos canais para dar
              suporte para cada serviço ofertado.
            </p>
            <Link
              style={{
                color: '#25D366',
                fontWeight: 'bold',
                textDecoration: 'none',
                fontSize: 20,
              }}
              to="/users"
            >
              Whatapp: (74) 99999-9999
            </Link>
            <Divider style={{marginTop: 20}} />
            <p
              style={{
                width: '50%',
                textAlign: 'left',
                marginTop: 20,
                fontSize: 20,
                marginBottom: 0,
                color: '#393939',
              }}
            >
              Email para usúarios:
            </p>
            <Button
              style={{
                height: 30,
                marginTop: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 16,
                justifyContent: 'flex-start',
              }}
              sx={{
                color: '#ff6600',
                ':hover': {
                  color: '#06448e',
                },
              }}
              onClick={() =>
                window.open(
                  'mailto:suporte@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              suporte@ireit.com.br
            </Button>
            <p
              style={{
                width: '50%',
                textAlign: 'left',
                marginTop: 10,
                fontSize: 20,
                marginBottom: 0,
                color: '#393939',
              }}
            >
              Email para os estabelecimentos:
            </p>
            <Button
              style={{
                height: 30,
                marginTop: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 16,
                justifyContent: 'flex-start',
              }}
              sx={{
                color: '#ff6600',
                ':hover': {
                  color: '#06448e',
                },
              }}
              onClick={() =>
                window.open(
                  'mailto:lojas@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              lojas@ireit.com.br
            </Button>
            <p
              style={{
                width: '50%',
                textAlign: 'left',
                marginTop: 10,
                fontSize: 20,
                marginBottom: 0,
                color: '#393939',
              }}
            >
              Email para motoristas, mototaxistas e entregadores:
            </p>
            <Button
              style={{
                height: 30,
                marginTop: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 16,
                justifyContent: 'flex-start',
                marginBottom: 30,
              }}
              sx={{
                color: '#ff6600',
                ':hover': {
                  color: '#06448e',
                },
              }}
              onClick={() =>
                window.open(
                  'mailto:parceiros@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              parceiros@ireit.com.br
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Body;
