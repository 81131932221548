import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Button, Fab} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Drawer from '@mui/material/Drawer';
import Footer from '../../Components/Footer';
import Tab from '../../Components/tab';
import Hearder from './header';
import logo from '../../Assets/logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import {Link} from 'react-router-dom';
import Body from './body';

// eslint-disable-next-line react/prop-types
const CodigoDeConduta = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [toggleBar, setToggleBar] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  // eslint-disable-next-line no-unused-vars
  const [width, height] = useWindowSize();
  return (
    <div style={{overflowX: 'hidden', width: '100%'}}>
      <div>
        {isVisible ? (
          <Fab
            onClick={() => scrollToTop()}
            style={{position: 'fixed', bottom: 20, left: 20, zIndex: 2}}
            size={'small'}
            color="primary"
            aria-label="add"
          >
            <ArrowUpwardIcon />
          </Fab>
        ) : null}
        <div style={{width: width}}>
          {isVerySmall || isSmall ? (
            <div style={{width: '100%'}}>
              <Drawer
                anchor={'top'}
                open={toggleBar}
                onClose={() => setToggleBar(false)}
              >
                <Tab setToggleBar={setToggleBar} />
              </Drawer>
              <Hearder />
              <Body />
              <Footer />
              <div
                style={{
                  background: '#06448e',
                  height: 60,
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  position: 'fixed',
                  zindex: 9999,
                  top: 0,
                  left: 0,
                  width: '100%',
                  overflow: 'scroll',
                }}
              >
                <Button
                  component={Link}
                  to="/"
                  style={{
                    width: 95,
                    height: 47,
                    marginLeft: 12,
                    backgroundImage: `url(${logo})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                />
                <MenuIcon
                  style={{
                    color: '#ffffff',
                    marginRight: 15,
                    background: '#06448e',
                  }}
                  onClick={() => setToggleBar(true)}
                />
              </div>
            </div>
          ) : (
            <div>
              <Hearder />
              <Body />
              <Footer />
              <Tab />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CodigoDeConduta;
