/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {CircularProgress, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import InputMask from 'react-input-mask';
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {validarCPF} from '../../../../Utils/cpfValidator';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(() => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
}));

const Owner = ({owner, setOwner, cpf, setCpf}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const [cpfError, setCpfError] = useState(false);
  const cashOwner = useSelector(state => state.setstore);

  useEffect(() => {
    setOwner(cashOwner.owner);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleChange = event => {
    const valorCpf = event.target.value;
    setCpf(valorCpf);

    if (validarCPF(valorCpf)) {
      setCpfError(false);
    } else {
      setCpfError(true);
    }
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();

  return (
    <main>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={classes.root}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{}}>
          {isSmall ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: isVerySmall
                  ? width * 0.73
                  : isSmall
                  ? width * 0.675
                  : width,

                marginLeft: 0,
                marginTop: 10,
              }}
            >
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Responsável pelo estabelecimento
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontSize: 16,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Forneça os dados da pessoa cujo nome consta no contrato social
                da empresa
              </p>
              <TextField
                style={{
                  marginTop: 20,
                  width: '90%',
                  marginBottom: 15,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="owner"
                variant="outlined"
                label="Nome do responsável"
                color="primary"
                value={owner}
                placeholder="Exemplo: Joao Silva Santos"
                onChange={event => {
                  setOwner(event.target.value);
                }}
              />
              <InputMask
                mask="999.999.999-99" // Máscara para o CEP
                value={cpf}
                onChange={handleChange}
              >
                {() => (
                  <TextField
                    style={{
                      marginTop: 20,
                      width: '90%',
                      marginBottom: 20,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                    inputProps={{
                      style: {fontSize: 16, padding: 6, color: '#393939'},
                      maxLength: 100,
                    }}
                    InputLabelProps={{
                      style: {fontSize: 16, marginTop: -8, color: '#797979'},
                    }}
                    id="owner"
                    variant="outlined"
                    label="CPF do responsável"
                    color="primary"
                    value={cpf}
                    placeholder="Exemplo: "
                    error={cpfError}
                    helperText={cpfError ? 'CPF inválido' : ''}
                  />
                )}
              </InputMask>
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Responsável pelo estabelecimento
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontSize: 16,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Forneça os dados da pessoa cujo nome consta no contrato social
                da empresa
              </p>
              <TextField
                style={{
                  marginTop: 20,
                  width: '90%',
                  marginBottom: 15,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="owner"
                variant="outlined"
                label="Nome do responsável"
                color="primary"
                value={owner}
                placeholder="Exemplo: Joao Silva Santos"
                onChange={event => {
                  setOwner(event.target.value);
                }}
              />
              <InputMask
                mask="999.999.999-99" // Máscara para o CEP
                value={cpf}
                onChange={handleChange}
              >
                {() => (
                  <TextField
                    style={{
                      marginTop: 20,
                      width: '90%',
                      marginBottom: 20,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                    inputProps={{
                      style: {fontSize: 16, padding: 6, color: '#393939'},
                      maxLength: 100,
                    }}
                    InputLabelProps={{
                      style: {fontSize: 16, marginTop: -8, color: '#797979'},
                    }}
                    id="owner"
                    variant="outlined"
                    label="CPF do responsável"
                    color="primary"
                    value={cpf}
                    placeholder="Exemplo: "
                    error={cpfError}
                    helperText={cpfError ? 'CPF inválido' : ''}
                  />
                )}
              </InputMask>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default Owner;
