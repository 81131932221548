import React from 'react';
import {useMediaQuery} from 'react-responsive';
import Cardservice from './cardservice';
import CatViagem from '../../Assets/CatViagem.png';
import CatDelivery from '../../Assets/CatDelivery.png';
import CatEntrega from '../../Assets/CatEntrega.png';
import Grid from '@mui/material/Grid';
import styled, {keyframes, css} from 'styled-components';

const fadeIn = keyframes`
  0% {
    transform : translateY(3em);
    opacity: 0;
  }
  50% {
    transform : translateY(3em);
    opacity: 0;
  }
  100% {
    transform : translateY(0em);
    opacity: 1;
  }
`;

const DivService = styled.div`
  animation-fill-mode: forwards;
  visibility: ${props => (props.animateService ? 'visible' : 'hidden')};
  animation: ${props =>
    props.animateService &&
    css`
      ${fadeIn} 1.0s linear 1
    `};
`;

// eslint-disable-next-line react/prop-types
const Services = ({width, animateService}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  return (
    <div
      style={{
        width: width,
        height: isVerySmall || isSmall ? 'auto' : 'auto',
        display: 'flex',
        marginTop: 0,
        alignItems: 'center',
        marginBottom: 15,
      }}
    >
      {isVerySmall || isSmall ? (
        <div style={{width: '100%'}}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <p
              style={{
                fontSize: 26,
                fontWeight: 'bold',
                marginBottom: 0,
                marginTop: 15,
              }}
            >
              Serviços Irê it
            </p>
            <p
              style={{
                fontSize: 18,
                color: '#ff6600',
                marginTop: 0,
                width: '80%',
                textAlign: 'center',
              }}
            >
              Confira os serviços ofertado pela Irê it em nosso app
            </p>
          </div>
          <div
            style={{
              width: '100%',
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Grid sx={{flexGrow: 1}} container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={12}>
                  <Grid item>
                    <Cardservice
                      img={CatViagem}
                      title={'Carona'}
                      desc={[
                        'Motoristas verificados',
                        'Cupons para sua viagem',
                        'Salve seus lugares favoritos',
                        'Escolha se deseja ir de carro ou moto.',
                      ]}
                    />
                  </Grid>
                  <Grid item>
                    <Cardservice
                      img={CatDelivery}
                      title={'Delivery'}
                      desc={[
                        'Os melhores estabelecimentos',
                        'Cupons de desconto',
                        'Acompanhe seu pedido em tempo real',
                      ]}
                    />
                  </Grid>
                  <Grid item>
                    <Cardservice
                      img={CatEntrega}
                      title={'Entrega'}
                      desc={[
                        'Entregadores verificados',
                        'Encontramos o entregador mais próximos a você',
                        'Acompanhe sua entrega em tempo real',
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div style={{width: '100%'}}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <p
              style={{
                fontSize: 36,
                fontWeight: 'bold',
                marginBottom: 0,
                marginTop: 15,
              }}
            >
              Serviços Irê it
            </p>
            <p style={{fontSize: 26, color: '#ff6600', marginTop: 0}}>
              Confira os serviços ofertado pela Irê it em nosso app
            </p>
          </div>
          <div
            style={{
              marginLeft: 20,
              marginRight: 20,
              width: '100%',
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Grid sx={{flexGrow: 1}} container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={12}>
                  <Grid item>
                    <DivService animateService={animateService}>
                      <Cardservice
                        img={CatViagem}
                        title={'Carona'}
                        desc={[
                          'Motoristas verificados',
                          'Cupons para sua viagem',
                          'Salve seus lugares favoritos',
                          'Escolha se deseja ir de carro ou moto.',
                        ]}
                      />
                    </DivService>
                  </Grid>
                  <Grid item>
                    <DivService animateService={animateService}>
                      <Cardservice
                        img={CatDelivery}
                        title={'Delivery'}
                        desc={[
                          'Os melhores estabelecimentos',
                          'Cupons de desconto',
                          'Acompanhe seu pedido em tempo real',
                        ]}
                      />
                    </DivService>
                  </Grid>
                  <Grid item>
                    <DivService animateService={animateService}>
                      <Cardservice
                        img={CatEntrega}
                        title={'Entrega'}
                        desc={[
                          'Entregadores verificados',
                          'Encontramos o entregador mais próximos a você',
                          'Acompanhe sua entrega em tempo real',
                        ]}
                      />
                    </DivService>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
