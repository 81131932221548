/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {useMediaQuery} from 'react-responsive';
import {AppBar, Box, Tab, Tabs, Typography, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import MapIcon from '@mui/icons-material/Map';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import EmailIcon from '@mui/icons-material/Email';
import ConfirmationIcon from '@mui/icons-material/Check';
import Address from './address';
import Owner from './owner';
import Email from './email';
import Estabelecimento from './estabelecimento';
import ConfirmEmail from './confirmEmail';
import {validarCPF} from '../../../../Utils/cpfValidator';
import {validarCNPJ} from '../../../../Utils/cnpjValidator';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  tabs: {
    fontSize: 14,
  },
  labelContainer: {
    padding: 0,
  },
  tab: {
    fontSize: 7,
    minWidth: '20%',
    width: '20%',
    minHeight: 0,
    height: 50,
    fontWeight: 'bold',
  },
}));

export default function FullWidthTabs({
  value,
  handleChange,
  handleChangeIndex,
  checkAddress,
  setCheckAddress,
  cepError,
  setCepError,
  fetchCep,
  cep,
  setCep,
  est,
  setEst,
  cid,
  setCid,
  bairro,
  setBairro,
  address,
  setAddress,
  comp,
  setComp,
  num,
  setNum,
  marker,
  setMarker,
  fetchLatLng,
  isReadyToMap,
  setReadyToMap,
  checkOwner,
  setCheckOwner,
  owner,
  setOwner,
  cpf,
  setCpf,
  checkEmail,
  setCheckEmail,
  email,
  setEmail,
  checkEst,
  setCheckEst,
  cnpj,
  setCnpj,
  cnpjError,
  setCnpjError,
  cnpjErrorMsg,
  fetchRazaoSocial,
  razaoSocial,
  setRazaoSocial,
  estNome,
  setEstNome,
  phone,
  setPhone,
  cat,
  setCat,
  isSending,
  isConfirmationDone,
  setIsConfirmationDone,
  isReadyToSend,
  handleSubmit,
  onRecaptchaChange,
  emailAlreadyExist,
  genericError,
  recaptchaLowPoints,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const isSmall = useMediaQuery({query: '(max-width: 767.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  useEffect(() => {
    const isFormValid =
      cep !== '' &&
      est !== '' &&
      cid !== '' &&
      bairro !== '' &&
      address !== '' &&
      num !== 0 &&
      marker.lat !== 0.0 &&
      marker.lng !== 0.0;

    const isReadyMap =
      cep !== '' &&
      est !== '' &&
      cid !== '' &&
      bairro !== '' &&
      address !== '' &&
      num !== 0;
    setCheckAddress(isFormValid);
    setReadyToMap(isReadyMap);
  }, [cep, est, cid, bairro, address, num, marker]);

  useEffect(() => {
    const isFormOwnerValid =
      checkAddress && owner !== '' && cpf !== '' && validarCPF(cpf);
    setCheckOwner(isFormOwnerValid);
  }, [owner, cpf, checkAddress]);

  useEffect(() => {
    const isFormEstValid =
      checkOwner &&
      cnpj !== '' &&
      razaoSocial !== '' &&
      estNome !== '' &&
      phone !== '' &&
      cat !== '' &&
      validarCNPJ(cnpj);
    setCheckEst(isFormEstValid);
  }, [checkOwner, cnpj, razaoSocial, estNome, phone, cat]);

  useEffect(() => {
    const isFormEmailValid = checkEst && email !== '';
    setCheckEmail(isFormEmailValid);
  }, [checkEst, email]);

  return (
    <div style={{margintop: 0, paddingTop: 0}} className={classes.root}>
      <AppBar
        position={isVerySmall ? 'fixed' : isSmall ? 'fixed' : 'static'}
        color="default"
      >
        <Tabs
          className={classes.tabs}
          labelContainer={classes.labelContainer}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="full width tabs example"
        >
          <Tab
            labelContainer={classes.labelContainer}
            className={classes.tab}
            icon={<MapIcon />}
            label="Endereço"
            {...a11yProps(0)}
          />
          {checkAddress ? (
            <Tab
              labelContainer={classes.labelContainer}
              className={classes.tab}
              icon={<PersonIcon />}
              label="Responsável"
              {...a11yProps(1)}
            />
          ) : null}
          {checkOwner ? (
            <Tab
              labelContainer={classes.labelContainer}
              className={classes.tab}
              icon={<StoreIcon />}
              label="Estabelecimento"
              {...a11yProps(2)}
            />
          ) : null}
          {checkEst ? (
            <Tab
              labelContainer={classes.labelContainer}
              className={classes.tab}
              icon={<EmailIcon />}
              label="Email"
              {...a11yProps(3)}
            />
          ) : null}
          {checkEmail ? (
            <Tab
              labelContainer={classes.labelContainer}
              className={classes.tab}
              icon={<ConfirmationIcon />}
              label="Confirmação"
              {...a11yProps(4)}
            />
          ) : null}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={0}
          dir={theme.direction}
        >
          <Address
            checkAddress={checkAddress}
            cep={cep}
            setCep={setCep}
            cepError={cepError}
            setCepError={setCepError}
            fetchCep={fetchCep}
            est={est}
            setEst={setEst}
            cid={cid}
            setCid={setCid}
            bairro={bairro}
            setBairro={setBairro}
            address={address}
            setAddress={setAddress}
            comp={comp}
            setComp={setComp}
            num={num}
            setNum={setNum}
            marker={marker}
            setMarker={setMarker}
            isReadyToMap={isReadyToMap}
            setReadyToMap={setReadyToMap}
            fetchLatLng={fetchLatLng}
          />
        </TabPanel>
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={1}
          dir={theme.direction}
        >
          <Owner owner={owner} setOwner={setOwner} cpf={cpf} setCpf={setCpf} />
        </TabPanel>
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={2}
          dir={theme.direction}
        >
          <Estabelecimento
            cnpj={cnpj}
            setCnpj={setCnpj}
            cnpjError={cnpjError}
            setCnpjError={setCnpjError}
            cnpjErrorMsg={cnpjErrorMsg}
            fetchRazaoSocial={fetchRazaoSocial}
            razaoSocial={razaoSocial}
            setRazaoSocial={setRazaoSocial}
            estNome={estNome}
            setEstNome={setEstNome}
            phone={phone}
            setPhone={setPhone}
            cat={cat}
            setCat={setCat}
          />
        </TabPanel>
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={3}
          dir={theme.direction}
        >
          <Email email={email} setEmail={setEmail} />
        </TabPanel>
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={4}
          dir={theme.direction}
        >
          <ConfirmEmail
            email={email}
            isSending={isSending}
            isConfirmationDone={isConfirmationDone}
            setIsConfirmationDone={setIsConfirmationDone}
            isReadyToSend={isReadyToSend}
            onRecaptchaChange={onRecaptchaChange}
            handleSubmit={handleSubmit}
            emailAlreadyExist={emailAlreadyExist}
            genericError={genericError}
            recaptchaLowPoints={recaptchaLowPoints}
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
