/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Button,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useState, useLayoutEffect, useEffect, useCallback} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Box} from '@mui/system';
import JsxParser from 'react-jsx-parser';
import {Link} from 'react-router-dom';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {Check} from '@mui/icons-material';
import {Error} from '@mui/icons-material';
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

const useStyles = makeStyles(() => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
}));

const ConfirmEmail = ({
  email,
  isSending,
  isConfirmationDone,
  onRecaptchaChange,
  handleSubmit,
  isReadyToSend,
  emailAlreadyExist,
  genericError,
  recaptchaLowPoints,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();

  const [currentLojasList, setCurrentLojasList] = useState([]);

  useEffect(() => {
    setCurrentLojasList([
      {
        _id: 1,
        bopen: false,
        question:
          'O que devo fazer se não receber o código de validação por e-mail?',
        anwser:
          '<p>Se o e-mail com o código de validação não estiver na sua caixa de entrada, verifique a pasta de spam, pois alguns serviços de e-mail podem filtrar nossas mensagens. Caso ainda não encontre o e-mail, confira se o endereço digitado está correto. Para corrigir, basta retornar à etapa anterior e ajustar as informações.</p>',
      },
      {
        _id: 2,
        bopen: false,
        question: 'Finalizei meu cadastro, e agora?',
        anwser:
          '<p>Você receberá um email para criar sua senha, após isso é só entrar no site https://www.lojasireit.com.br para começar a sua operação .</p>',
      },
    ]);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const Listquestion = ({list, setList}) => {
    if (list.length > 0) {
      let mylist = list.map((item, index) => {
        return (
          <div
            key={item._id}
            style={{
              width: '100%',
              borderRadius: 30,
              marginTop: 0,
            }}
          >
            <Box
              boxShadow={3}
              style={{
                width: '100%',
                backgroundColor: '#ffffff',
              }}
            >
              <ListItem
                style={{
                  backgroundColor: '#f8f8f8',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemText
                  style={{
                    width: isVerySmall || isSmall ? '90%' : '70%',
                    textOverflow: 'ellipsis',
                    color: '#393939',
                  }}
                  primaryTypographyProps={{fontWeight: 'bold'}}
                  primary={item.question}
                />
                {list.find(o => o._id === item._id).bopen ? (
                  <IconButton
                    onClick={() =>
                      handleClick(
                        index,
                        item._id,
                        !list.find(o => o._id === item._id).bopen,
                        list,
                        setList,
                      )
                    }
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                  >
                    <ExpandLess />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      handleClick(
                        index,
                        item._id,
                        !list.find(o => o._id === item._id).bopen,
                        list,
                        setList,
                      )
                    }
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                  >
                    <ExpandMore />
                  </IconButton>
                )}
              </ListItem>
              <Collapse
                style={{background: '#ffffff'}}
                in={list.find(o => o._id === item._id).bopen}
                unmountOnExit
              >
                <div
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    padding: 10,
                  }}
                >
                  <JsxParser components={{Link}} jsx={item.anwser} />
                </div>
              </Collapse>
            </Box>
          </div>
        );
      });
      return mylist;
    } else {
      return null;
    }
  };

  const [width] = useWindowSize();

  const handleClick = (i, id, open, list, setList) => {
    let list2 = list.map(item => {
      if (item._id === id) {
        return {...item, bopen: open};
      } else {
        return item;
      }
    });
    setList(list2);
  };

  return (
    <main>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={classes.root}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{}}>
          {isSmall ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: isVerySmall
                  ? width * 0.73
                  : isSmall
                  ? width * 0.675
                  : width,
                marginLeft: 0,
                marginTop: 10,
              }}
            >
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Enviar informações
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontSize: 16,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Estamos quase lá, confirme seu email e clique no botão de
                cadastrar
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#000000',
                  fontSize: 20,
                  marginTop: 20,
                  marginBottom: 40,
                  height: 30,
                  fontWeight: 'bold',
                  wordWrap: 'break-word',
                  width: '100%',
                  maxHeight: 60,
                  lineHeight: '30px',
                }}
              >
                {email}
              </p>
              <GoogleReCaptcha onVerify={onRecaptchaChange} />
              {isSending ? (
                <div
                  style={{
                    display: 'flex',
                    width: isVerySmall ? '45%' : '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  className={classes.root}
                >
                  <CircularProgress />
                </div>
              ) : emailAlreadyExist ? (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  className={classes.root}
                >
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      fontSize: 20,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    O email ja esta sendo utilizado.
                  </p>
                  <Error
                    style={{color: '#FF0000', fontSize: 50, marginTop: 0}}
                  />
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontSize: 16,
                      marginTop: 0,
                      marginBottom: 0,
                      textAlign: 'center',
                    }}
                  >
                    Parece que o email que você está tentando cadastrar já está
                    sendo utilizado. Lembre-se que a Irê it utiliza a mesma
                    conta em todos os seus serviços seja como cliente,
                    entregador ou motorista. Evite utilizar email pessoal para
                    gerir sua loja.
                  </p>
                </div>
              ) : genericError ? (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  className={classes.root}
                >
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      fontSize: 20,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Algo deu errado durante o envio das informações.
                  </p>
                  <Error
                    style={{color: '#FF0000', fontSize: 50, marginTop: 0}}
                  />
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontSize: 16,
                      marginTop: 0,
                      marginBottom: 0,
                      textAlign: 'center',
                    }}
                  >
                    Por favor, verifique se sua rede está estável, se todos os
                    campos foram preenchidos corretamente, atualize o navegador
                    e tente novamente. Caso o erro persista, entre em contato
                    com o nosso suporte atráves do email suporte@ireit.com.br.
                  </p>
                </div>
              ) : recaptchaLowPoints ? (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  className={classes.root}
                >
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      fontSize: 20,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Comportamento estranho.
                  </p>
                  <Error
                    style={{color: '#FF0000', fontSize: 50, marginTop: 0}}
                  />
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontSize: 16,
                      marginTop: 0,
                      marginBottom: 0,
                      textAlign: 'center',
                    }}
                  >
                    Detectamos um comportamento estranho durante o processo de
                    cadastro. Por favor atualize a pagina e tente novamente ou
                    tente através de outro dispositivo.
                  </p>
                </div>
              ) : isConfirmationDone ? (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  className={classes.root}
                >
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      fontSize: 20,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Sua conta foi criada com sucesso.
                  </p>
                  <Check
                    style={{color: '#52d869', fontSize: 50, marginTop: 0}}
                  />
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontSize: 16,
                      marginTop: 0,
                      marginBottom: 0,
                      textAlign: 'center',
                    }}
                  >
                    Parabéns pelo cadastro da seu estabelecimento no app Irê it!
                    Você receberá em breve um e-mail com instruções para
                    configurar sua senha. Após definir sua senha, acesse o site{' '}
                    <strong>lojasireit.com.br</strong> para completar o processo
                    e gerenciar seu estabelecimento. Caso não receba o e-mail em
                    alguns minutos, verifique sua caixa de spam ou lixo
                    eletrônico.{' '}
                  </p>
                  <Button
                    style={{
                      marginTop: 5,
                      marginBottom: 0,
                      textTransform: 'none',
                      fontWeight: '100',
                      fontStyle: 'normal',
                      justifyContent: 'flex-start',
                      fontSize: 18,
                      marginLeft: 0,
                    }}
                    sx={{
                      color: '#FF6600',
                      ':hover': {
                        color: '#06448E',
                      },
                    }}
                    onClick={handleSubmit}
                    disabled={!isReadyToSend}
                  >
                    Não recebi o email de nova senha
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    style={{
                      marginTop: 5,
                      marginBottom: 0,
                      textTransform: 'none',
                      fontWeight: '100',
                      fontStyle: 'normal',
                      justifyContent: 'flex-start',
                      fontSize: 18,
                      marginLeft: 0,
                    }}
                    sx={{
                      color: '#FF6600',
                      ':hover': {
                        color: '#06448E',
                      },
                    }}
                    onClick={handleSubmit}
                    disabled={!isReadyToSend}
                  >
                    Cadastrar
                  </Button>
                </div>
              )}

              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'normal',
                  fontSize: 20,
                  marginTop: 30,
                  marginBottom: 0,
                }}
              >
                Princpais dúvidas
              </p>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <Listquestion
                  list={currentLojasList}
                  setList={setCurrentLojasList}
                />
              </List>
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Enviar informações
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontSize: 16,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Estamos quase lá, confirme seu email e clique no botão de
                cadastrar
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#000000',
                  fontSize: 20,
                  marginTop: 20,
                  marginBottom: 40,
                  height: 30,
                  fontWeight: 'bold',
                  wordWrap: 'break-word',
                  width: '100%',
                  maxHeight: 60,
                  lineHeight: '30px',
                }}
              >
                {email}
              </p>
              <GoogleReCaptcha onVerify={onRecaptchaChange} />
              {isSending ? (
                <div
                  style={{
                    display: 'flex',
                    width: isVerySmall ? '45%' : '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  className={classes.root}
                >
                  <CircularProgress />
                </div>
              ) : emailAlreadyExist ? (
                <div
                  style={{
                    display: 'flex',
                    width: isVerySmall ? '45%' : '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  className={classes.root}
                >
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      fontSize: 20,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    O email ja esta sendo utilizado.
                  </p>
                  <Error
                    style={{color: '#FF0000', fontSize: 50, marginTop: 0}}
                  />
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontSize: 16,
                      marginTop: 0,
                      marginBottom: 0,
                      textAlign: 'center',
                    }}
                  >
                    Parece que o email que você está tentando cadastrar já está
                    sendo utilizado. Lembre-se que a Irê it utiliza a mesma
                    conta em todos os seus serviços seja como cliente,
                    entregador ou motorista. Evite utilizar email pessoal para
                    gerir sua loja.
                  </p>
                </div>
              ) : genericError ? (
                <div
                  style={{
                    display: 'flex',
                    width: isVerySmall ? '45%' : '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  className={classes.root}
                >
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      fontSize: 20,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Algo deu errado durante o envio das informações.
                  </p>
                  <Error
                    style={{color: '#FF0000', fontSize: 50, marginTop: 0}}
                  />
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontSize: 16,
                      marginTop: 0,
                      marginBottom: 0,
                      textAlign: 'center',
                    }}
                  >
                    Por favor, verifique se sua rede está estável, se todos os
                    campos foram preenchidos corretamente, atualize o navegador
                    e tente novamente. Caso o erro persista, entre em contato
                    com o nosso suporte atráves do email suporte@ireit.com.br.
                  </p>
                </div>
              ) : recaptchaLowPoints ? (
                <div
                  style={{
                    display: 'flex',
                    width: isVerySmall ? '45%' : '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  className={classes.root}
                >
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      fontSize: 20,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Comportamento estranho.
                  </p>
                  <Error
                    style={{color: '#FF0000', fontSize: 50, marginTop: 0}}
                  />
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontSize: 16,
                      marginTop: 0,
                      marginBottom: 0,
                      textAlign: 'center',
                    }}
                  >
                    Detectamos um comportamento estranho durante o processo de
                    cadastro. Por favor atualize a pagina e tente novamente ou
                    tente através de outro dispositivo.
                  </p>
                </div>
              ) : isConfirmationDone ? (
                <div
                  style={{
                    display: 'flex',
                    width: isVerySmall ? '45%' : '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  className={classes.root}
                >
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      fontSize: 20,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Sua conta foi criada com sucesso
                  </p>
                  <Check
                    style={{color: '#52d869', fontSize: 50, marginTop: 0}}
                  />
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3d3d3d',
                      fontSize: 16,
                      marginTop: 0,
                      marginBottom: 0,
                      textAlign: 'center',
                    }}
                  >
                    Parabéns pelo cadastro da seu estabelecimento no app Irê it!
                    Você receberá em breve um e-mail com instruções para
                    configurar sua senha. Após definir sua senha, acesse o site{' '}
                    <strong>lojasireit.com.br</strong> para completar o processo
                    e gerenciar seu estabelecimento. Caso não receba o e-mail em
                    alguns minutos, verifique sua caixa de spam ou lixo
                    eletrônico.{' '}
                  </p>
                  <Button
                    style={{
                      marginTop: 5,
                      marginBottom: 0,
                      textTransform: 'none',
                      fontWeight: '100',
                      fontStyle: 'normal',
                      justifyContent: 'flex-start',
                      fontSize: 18,
                      marginLeft: 0,
                    }}
                    sx={{
                      color: '#FF6600',
                      ':hover': {
                        color: '#06448E',
                      },
                    }}
                    onClick={handleSubmit}
                    disabled={!isReadyToSend}
                  >
                    Não recebi o email de nova senha
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    style={{
                      marginTop: 5,
                      marginBottom: 0,
                      textTransform: 'none',
                      fontWeight: '100',
                      fontStyle: 'normal',
                      justifyContent: 'flex-start',
                      fontSize: 18,
                      marginLeft: 0,
                    }}
                    sx={{
                      color: '#FF6600',
                      ':hover': {
                        color: '#06448E',
                      },
                    }}
                    onClick={handleSubmit}
                    disabled={!isReadyToSend}
                  >
                    Cadastrar
                  </Button>
                </div>
              )}

              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'normal',
                  fontSize: 20,
                  marginTop: 30,
                  marginBottom: 0,
                }}
              >
                Princpais dúvidas
              </p>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <Listquestion
                  list={currentLojasList}
                  setList={setCurrentLojasList}
                />
              </List>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default ConfirmEmail;
