/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {CircularProgress, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';

const useStyles = makeStyles(() => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
}));

const Email = ({email, setEmail}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const cashOwner = useSelector(state => state.setstore);

  useEffect(() => {
    setEmail(cashOwner.email);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();

  return (
    <main>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={classes.root}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{}}>
          {isSmall ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: isVerySmall
                  ? width * 0.73
                  : isSmall
                  ? width * 0.675
                  : width,
                marginLeft: 0,
                marginTop: 10,
              }}
            >
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Defina um email
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontSize: 16,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Você acessará sua conta e receberá informações da Irê it através
                desse email
              </p>
              <TextField
                style={{
                  marginTop: 20,
                  width: '90%',
                  marginBottom: 15,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="owner"
                variant="outlined"
                label="Email"
                color="primary"
                value={email}
                placeholder="exemplo@ireit.com.br"
                onChange={event => {
                  setEmail(event.target.value);
                }}
              />
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Defina um email
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontSize: 16,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Você acessará sua conta e receberá informações da Irê it através
                desse email
              </p>
              <TextField
                style={{
                  marginTop: 20,
                  width: '90%',
                  marginBottom: 15,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="owner"
                variant="outlined"
                label="Email"
                color="primary"
                value={email}
                placeholder="exemplo@ireit.com.br"
                onChange={event => {
                  setEmail(event.target.value);
                }}
              />
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default Email;
