/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import LojasIreit from '../../Assets/LojasIreit.png';
import RegisterBox from './registerbox';

const Body = ({setOpenItem}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  useEffect(() => {}, []);

  return (
    <div>
      {isVerySmall || isSmall ? (
        <div
          style={{
            background: 'linear-gradient(#06448e, #7c53e3, #b553e3)',
            width: '100%',
            height: 'auto',
            marginTop: 60,
            display: 'flex',
            paddingBottom: 60,
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 60,
              justifyContent: 'center',
              marginLeft: 10,
              marginRight: 10,
              width: '100%',
            }}
          >
            <p
              style={{
                fontSize: 36,
                width: '100%',
                marginTop: 10,
                fontWeight: 'bold',
                color: '#FF6600',
                marginBottom: 0,
                textAlign: 'center',
              }}
            >
              Seu estabelecimento na Irê it.
            </p>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                marginTop: 0,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={{
                  width: '90%',
                  height: 'auto',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 0,
                }}
                src={LojasIreit}
              />
            </div>
            <div>
              <p
                style={{
                  fontSize: 24,
                  width: '90%',
                  marginTop: 20,
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                  marginLeft: 10,
                }}
              >
                Cadastra-se em nossa plataforma, aproveite a facilidade do nosso
                painel e a logística Irê it.
              </p>
            </div>
            <RegisterBox setOpenItem={setOpenItem} />
          </div>
        </div>
      ) : (
        <div
          style={{
            background: 'linear-gradient(#06448e, #7c53e3, #b553e3)',
            width: '100%',
            height: 700,
            marginTop: 60,
            display: 'flex',
            paddingBottom: 60,
            justifyContent: 'space-between',
          }}
        >
          <div style={{display: 'flex', flexDirection: 'column', flex: 0.6}}>
            <p
              style={{
                fontSize: 48,
                width: '100%',
                marginLeft: 30,
                marginTop: 30,
                fontWeight: 'bold',
                color: '#FF6600',
              }}
            >
              Seu estabelecimento na Irê it.
            </p>
            <div>
              <p
                style={{
                  fontSize: 28,
                  width: '80%',
                  marginLeft: 30,
                  marginTop: -30,
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                }}
              >
                Cadastra-se em nossa plataforma, aproveite a facilidade do nosso
                painel e a logística Irê it.
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 25,
                // position: 'absolute',
                // top: 400,
                // left: 25,
              }}
            >
              <img
                style={{
                  width: '75%',
                  height: 'auto',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 0,
                }}
                src={LojasIreit}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 0.35,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              marginTop: 60,
              marginRight: '5%',
            }}
          >
            <RegisterBox setOpenItem={setOpenItem} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Body;
