/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useEffect, useCallback} from 'react';
import FullWidthTab from './TabsItem';
import {useMediaQuery} from 'react-responsive';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContentText,
  Divider,
} from '@mui/material';
import {
  getAuth,
  signInAnonymously,
  linkWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
} from 'firebase/auth';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogAcitons from '@mui/material/DialogActions';
import {validarCNPJ} from '../../../Utils/cnpjValidator';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

export default function AddItemDialogs({bopen, handleClose}) {
  const auth = getAuth();
  const {executeRecaptcha} = useGoogleReCaptcha();
  const isSmall = useMediaQuery({query: '(max-width: 767.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width: 367.98px)'});
  const [open, setOpen] = React.useState(false);
  const [toggleBackdrop, setToggleBackdrop] = useState(false);
  const [titleBackdrop, setTitleBackdrop] = useState('');
  const [descriptionBackdrop, setDescriptionBackdrop] = useState('');
  const [codeBackdrop, setCodeBackdrop] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [isSending, setIsSending] = useState(false);
  const handleBackDrop = (title, description, code, open) => {
    setToggleBackdrop(open);
    setTitleBackdrop(title);
    setDescriptionBackdrop(description);
    setCodeBackdrop(code);
  };

  const handleClose2 = () => {
    setValue(0);
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  function close() {
    setEmailAlreadyExist(false);
    setIsSendingConfirm(false);
    setGenericError(false);
    setRecaptchaLowPoints(false);
    setIsConfirmationDone(false);
    setValue(0);
    handleClose();
  }

  //address
  const [checkAddress, setCheckAddress] = useState(false);
  const [cepError, setCepError] = useState(false);
  const [cep, setCep] = useState('');
  const [est, setEst] = useState('');
  const [cid, setCid] = useState('');
  const [bairro, setBairro] = useState('');
  const [address, setAddress] = useState('');
  const [num, setNum] = useState(0);
  const [comp, setComp] = useState('');
  const [marker, setMarker] = useState({lat: -11.4392849, lng: -41.3939829});
  const [isReadyToMap, setReadyToMap] = useState(false);

  // owner
  const [checkOwner, setCheckOwner] = useState(false);
  const [owner, setOwner] = useState('');
  const [cpf, setCpf] = useState('');

  //estabelecimento
  const [checkEst, setCheckEst] = useState(false);
  const [cnpj, setCnpj] = useState('');
  const [cnpjError, setCnpjError] = useState(false);
  const [cnpjErrorMsg, setCnpjErrorMsg] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [estNome, setEstNome] = useState('');
  const [phone, setPhone] = useState('');
  const [cat, setCat] = useState('');

  //email
  const [checkEmail, setCheckEmail] = useState(false);
  const [email, setEmail] = useState('');

  //confirmation email
  const [otp, setOtp] = useState('');
  const [isSendingConfirm, setIsSendingConfirm] = useState(false);
  const [isConfirmationDone, setIsConfirmationDone] = useState(false);
  const [countExp, setCountExp] = useState(0);
  const [token, setToken] = useState();
  const [isReadyToSend, setIsReadyToSend] = useState(false);
  const [emailAlreadyExist, setEmailAlreadyExist] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const [recaptchaLowPoints, setRecaptchaLowPoints] = useState(false);

  useEffect(() => {
    setEmailAlreadyExist(false);
    setIsSendingConfirm(false);
    setGenericError(false);
    setRecaptchaLowPoints(false);
    setIsConfirmationDone(false);
  }, [email]);

  useEffect(() => {
    auth.signOut();
  }, []);

  const fetchRazaoSocial = useCallback(
    newCnpj => {
      if (validarCNPJ(cnpj)) {
        setCnpjErrorMsg('CNPJ não pode estar vazio');
        setCnpjError(true);
        return;
      }
      setCnpjError(false);
      setCnpjErrorMsg('');

      const cnpjLimpo = newCnpj.replace(/\D/g, '');

      const request = new Request(`https://publica.cnpj.ws/cnpj/${cnpjLimpo}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      fetch(request)
        .then(response => {
          response
            .json()
            .then(data => {
              setRazaoSocial(data.razao_social.toString());
            })
            .catch(error => {
              setCnpjError(true);
            });
        })
        .catch(error => {
          console.debug(error);
        });
    },
    [cnpj],
  );

  const fetchCep = useCallback(
    newCep => {
      const request = new Request(`https://viacep.com.br/ws/${newCep}/json/`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      fetch(request)
        .then(response => {
          response
            .json()
            .then(data => {
              if (data.erro) {
                setCepError(true);
                setEst('');
                setCid('');
                setAddress('');
                setBairro('');
              } else {
                setCepError(false);
                setEst(data.uf);
                setCid(data.localidade);
                setAddress(data.logradouro);
                setBairro(data.bairro);
              }
            })
            .catch(error => {
              setCepError(true);
              setEst('');
              setCid('');
              setAddress('');
              setBairro('');
            });
        })
        .catch(error => {
          console.debug(error);
          setCepError(true);
          setEst('');
          setCid('');
          setAddress('');
          setBairro('');
        });
    },
    [cep],
  );

  const fetchLatLng = useCallback(
    newAddress => {
      const request = new Request(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          newAddress,
        )}&key=${'AIzaSyDDktPhGLUCBoDQfNJ9TbtSiVcCKFGTdsM'}`,
        {
          method: 'GET',
          mode: 'cors',
        },
      );

      fetch(request)
        .then(response => {
          response
            .json()
            .then(data => {
              setMarker({
                lat: data.results[0].geometry.location.lat,
                lng: data.results[0].geometry.location.lng,
              });
            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch(error => {
          console.debug(error);
        });
    },
    [cep, address, cid, est],
  );

  const onRecaptchaChange = useCallback(async () => {
    if (!executeRecaptcha) {
      console.debug('Recaptcha não está disponível ainda');
      return;
    }

    const token = await executeRecaptcha();
    setToken(token);
    setIsReadyToSend(true); // Sinaliza que o reCAPTCHA foi resolvido
  }, [executeRecaptcha]);

  // Verificar o reCAPTCHA no envio do formulário
  const handleSubmit = async () => {
    if (!isReadyToSend) {
      console.debug('Recaptcha não foi resolvido ainda');
      return;
    }

    // Valida o token com o backend antes de qualquer outra ação
    await sendRegister(email, token);
  };

  const sendRegister = useCallback(
    async (newEmail, tokenRecaptcha) => {
      setEmailAlreadyExist(false);
      setIsSendingConfirm(true);
      setGenericError(false);
      setRecaptchaLowPoints(false);
      setIsConfirmationDone(false);
      try {
        if (!auth.currentUser) {
          // Se o usuário não estiver logado, fazer login anônimo
          await signInAnonymously(auth);
        }

        // Obter o token do usuário logado (pode ser anônimo)
        const token = await auth.currentUser.getIdToken();

        // Preparar a requisição à API
        const request = new Request(
          'https://kops.apiireit.com/gateway/store/register/store?user=' +
            auth.currentUser.uid +
            '&owner=' +
            owner +
            '&email=' +
            email +
            '&ctt=' +
            phone +
            '&cep=' +
            cep +
            '&est=' +
            est +
            '&cid=' +
            cid +
            '&bairro=' +
            bairro +
            '&address=' +
            address +
            '&comp=' +
            comp +
            '&num=' +
            num +
            '&lat=' +
            marker.lat +
            '&lng=' +
            marker.lng +
            '&cpf=' +
            cpf +
            '&cnpj=' +
            cnpj +
            '&rzscl=' +
            razaoSocial +
            '&nome=' +
            estNome +
            '&cat=' +
            cat +
            '&prod=' +
            true,
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({token: tokenRecaptcha}),
          },
        );

        // Fazer a requisição à API
        const response = await fetch(request);

        // Verificar se a requisição foi bem-sucedida (status 200 OK)
        if (response.ok) {
          console.debug('Resposta bem-sucedida da API');

          // Extrair os dados em JSON
          const data = await response.json();

          // Agora, se quiser verificar algo específico na resposta da API,
          // você pode verificar o conteúdo do 'data'
          if (data && data.score >= 0.9) {
            const credential = EmailAuthProvider.credential(
              newEmail,
              'p4$$w0rd',
            );
            const userCred = await linkWithCredential(
              auth.currentUser,
              credential,
            );
            setEmailAlreadyExist(false);
            setIsSendingConfirm(false);
            setGenericError(false);
            setRecaptchaLowPoints(false);
            setIsConfirmationDone(true);

            // Enviar e-mail de redefinição de senha
            await sendPasswordResetEmail(auth, email);
          } else {
            setEmailAlreadyExist(false);
            setIsSendingConfirm(false);
            setGenericError(false);
            setRecaptchaLowPoints(true);
            setIsConfirmationDone(false);
          }
        } else {
          setEmailAlreadyExist(false);
          setIsSendingConfirm(false);
          setGenericError(true);
          setRecaptchaLowPoints(false);
          setIsConfirmationDone(false);
        }
      } catch (error) {
        console.error('Erro:', error);
        if (error.message.includes('auth/email-already-in-use')) {
          setEmailAlreadyExist(true);
          setIsSendingConfirm(false);
          setGenericError(false);
          setRecaptchaLowPoints(false);
          setIsConfirmationDone(false);
        } else {
          setEmailAlreadyExist(false);
          setIsSendingConfirm(false);
          setGenericError(true);
          setRecaptchaLowPoints(false);
          setIsConfirmationDone(false);
        }
        // Opcional: definir um estado de erro ou lidar com o erro adequadamente
      }
    },
    [
      email,
      owner,
      phone,
      cep,
      est,
      cid,
      bairro,
      address,
      comp,
      num,
      marker,
      cpf,
      cnpj,
      razaoSocial,
      estNome,
      cat,
    ],
  );

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <FullWidthTab
          value={value}
          handleChange={handleChange}
          handleChangeIndex={handleChangeIndex}
          checkAddress={checkAddress}
          setCheckAddress={setCheckAddress}
          cep={cep}
          setCep={setCep}
          cepError={cepError}
          setCepError={setCepError}
          fetchCep={fetchCep}
          est={est}
          setEst={setEst}
          cid={cid}
          setCid={setCid}
          bairro={bairro}
          setBairro={setBairro}
          address={address}
          setAddress={setAddress}
          comp={comp}
          setComp={setComp}
          num={num}
          setNum={setNum}
          marker={marker}
          setMarker={setMarker}
          fetchLatLng={fetchLatLng}
          isReadyToMap={isReadyToMap}
          setReadyToMap={setReadyToMap}
          checkOwner={checkOwner}
          setCheckOwner={setCheckOwner}
          owner={owner}
          setOwner={setOwner}
          cpf={cpf}
          setCpf={setCpf}
          checkEmail={checkEmail}
          setCheckEmail={setCheckEmail}
          email={email}
          setEmail={setEmail}
          checkEst={checkEst}
          setCheckEst={setCheckEst}
          cnpj={cnpj}
          setCnpj={setCnpj}
          setCnpjError={setCnpjError}
          cnpjError={cnpjError}
          cnpjErrorMsg={cnpjErrorMsg}
          fetchRazaoSocial={fetchRazaoSocial}
          razaoSocial={razaoSocial}
          setRazaoSocial={setRazaoSocial}
          estNome={estNome}
          setEstNome={setEstNome}
          phone={phone}
          setPhone={setPhone}
          cat={cat}
          setCat={setCat}
          otp={otp}
          setOtp={setOtp}
          isSending={isSendingConfirm}
          isConfirmationDone={isConfirmationDone}
          setIsConfirmationDone={setIsConfirmationDone}
          countExp={countExp}
          setCountExp={setCountExp}
          token={token}
          setToken={setToken}
          sendRegister={sendRegister}
          handleSubmit={handleSubmit}
          onRecaptchaChange={onRecaptchaChange}
          isReadyToSend={isReadyToSend}
          setIsReadyToSend={setReadyToMap}
          emailAlreadyExist={emailAlreadyExist}
          genericError={genericError}
          recaptchaLowPoints={recaptchaLowPoints}
        />
        <Divider variant="middle" />

        <DialogAcitons style={{position: 'relative', bottom: 0}}>
          {isVerySmall ? (
            <Button size={'small'} onClick={() => close()}>
              Cancelar
            </Button>
          ) : (
            <Button onClick={() => close()}>Cancelar</Button>
          )}
          {isSmall ? (
            value === 4 ? (
              <Button size={'small'} onClick={() => close()} color="primary">
                Finalizar
              </Button>
            ) : (
              <Button
                size={'small'}
                onClick={() => setValue(value + 1)}
                color="primary"
                disabled={
                  (value === 0 && !checkAddress) ||
                  (value === 1 && !checkOwner) ||
                  (value === 2 && !checkEst) ||
                  (value === 3 && !checkEmail)
                }
              >
                Próximo
              </Button>
            )
          ) : value === 4 ? (
            <Button onClick={() => close()} color="primary">
              Finalizar
            </Button>
          ) : (
            <Button
              onClick={() => setValue(value + 1)}
              color="primary"
              disabled={
                (value === 0 && !checkAddress) ||
                (value === 1 && !checkOwner) ||
                (value === 2 && !checkEst) ||
                (value === 3 && !checkEmail)
              }
            >
              Próximo
            </Button>
          )}
        </DialogAcitons>
      </Dialog>

      <Dialog open={toggleBackdrop} onClick={() => setToggleBackdrop(false)}>
        <DialogTitle id="alert-dialog-title">
          Erro: {codeBackdrop} - {titleBackdrop}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {descriptionBackdrop}
          </DialogContentText>
        </DialogContent>
        <DialogAcitons>
          <Button onClick={handleClose2} color="primary">
            Entendi
          </Button>
        </DialogAcitons>
      </Dialog>
      <Dialog open={isSending} onClick={() => setIsSending(false)}>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: 100,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <CircularProgress />
            <p style={{marginLeft: 20}}>Salvando...</p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
