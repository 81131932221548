/* eslint-disable react/prop-types */
import {Box} from '@mui/material';
import React from 'react';

// eslint-disable-next-line react/prop-types
const Cardservice = ({img, title, desc}) => {
  return (
    <Box
      boxShadow={3}
      style={{
        display: 'flex',
        alignItems: 'center',
        width: 230,
        height: 400,
        padding: 10,
        borderRadius: 3,
        flexDirection: 'column',
      }}
    >
      <div>
        <img
          style={{
            width: 'auto',
            height: 180,
            marginLeft: 10,
            background: '#06448e',
            borderRadius: 90,
          }}
          src={img}
        />
      </div>
      <div
        style={{
          marginLeft: 10,
          height: 80,
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            fontSize: 26,
            marginBottom: 10,
            textAlign: 'center',
            marginTop: 10,
          }}
        >
          {title}
        </p>
        {desc.map((item, index) => (
          <p
            key={item + index}
            style={{
              fontSize: 16,
              marginBottom: 5,
              marginTop: 5,
              textAlign: 'left',
              width: '100%',
              color: '#393939',
            }}
          >
            • {item}
          </p>
        ))}
      </div>
    </Box>
  );
};

export default Cardservice;
