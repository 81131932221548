import {Button, TextField} from '@mui/material';
import {Box} from '@mui/system';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import logo from '../../Assets/logo.png';
import AlertSending from '../../Components/AlertSending';
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode,
} from 'firebase/auth';
import {Check} from '@mui/icons-material';

const ResetPassword = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isExpired, setIsExpired] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isDone, setDone] = useState(false);
  const [mode, setMode] = useState(false);

  const auth = getAuth();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  useEffect(() => {
    if (getParameterByName('mode') == 'resetPassword') {
      setMode('resetPassword');
      verifyPasswordResetCode(auth, getParameterByName('oobCode'))
        .then(function (email) {
          setIsReady(true);
          setIsExpired(false);
          console.debug(email);
        })
        .catch(err => {
          setIsReady(true);
          setIsExpired(true);
          console.debug('Error to verify: ', err);
          // ...
        });
    } else {
      setMode('confirmEmail');
      applyActionCode(auth, getParameterByName('oobCode'))
        .then(resp => {
          setDone(true);
          setIsReady(true);
          setIsExpired(false);
          console.debug(resp);
        })
        .catch(error => {
          setDone(true);
          setIsReady(true);
          setIsExpired(true);
          console.debug('Error to verify: ', error);
        });
    }
  }, []);

  function savePassword() {
    if (password === password2) {
      if (password.length < 6) {
        alert('Senha muito curta');
      } else {
        confirmPasswordReset(auth, getParameterByName('oobCode'), password)
          .then(function (resp) {
            console.debug(resp);
            setDone(true);
          })
          .catch(function (error) {
            alert('Error a salvar senha');
            console.debug(error);
          });
      }
    } else {
      alert('Senhas diferentes');
    }
  }

  return (
    <div
      style={{
        width: width,
        height: height,
        background: 'linear-gradient(#06448e, #7c53e3, #b553e3)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      {mode == 'resetPassword' ? (
        isReady ? (
          <Box
            boxShadow={3}
            borderRadius={1}
            style={{
              background: 'rgba(255, 255, 255, 0.725)',
              marginLeft: isVerySmall || isSmall ? 20 : 30,
              marginRight: isVerySmall || isSmall ? 20 : 30,
              padding: isVerySmall || isSmall ? 15 : 20,
            }}
          >
            {!isExpired ? (
              <div>
                {isDone ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: isVerySmall || isSmall ? '100%' : 275,
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={logo}
                      style={{
                        width: 100,
                        height: 50,
                        background: '#06448e',
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderRadius: 5,
                      }}
                    />
                    <Check
                      style={{color: '#52d869', fontSize: 50, marginTop: 25}}
                    />
                    <p
                      style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Senha atualizada com sucesso!
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: isVerySmall || isSmall ? '100%' : 275,
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={logo}
                      style={{
                        width: 100,
                        height: 50,
                        background: '#06448e',
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderRadius: 5,
                      }}
                    />
                    <p style={{fontSize: 20, fontWeight: 'bold'}}>
                      Atualizar senha
                    </p>
                    <TextField
                      type={'password'}
                      style={{
                        marginTop: 10,
                        width: '80%',
                        marginBottom: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                      inputProps={{
                        style: {fontSize: 14, padding: 6, color: '#393939'},
                        maxLength: 100,
                      }}
                      InputLabelProps={{
                        style: {fontSize: 14, marginTop: -8, color: '#797979'},
                      }}
                      id="pass"
                      variant="outlined"
                      label="Senha"
                      value={password}
                      onChange={event => {
                        setPassword(event.target.value);
                      }}
                    />
                    <TextField
                      type={'password'}
                      style={{
                        marginTop: 10,
                        width: '80%',
                        marginBottom: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                      inputProps={{
                        style: {fontSize: 14, padding: 6, color: '#393939'},
                        maxLength: 100,
                      }}
                      InputLabelProps={{
                        style: {fontSize: 14, marginTop: -8, color: '#797979'},
                      }}
                      id="pass2"
                      variant="outlined"
                      label="Confirme a senha"
                      value={password2}
                      onChange={event => {
                        setPassword2(event.target.value);
                      }}
                    />
                    <Button
                      style={{
                        marginTop: 35,
                        marginBottom: 20,
                        textTransform: 'none',
                        fontWeight: '100',
                        fontStyle: 'normal',
                        fontSize: 18,
                        paddingLeft: 30,
                        paddingRight: 30,
                        justifyContent: 'flex-start',
                      }}
                      sx={{
                        color: '#ffffff',
                        bgcolor: '#FF6600',
                        ':hover': {
                          bgcolor: '#06448e',
                          color: '#ffFFFF',
                        },
                      }}
                      onClick={() => savePassword()}
                    >
                      Atualizar
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: isVerySmall || isSmall ? '100%' : 275,
                  justifyContent: 'center',
                }}
              >
                <img
                  src={logo}
                  style={{
                    width: 100,
                    height: 50,
                    background: '#06448e',
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  }}
                />
                <InfoOutlinedIcon
                  style={{color: '#ff6600', fontSize: 50, marginTop: 20}}
                />
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  Sua troca de senha expirou
                </p>
                <p style={{fontSize: 20, textAlign: 'center'}}>
                  Envie uma nova requisição para poder trocar a senha
                </p>
              </div>
            )}
          </Box>
        ) : null
      ) : isReady ? (
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            background: 'rgba(255, 255, 255, 0.725)',
            marginLeft: isVerySmall || isSmall ? 20 : 30,
            marginRight: isVerySmall || isSmall ? 20 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          {!isExpired ? (
            <div>
              {isDone ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: isVerySmall || isSmall ? '100%' : 275,
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={logo}
                    style={{
                      width: 100,
                      height: 50,
                      background: '#06448e',
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderRadius: 5,
                    }}
                  />
                  <Check
                    style={{color: '#52d869', fontSize: 50, marginTop: 25}}
                  />
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    Email confirmado com sucesso!
                  </p>
                </div>
              ) : null}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: isVerySmall || isSmall ? '100%' : 275,
                justifyContent: 'center',
              }}
            >
              <img
                src={logo}
                style={{
                  width: 100,
                  height: 50,
                  background: '#06448e',
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                }}
              />
              <InfoOutlinedIcon
                style={{color: '#ff6600', fontSize: 50, marginTop: 20}}
              />
              <p
                style={{fontSize: 20, fontWeight: 'bold', textAlign: 'center'}}
              >
                Sua confirmação de email expirou
              </p>
              <p style={{fontSize: 20, textAlign: 'center'}}>
                Envie uma nova requisição para poder confirmar o email.
              </p>
            </div>
          )}
        </Box>
      ) : null}

      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default ResetPassword;
