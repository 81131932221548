/* eslint-disable react/prop-types */
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {Box} from '@mui/system';
import React, {useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import JsxParser from 'react-jsx-parser';
import {Link} from 'react-router-dom';

const Questions = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  const [currentLojasList, setCurrentLojasList] = useState([]);

  useEffect(() => {
    setCurrentLojasList([
      {
        _id: 1,
        bopen: false,
        question: 'Quais são as taxas cobradas pela Irê it',
        anwser:
          '<p>A Irê it possui dois tipos de cobrança para seus serviços:</p> <p><strong>1. Entrega pela loja ou retirada</strong>: Quando a entrega é feita pela própria loja, é cobrada uma taxa de 8% sobre o valor do pedido.</p> <p><strong>2. Entrega pela Irê it</strong>: Se a entrega for realizada pelos parceiros entregadores da Irê it, a taxa é de 15%.</p> <p>Além disso, existe uma taxa fixa de R$ 1,00 para pedidos abaixo de R$ 15,00. Se o pagamento for feito via cartão dentro do aplicativo, há um acréscimo de R$ 0,50 + 3,99% do valor cobrado no cartão, que é cobrado pela gateway de pagamento. Importante destacar que a Irê it <strong>não cobra mensalidade</strong> dos seus parceiros.</p>',
      },
      {
        _id: 2,
        bopen: false,
        question: 'O que é necessário para cadastrar meu estabelecimento?',
        anwser:
          '<p>Para se cadastrar na Irê it, é necessário possuir um <strong>CNPJ ativo</strong> e, dependendo da categoria da sua loja, ter um <strong>CNAE</strong> relacionado ao ramo alimentício.</p> <p>Se todos os requisitos estiverem corretos, você pode prosseguir para a parte de cadastro e começar a utilizar nossos serviços!</p>',
      },
      {
        _id: 3,
        bopen: false,
        question:
          'Em quanto tempo minha loja estará pronta para começar a operar?',
        anwser:
          '<p>Assim que você preencher a ficha de cadastro e montar o seu catálogo, sua loja já estará disponível para começar a operar. O melhor de tudo é que você tem total liberdade para decidir o momento em que deseja iniciar suas atividades na plataforma!</p>',
      },
      {
        _id: 4,
        bopen: false,
        question: 'Quais as vantagens de utilizar a Irê it?',
        anwser:
          '<p>Utilizar a Irê it traz diversas vantagens para sua loja:</p> <p><strong>1.</strong> A Irê it possui as taxas mais baixas em relação aos concorrentes.</p> <p><strong>2.</strong> A Irê it conta com uma ampla rede de entregadores parceiros que utiliza A Irê it MME (Motoristas, Mototaxistas e Entregadores), facilitando o atendimento em regiões onde quer que você esteja.</p> <p><strong>3.</strong> A Irê it proporciona transparência com relatórios diários detalhados.</p> <p><strong>4.</strong> A Irê it está em constante evolução, sempre implementando novas funcionalidades para melhorar a experiência dos parceiros e clientes.</p>',
      },
    ]);
  }, []);

  const handleClick = (i, id, open, list, setList) => {
    let list2 = list.map(item => {
      if (item._id === id) {
        return {...item, bopen: open};
      } else {
        return item;
      }
    });
    setList(list2);
  };

  const Listquestion = ({list, setList}) => {
    if (list.length > 0) {
      let mylist = list.map((item, index) => {
        return (
          <div
            key={item._id}
            style={{
              width: '100%',
              borderRadius: 30,
              marginTop: 25,
            }}
          >
            <Box
              boxShadow={3}
              style={{
                width: '100%',
                backgroundColor: '#ffffff',
              }}
            >
              <ListItem
                style={{
                  backgroundColor: '#f8f8f8',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemText
                  style={{
                    width: isVerySmall || isSmall ? '90%' : '70%',
                    textOverflow: 'ellipsis',
                    color: '#393939',
                  }}
                  primaryTypographyProps={{fontWeight: 'bold'}}
                  primary={item.question}
                />
                {list.find(o => o._id === item._id).bopen ? (
                  <IconButton
                    onClick={() =>
                      handleClick(
                        index,
                        item._id,
                        !list.find(o => o._id === item._id).bopen,
                        list,
                        setList,
                      )
                    }
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                  >
                    <ExpandLess />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      handleClick(
                        index,
                        item._id,
                        !list.find(o => o._id === item._id).bopen,
                        list,
                        setList,
                      )
                    }
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                  >
                    <ExpandMore />
                  </IconButton>
                )}
              </ListItem>
              <Collapse
                style={{background: '#ffffff'}}
                in={list.find(o => o._id === item._id).bopen}
                unmountOnExit
              >
                <div
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    padding: 10,
                  }}
                >
                  <JsxParser components={{Link}} jsx={item.anwser} />
                </div>
              </Collapse>
            </Box>
          </div>
        );
      });
      return mylist;
    } else {
      return null;
    }
  };

  return (
    <div style={{overflowX: 'hidden'}}>
      {isVerySmall || isSmall ? (
        <div>
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Dúvidas frequentes
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion
                list={currentLojasList}
                setList={setCurrentLojasList}
              />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Ainda precisa de ajuda?
            </p>
            <Divider style={{marginTop: 10}} />
            <p
              style={{
                width: '100%',
                textAlign: 'left',
                marginTop: 15,
                fontSize: 26,
                color: '#393939',
                marginBottom: 10,
              }}
            >
              Será um prazer te ajudar! A Irê it possui diversos canais para dar
              suporte para cada serviço ofertado.
            </p>
            <Link
              style={{
                color: '#25D366',
                fontWeight: 'bold',
                textDecoration: 'none',
                fontSize: 18,
                textAlign: 'center',
              }}
              to="/users"
            >
              Whatapp: (74) 99999-9999
            </Link>
            <Divider style={{marginTop: 20}} />
            <p
              style={{
                width: '90%',
                textAlign: 'left',
                marginTop: 10,
                fontSize: 20,
                marginBottom: 0,
                color: '#393939',
              }}
            >
              Email para os estabelecimentos:
            </p>
            <Button
              style={{
                height: 30,
                marginTop: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 16,
                justifyContent: 'flex-start',
                marginBottom: 40,
              }}
              sx={{
                color: '#ff6600',
                ':hover': {
                  color: '#06448e',
                },
              }}
              onClick={() =>
                window.open(
                  'mailto:lojas@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              lojas@ireit.com.br
            </Button>
          </div>
        </div>
      ) : (
        <div style={{backgroundColor: '#ffffff', width: '100%'}}>
          <div
            style={{
              width: 'auto',
              marginLeft: 50,
              marginRight: 50,
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Dúvidas frequentes
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion
                list={currentLojasList}
                setList={setCurrentLojasList}
              />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'left',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Ainda precisa de ajuda?
            </p>
            <Divider />
            <p
              style={{
                width: '50%',
                textAlign: 'left',
                marginTop: 10,
                fontSize: 26,
                color: '#393939',
                marginBottom: 10,
              }}
            >
              Será um prazer te ajudar! A Irê it possui diversos canais para dar
              suporte para cada serviço ofertado.
            </p>
            <Link
              style={{
                color: '#25D366',
                fontWeight: 'bold',
                textDecoration: 'none',
                fontSize: 20,
              }}
              to="/users"
            >
              Whatapp: (74) 99999-9999
            </Link>
            <Divider style={{marginTop: 20}} />

            <p
              style={{
                width: '50%',
                textAlign: 'left',
                marginTop: 10,
                fontSize: 20,
                marginBottom: 0,
                color: '#393939',
              }}
            >
              Email para os estabelecimentos:
            </p>
            <Button
              style={{
                height: 30,
                marginTop: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 16,
                justifyContent: 'flex-start',
                marginBottom: 40,
              }}
              sx={{
                color: '#ff6600',
                ':hover': {
                  color: '#06448e',
                },
              }}
              onClick={() =>
                window.open(
                  'mailto:lojas@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              lojas@ireit.com.br
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Questions;
