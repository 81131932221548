import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {store, persistor} from './store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import MainPage from './Pages/main_page';
import {createTheme, ThemeProvider} from '@mui/material';
import Ajuda from './Pages/ajuda';
import PoliticaDePrivacidade from './Pages/politica_de_privacidade';
import CodigoDeConduta from './Pages/codigo_de_conduta';
import TermosDeUso from './Pages/termos_de_uso';
import ResetPassword from './Pages/resetpassword';
import Confirmemail from './Pages/confirmemail';
import Redirect from './Pages/redirect';
import firebase from 'firebase/compat/app';
import StoreRegister from './Pages/storeRegister';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

const theme = createTheme({
  typography: {
    fontFamily: ['Nunito'].join(','),
  },
  palette: {
    primary: {
      light: '#096be0',
      main: '#06448e',
      dark: '#063976',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff893b',
      main: '#FF6600',
      dark: '#e35b00',
      contrastText: '#000',
    },
    error: {
      light: '#ffeb3b',
      main: '#ffc107',
      dark: '#ff9800',
      contrastText: '#000',
    },
  },
});

function App() {
  if (firebase.apps.length === 0) {
    firebase.initializeApp({
      apiKey: 'AIzaSyA7d6k4NU7QLZpc142vDLklI5qDw1AwE0c',
      authDomain: 'mercasadelivery.firebaseapp.com',
      databaseURL: 'https://mercasadelivery.firebaseio.com',
      projectId: 'mercasadelivery',
      storageBucket: 'mercasadelivery.appspot.com',
      messagingSenderId: '1075142419931',
      appId: '1:1075142419931:web:ef11de93606bcae5ccf3fe',
    });
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          {/* Para teste utilize: 6LcJ9nUqAAAAAH3ztse_eiChB3TalVMPdGPQThNN */}
          <GoogleReCaptchaProvider
            reCaptchaKey="6Lcr3nUqAAAAAKqOvOKWwEDr47-t-KsrABNHYXOV"
            language="pt-BR"
            useRecaptchaNet={false}
            useEnterprise={false}
            scriptProps={{
              async: false, // optional, default to false,
              defer: false, // optional, default to false
              appendTo: 'head', // optional, default to "head", can be "head" or "body",
              nonce: undefined, // optional, default undefined
            }}
          >
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/ajuda" element={<Ajuda />} />
                <Route path="/estabelecimento" element={<StoreRegister />} />
                <Route
                  path="/politica_de_privacidade"
                  element={<PoliticaDePrivacidade />}
                />
                <Route
                  path="/codigo_de_conduta"
                  element={<CodigoDeConduta />}
                />
                <Route path="/termos_de_uso" element={<TermosDeUso />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/confirmemail" element={<Confirmemail />} />
                <Route path="/app/*" element={<Redirect />} />
                <Route path="/*" element={<MainPage />} />
              </Routes>
            </BrowserRouter>
          </GoogleReCaptchaProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
