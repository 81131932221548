/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {CircularProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {TextField, Button} from '@mui/material';
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import InputMask from 'react-input-mask';
import ContentMap from './contentMap';

const useStyles = makeStyles(() => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
}));

const Address = ({
  checkAddress,
  cep,
  setCep,
  est,
  setEst,
  cid,
  setCid,
  bairro,
  setBairro,
  address,
  setAddress,
  comp,
  setComp,
  num,
  setNum,
  marker,
  setMarker,
  isReadyToMap,
  setReadyToMap,
  cepError,
  setCepError,
  fetchCep,
  fetchLatLng,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isReadyToMap) {
      const newAddress = `${address}, ${cid}, ${est}`;
      fetchLatLng(newAddress);
    }
  }, [address, est, cid, isReadyToMap]);

  const handleCepChange = event => {
    const inputCep = event.target.value;

    const cepLimpo = inputCep.replace(/\D/g, '');

    if (cepLimpo.length === 8) {
      setCep(cepLimpo);
      setCepError(false);
      fetchCep(cepLimpo);
    } else {
      setCepError(true);
      setCep(inputCep);
    }

    setCep(event.target.value);
    // fetchCep(event.target.value);
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();

  return (
    <main>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={classes.root}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{}}>
          {isSmall ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: isVerySmall
                  ? width * 0.73
                  : isSmall
                  ? width * 0.675
                  : width,
                marginLeft: 0,
                marginTop: 10,
              }}
            >
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Endereço do estabelecimento
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontSize: 16,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Preencha as informações do endereço do estabelecimento
              </p>
              <InputMask
                mask="99999-999" // Máscara para o CEP
                value={cep}
                onChange={handleCepChange}
              >
                {() => (
                  <TextField
                    style={{
                      marginTop: 20,
                      width: '90%',
                      marginBottom: 0,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                    inputProps={{
                      style: {fontSize: 16, padding: 6, color: '#393939'},
                      maxLength: 100,
                    }}
                    InputLabelProps={{
                      style: {fontSize: 16, marginTop: -8, color: '#797979'},
                    }}
                    id="cep"
                    variant="outlined"
                    label="CEP"
                    color="primary"
                    value={cep}
                    placeholder="Digite o CEP do estabelecimento..."
                    error={cepError}
                    helperText={cepError ? 'CEP inválido' : ''}
                  />
                )}
              </InputMask>
              <Button
                style={{
                  width: '95%',
                  marginTop: 0,
                  marginBottom: 0,
                  textTransform: 'none',
                  fontWeight: 'normal',
                  fontSize: 14,
                  justifyContent: 'flex-start',
                  alignSelf: 'flex-start',
                  marginLeft: 15,
                }}
                sx={{
                  color: '#FF6600',
                  bgcolor: '#FFFFFF',
                  ':hover': {
                    bgcolor: '#FFFFFF',
                    color: '#06448e',
                  },
                }}
                target="_blank"
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              >
                Não sabe o cep? Busque aqui
              </Button>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  width: '95%',
                }}
              >
                <TextField
                  style={{
                    marginTop: 20,
                    width: '50%',
                    marginBottom: 0,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 16, padding: 6, color: '#393939'},
                    maxLength: 100,
                  }}
                  InputLabelProps={{
                    style: {fontSize: 16, marginTop: -8, color: '#797979'},
                  }}
                  id="est"
                  variant="outlined"
                  label="Estado"
                  color="primary"
                  value={est}
                  placeholder=""
                  onChange={event => {
                    setEst(event.target.value);
                  }}
                  disabled
                />

                <TextField
                  style={{
                    marginTop: 20,
                    width: '50%',
                    marginBottom: 0,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 16, padding: 6, color: '#393939'},
                    maxLength: 100,
                  }}
                  InputLabelProps={{
                    style: {fontSize: 16, marginTop: -8, color: '#797979'},
                  }}
                  id="cid"
                  variant="outlined"
                  label="Cidade"
                  color="primary"
                  value={cid}
                  placeholder=""
                  onChange={event => {
                    setCid(event.target.value);
                  }}
                  disabled
                />
              </div>
              <TextField
                style={{
                  marginTop: 20,
                  width: '90%',
                  marginBottom: 0,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="bairro"
                variant="outlined"
                label="Bairro"
                color="primary"
                value={bairro}
                placeholder="Exemplo: Centro"
                onChange={event => {
                  setBairro(event.target.value);
                }}
              />
              <TextField
                style={{
                  marginTop: 20,
                  width: '90%',
                  marginBottom: 0,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="address"
                variant="outlined"
                label="Endereço"
                color="primary"
                value={address}
                placeholder="Exemplo: Rua rio amazonas"
                onChange={event => {
                  setAddress(event.target.value);
                }}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  width: '95%',
                }}
              >
                <TextField
                  style={{
                    marginTop: 20,
                    width: '45%',
                    marginBottom: 0,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 16, padding: 6, color: '#393939'},
                    maxLength: 100,
                  }}
                  InputLabelProps={{
                    style: {fontSize: 16, marginTop: -8, color: '#797979'},
                  }}
                  id="num"
                  variant="outlined"
                  label="Número"
                  color="primary"
                  value={num}
                  placeholder="Exemplo: 01"
                  type="number"
                  onChange={event => {
                    setNum(event.target.value);
                  }}
                />
                <TextField
                  style={{
                    marginTop: 20,
                    width: '45%',
                    marginBottom: 0,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 16, padding: 6, color: '#393939'},
                    maxLength: 100,
                  }}
                  InputLabelProps={{
                    style: {fontSize: 16, marginTop: -8, color: '#797979'},
                  }}
                  id="complemento"
                  variant="outlined"
                  label="Complemento (Opcional)"
                  color="primary"
                  value={comp}
                  placeholder="Exemplo: Apto 101"
                  onChange={event => {
                    setComp(event.target.value);
                  }}
                />
              </div>
              {isReadyToMap ? (
                <p
                  style={{
                    width: '90%',
                    padding: 10,
                    color: '#393939',
                    backgroundColor: '#e8ffff',
                    borderRadius: 10,
                  }}
                >
                  * Ajuste sua localização cliclando no mapa para facilitar as
                  retiradas e agilizar a chegada dos entregadores em seu
                  estabelecimento. Lembre-se a Irê it calcula o frete de acordo
                  com a distância entre o ponto de entrega e a localização da
                  loja.
                </p>
              ) : null}
              {isReadyToMap ? (
                <ContentMap
                  isSmall={false}
                  isVerySmall={false}
                  marker={marker}
                  setMarker={setMarker}
                />
              ) : null}
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Endereço do estabelecimento
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontSize: 16,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Preencha as informações do endereço do estabelecimento
              </p>
              <InputMask
                mask="99999-999" // Máscara para o CEP
                value={cep}
                onChange={handleCepChange}
              >
                {() => (
                  <TextField
                    style={{
                      marginTop: 20,
                      width: '90%',
                      marginBottom: 0,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                    inputProps={{
                      style: {fontSize: 16, padding: 6, color: '#393939'},
                      maxLength: 100,
                    }}
                    InputLabelProps={{
                      style: {fontSize: 16, marginTop: -8, color: '#797979'},
                    }}
                    id="cep"
                    variant="outlined"
                    label="CEP"
                    color="primary"
                    value={cep}
                    placeholder="Digite o CEP do estabelecimento..."
                    error={cepError}
                    helperText={cepError ? 'CEP inválido' : ''}
                  />
                )}
              </InputMask>
              <Button
                style={{
                  width: '95%',
                  marginTop: 0,
                  marginBottom: 0,
                  textTransform: 'none',
                  fontWeight: 'normal',
                  fontSize: 14,
                  justifyContent: 'flex-start',
                  alignSelf: 'flex-start',
                  marginLeft: 5,
                }}
                sx={{
                  color: '#FF6600',
                  bgcolor: '#FFFFFF',
                  ':hover': {
                    bgcolor: '#FFFFFF',
                    color: '#06448e',
                  },
                }}
                target="_blank"
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              >
                Não sabe o cep? Busque aqui
              </Button>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  width: '95%',
                }}
              >
                <TextField
                  style={{
                    marginTop: 20,
                    width: '50%',
                    marginBottom: 0,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 16, padding: 6, color: '#393939'},
                    maxLength: 100,
                  }}
                  InputLabelProps={{
                    style: {fontSize: 16, marginTop: -8, color: '#797979'},
                  }}
                  id="est"
                  variant="outlined"
                  label="Estado"
                  color="primary"
                  value={est}
                  placeholder=""
                  onChange={event => {
                    setEst(event.target.value);
                  }}
                  disabled
                />

                <TextField
                  style={{
                    marginTop: 20,
                    width: '50%',
                    marginBottom: 0,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 16, padding: 6, color: '#393939'},
                    maxLength: 100,
                  }}
                  InputLabelProps={{
                    style: {fontSize: 16, marginTop: -8, color: '#797979'},
                  }}
                  id="cid"
                  variant="outlined"
                  label="Cidade"
                  color="primary"
                  value={cid}
                  placeholder=""
                  onChange={event => {
                    setCid(event.target.value);
                  }}
                  disabled
                />
              </div>
              <TextField
                style={{
                  marginTop: 20,
                  width: '90%',
                  marginBottom: 0,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="bairro"
                variant="outlined"
                label="Bairro"
                color="primary"
                value={bairro}
                placeholder="Exemplo: Centro"
                onChange={event => {
                  setBairro(event.target.value);
                }}
              />
              <TextField
                style={{
                  marginTop: 20,
                  width: '90%',
                  marginBottom: 0,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="address"
                variant="outlined"
                label="Endereço"
                color="primary"
                value={address}
                placeholder="Exemplo: Rua rio amazonas"
                onChange={event => {
                  setAddress(event.target.value);
                }}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  width: '95%',
                }}
              >
                <TextField
                  style={{
                    marginTop: 20,
                    width: '45%',
                    marginBottom: 0,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 16, padding: 6, color: '#393939'},
                    maxLength: 100,
                  }}
                  InputLabelProps={{
                    style: {fontSize: 16, marginTop: -8, color: '#797979'},
                  }}
                  id="num"
                  variant="outlined"
                  label="Número"
                  color="primary"
                  value={num}
                  placeholder="Exemplo: 01"
                  type="number"
                  onChange={event => {
                    setNum(event.target.value);
                  }}
                />
                <TextField
                  style={{
                    marginTop: 20,
                    width: '45%',
                    marginBottom: 0,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 16, padding: 6, color: '#393939'},
                    maxLength: 100,
                  }}
                  InputLabelProps={{
                    style: {fontSize: 16, marginTop: -8, color: '#797979'},
                  }}
                  id="complemento"
                  variant="outlined"
                  label="Complemento (Opcional)"
                  color="primary"
                  value={comp}
                  placeholder="Exemplo: Apto 101"
                  onChange={event => {
                    setComp(event.target.value);
                  }}
                />
              </div>
              {isReadyToMap ? (
                <p
                  style={{
                    width: '90%',
                    padding: 10,
                    color: '#393939',
                    backgroundColor: '#e8ffff',
                    borderRadius: 10,
                  }}
                >
                  * Ajuste sua localização cliclando no mapa para facilitar as
                  retiradas e agilizar a chegada dos entregadores em seu
                  estabelecimento. Lembre-se a Irê it calcula o frete de acordo
                  com a distância entre o ponto de entrega e a localização da
                  loja.
                </p>
              ) : null}
              {isReadyToMap ? (
                <ContentMap
                  isSmall={false}
                  isVerySmall={false}
                  marker={marker}
                  setMarker={setMarker}
                />
              ) : null}
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default Address;
