import {Android, Apple, Launch} from '@mui/icons-material';
import {Button} from '@mui/material';
import React, {useLayoutEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import styled, {keyframes} from 'styled-components';

const fadeIn = keyframes`
  0% {
    transform : translateY(3em);
    opacity: 0;
  }
  50% {
    transform : translateY(3em);
    opacity: 0;
  }
  100% {
    transform : translateY(0em);
    opacity: 1;
  }
`;

const fadeInX = keyframes`
  0% {
    transform : translateX(-10em);
    opacity: 0;
  }
  50% {
    transform : translateX(-10em);
    opacity: 0;
  }
  100% {
    transform : translateX(0em);
    opacity: 1;
  }
`;

const EnterDiv = styled.div`
  animation-fill-mode: forwards;
  animation: 1000ms ${fadeIn} linear;
`;

const EnterXDiv = styled.div`
  animation-fill-mode: forwards;
  animation: 1000ms ${fadeInX} linear;
`;

const Banner = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  // eslint-disable-next-line no-unused-vars
  const [width, height] = useWindowSize();

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  function openDeepLink() {
    var url = 'ireit://';

    if (getParameterByName('mode') == 'carona') {
      if (getParameterByName('id') == '') {
        url = 'ireit://carona';
      } else {
        url = 'ireit://carona/' + getParameterByName('id');
      }
    } else if (getParameterByName('mode') == 'order') {
      if (getParameterByName('id') == '') {
        url = 'ireit://order';
      } else {
        url = 'ireit://order/' + getParameterByName('id');
      }
    } else if (getParameterByName('mode') == 'maindelivery') {
      url = 'ireit://maindelivery';
    } else if (getParameterByName('mode') == 'acompanhamento') {
      if (getParameterByName('id') == '') {
        url = 'ireit://acompanhamento';
      } else {
        url = 'ireit://acompanhamento/' + getParameterByName('id');
      }
    } else if (getParameterByName('mode') == 'loja') {
      if (getParameterByName('id') == '') {
        url = 'ireit://loja';
      } else {
        url = 'ireit://loja/' + getParameterByName('id');
      }
    } else {
      url = 'ireit://inicio';
    }
    window.open(url);
  }

  return (
    <div style={{width: width, height: 'auto'}}>
      {isVerySmall || isSmall ? (
        <div
          style={{
            background: 'linear-gradient(#06448e, #7c53e3, #b553e3)',
            width: '100%',
            height: 'auto',
            marginTop: 60,
            display: 'flex',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: width,
              marginBottom: 60,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: 30,
                width: 'auto',
                marginLeft: 30,
                marginRight: 30,
                marginTop: 20,
                fontWeight: 'bold',
                color: '#FFFFFF',
                textAlign: 'center',
              }}
            >
              Abrir o aplicativo da Irê it?
            </p>
            <p
              style={{
                fontSize: 18,
                width: '70%',
                marginLeft: 0,
                marginTop: 0,
                fontWeight: 'bold',
                color: '#FFFFFF',
                textAlign: 'center',
              }}
            >
              Você clicou em um link que deseja redirecionar para o App da Irê
              it. Deseja continuar?
            </p>
            <Button
              onClick={() => openDeepLink()}
              style={{
                textTransform: 'none',
                width: '70%',
                height: 60,
                fontWeight: 'normal',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 10,
                marginBottom: 0,
              }}
              sx={{
                color: '#ffffff',
                bgcolor: '#FF6600',
                ':hover': {
                  bgcolor: '#FFFFFF',
                  color: '#FF6600',
                },
              }}
            >
              <Launch />
              <div style={{marginLeft: 10}}>
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                  }}
                >
                  Abrir app
                </p>
                <p
                  style={{
                    marginTop: -10,
                    marginBottom: 0,
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    fontSize: 20,
                  }}
                >
                  Irê it
                </p>
              </div>
            </Button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 50,
                width: '100%',
              }}
            >
              <Button
                style={{
                  textTransform: 'none',
                  width: '70%',
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#06448e',
                  ':hover': {
                    color: '#ffffff',
                    bgcolor: '#06448e',
                  },
                }}
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.ireit&hl=pt"
              >
                <Android />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 12,
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                  >
                    Play Store
                  </p>
                </div>
              </Button>
              <Button
                style={{
                  textTransform: 'none',
                  width: '70%',
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  marginTop: 20,
                  marginBottom: 100,
                }}
                sx={{
                  color: '#06448e',
                  bgcolor: '#ffffff',
                  ':hover': {
                    color: '#06448e',
                    bgcolor: '#ffffff',
                  },
                }}
                target="_blank"
                href="https://apps.apple.com/us/app/irê-it/id6475050868"
              >
                <Apple />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 12,
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                  >
                    Apple Store
                  </p>
                </div>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            background: 'linear-gradient(#06448e, #7c53e3, #aa3cc9)',
            width: '100%',
            height: 'auto',
            marginTop: 60,
            display: 'flex',
            paddingBottom: 60,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <p
              style={{
                fontSize: 48,
                width: '100%',
                marginLeft: 0,
                marginTop: 60,
                fontWeight: 'bold',
                color: '#FFFFFF',
                textAlign: 'center',
              }}
            >
              Abrir o aplicativo da Irê it?
            </p>
            <EnterXDiv>
              <p
                style={{
                  fontSize: 28,
                  width: '100%',
                  marginLeft: 0,
                  marginTop: 10,
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                  textAlign: 'center',
                }}
              >
                Você clicou em um link que deseja redirecionar para o App da Irê
                it. Deseja continuar?
              </p>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  style={{
                    marginRight: 10,
                    textTransform: 'none',
                    width: 180,
                    height: 60,
                    fontWeight: 'normal',
                    alignItems: 'center',
                    marginLeft: 30,
                  }}
                  sx={{
                    color: '#ffffff',
                    bgcolor: '#06448e',
                    ':hover': {
                      bgcolor: '#FFFFFF',
                      color: '#06448e',
                    },
                  }}
                >
                  <Launch />
                  <div style={{marginLeft: 10}}>
                    <p
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                      }}
                    >
                      Abrir app
                    </p>
                    <p
                      style={{
                        marginTop: -10,
                        marginBottom: 0,
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                        fontSize: 20,
                      }}
                    >
                      Irê it
                    </p>
                  </div>
                </Button>
              </div>
            </EnterXDiv>

            <EnterDiv
              style={{
                display: 'flex',
                marginTop: 160,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{
                  marginRight: 10,
                  textTransform: 'none',
                  width: 180,
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  marginLeft: 30,
                }}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#ff6600',
                  ':hover': {
                    bgcolor: '#FFFFFF',
                    color: '#FF6600',
                  },
                }}
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.ireit&hl=pt"
              >
                <Android />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 20,
                    }}
                  >
                    Play Store
                  </p>
                </div>
              </Button>
              <Button
                style={{
                  marginRight: 10,
                  textTransform: 'none',
                  width: 180,
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  marginLeft: 30,
                }}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#ff6600',
                  ':hover': {
                    bgcolor: '#FFFFFF',
                    color: '#FF6600',
                  },
                }}
                target="_blank"
                href="https://apps.apple.com/us/app/irê-it/id6475050868"
              >
                <Apple />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 20,
                    }}
                  >
                    Apple Store
                  </p>
                </div>
              </Button>
            </EnterDiv>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
