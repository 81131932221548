import {Box} from '@mui/system';
import React from 'react';
import {useMediaQuery} from 'react-responsive';
// import { Container } from './styles';

const Body = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  return (
    <div style={{width: '100%', height: 'auto'}}>
      <div style={{background: '#ffffff'}}>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div className="post-content">
            <h3>Código de conduta da Irê it</h3>
            <p>
              O Código de Conduta da Irê it foi criado para ajudar a fazer com
              que toda experiência seja positiva, segura e respeitosa. Ele se
              aplica a todos que usam nossos apps, inclusive usuários,
              motoristas e entregadores parceiros, usuários da Irê it delivery e
              da Irê it serviços gerais, restaurantes e outros estabelecimentos.
              Além disso, trata das interações que você pode ter com
              colaboradores e terceirizados da Irê it, inclusive nos Espaços Irê
              it, por meio de sistemas de suporte online e pelo telefone.
            </p>
            <br />
            <p style={{marginBottom: 0, marginTop: 0}}>
              Agradecemos por nos ajudar a manter um ambiente acolhedor.
            </p>
            <br />
            <p>
              As diretrizes abaixo ajudam a explicar alguns tipos de
              comportamento que podem causar a perda de acesso aos apps da Irê
              it. Sempre haverá eventos imprevistos que poderão levar à perda de
              acesso aos apps da Irê it e estas diretrizes serão atualizadas com
              frequência. No entanto, as diretrizes a seguir são suficientes
              para justificar uma ação da Irê it, como a desativação da conta.
              Recomendamos que separe um tempo para a leitura completa deste
              documento.
            </p>
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <h3>Um código para todos</h3>
            <p>
              Todos que se cadastram para ter uma conta da Irê it precisam
              seguir o nosso Código de Conduta. Ele se baseia nos 3 pilares a
              seguir e nas normas contidas em cada uma destas seções.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>
              Trate todas as pessoas com respeito
            </h8>
            <br />
            <p>
              Nossa comunidade é amplamente diversa e, provavelmente, você
              encontrará pessoas diferentes de você ou com crenças que diferem
              das suas. As diretrizes desta seção ajudam a promover interações
              positivas em todas as experiências.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>
              Contribua com a segurança de todos
            </h8>
            <br />
            <p>
              Nossa equipe se empenha muito para ajudar a criar experiências
              mais seguras para todos. Sua segurança é nossa prioridade. Por
              isso, escrevemos este código. Além do Código de Conduta da Irê it,
              você encontrará também algumas normas exclusivas da Irê delivery e
              Irê it serviços gerais na seção “Outras diretrizes para a Irê it
              delivery e Irê it serviços gerais”.{' '}
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Cumpra a lei</h8>
            <br />
            <p>
              Temos o compromisso de cumprir com todas as leis e normas
              aplicáveis e conquistar sua confiança e esperamos que os usuários
              dos nossos apps façam sua parte e cumpram com as leis e normas
              aplicáveis, inclusive as que se aplicam a aeroportos.{' '}
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>
              Seus comentários são importantes
            </h8>
            <br />
            <p>
              Teve uma experiência ruim? Quer fazer um elogio? Compartilhe com a
              gente. Trabalhamos constantemente para aprimorar nossos padrões, e
              seus comentários são importantes para mantermos nossos padrões à
              medida que nossa tecnologia evolui.{' '}
            </p>
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <h3>Trate todas as pessoas com respeito</h3>
            <p>
              Trate todos os usuários do app da Irê it do mesmo modo como você
              quer ser tratado: com respeito. A forma como você usa os apps da
              Irê it faz a diferença na segurança e no conforto das outras
              pessoas. A cordialidade é muito importante. Por isso, esperamos
              que você use o bom senso e tenha consideração com os outros ao
              usar os apps da Irê it - o mesmo comportamento que você teria em
              um espaço público.
            </p>
            <br />
            <p>
              Por exemplo, seja pontual ao iniciar uma viagem ou retirar um
              pedido, pois ninguém gosta de esperar. É também uma questão de
              educação não gritar, xingar nem bater a porta do carro. Ajude a
              manter o veículo em boas condições para que o próximo usuário
              também possa fazer uma viagem agradável. Não deixe seu lixo para
              trás e limpe a sujeira que você fizer. Acima de tudo, lembre-se de
              que, ao usar os apps da Irê it, você encontrará pessoas
              diferentes, tanto na aparência como no modo de pensar. Respeite
              essas diferenças. Queremos que todos se sintam acolhidos e tenham
              uma experiência tranquila ao usar os apps da Irê it. Por isso,
              criamos normas que tratam de contato físico, violência e assédio,
              comportamento grosseiro e ameaçador, contato indesejado,
              discriminação e danos à propriedade.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Contato físico</h8>
            <p>
              Não toque em desconhecidos nem em alguém que tenha acabado de
              conhecer durante o uso dos apps da Irê it. Bater, machucar ou
              tentar ferir outra pessoa não é permitido sob nenhuma hipótese.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Violência e assédio</h8>
            <p>
              Todos nós gostamos que nosso espaço pessoal e nossa privacidade
              sejam respeitados. É permitido conversar. Porém, evite fazer
              comentários sobre a aparência ou perguntar se a pessoa é
              comprometida. A Irê it proíbe qualquer tipo de violência e
              assédio. Violência e assédio se referem a contato ou comportamento
              sexual sem consentimento explícito da outra pessoa.
            </p>
            <br />
            <p>
              Todos devem ter a privacidade e o espaço pessoal preservados. A
              lista a seguir apresenta alguns exemplos de condutas impróprias.
            </p>
            <Box
              boxShadow={3}
              borderRadius={2}
              style={{
                marginLeft: 10,
                marginRight: 10,
                padding: 10,
                marginBottom: 10,
              }}
            >
              <ulist>
                <li>
                  Comportamentos e comentários que podem fazer outras pessoas se
                  sentirem desconfortáveis são inaceitáveis. Por exemplo,
                  empurrões, assobios e piscadelas. Não toque em pessoas que
                  você não conhece nem flerte com elas.
                </li>
                <li>
                  Algumas conversas que parecem inofensivas podem ser ofensivas.
                  Por exemplo, evite fazer comentários sobre aparência,
                  identidade de gênero e orientação sexual. Evite também fazer
                  perguntas pessoais fora de contexto, como “você namora?” Evite
                  discutir sua vida sexual ou a de outras pessoas, usar
                  linguagem explícita e fazer piadas que envolvam sexo.
                </li>
                <li>
                  A Irê it proíbe contato sexual durante o uso dos nossos apps,
                  inclusive durante uma viagem ou entrega, independentemente de
                  você conhecer a pessoa ou haver consentimento. Isso inclui
                  relação sexual, solicitação de relação sexual, masturbação ou
                  tocar e expor partes íntimas do corpo.
                </li>
              </ulist>
            </Box>
            <h8 style={{fontWeight: 'bold'}}>
              Ameaças e comportamento grosseiro
            </h8>
            <p>
              A Irê it proíbe comportamentos agressivos, conflituosos e
              assediantes. Não use palavras ou gestos desrespeitosos,
              ameaçadores ou impróprios. Recomendamos evitar opiniões pessoais
              potencialmente polêmicas, como as relacionadas a religião e
              política.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Contato indesejado</h8>
            <p>
              A comunicação deve terminar quando a viagem ou a entrega for
              concluída, a menos que haja um motivo legítimo, como para devolver
              um objeto perdido. Por exemplo, não é permitido enviar mensagens,
              entrar em contato por redes sociais, ligar, visitar nem tentar
              visitar uma pessoa depois da conclusão de uma viagem ou entrega ou
              alguma outra prestação de serviço.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Discriminação</h8>
            <p>
              Todas as experiências na plataforma devem transmitir tranquilidade
              e segurança. Por isso, não toleramos qualquer tipo de conduta
              discriminatória, especialmente com base em características como
              idade, cor da pele, deficiência, identidade de gênero, estado
              civil, nacionalidade, cor/raça, religião, orientação sexual ou
              outra característica que seja protegida por lei. Por exemplo, é
              inaceitável se recusar a prestar serviços ou avaliar outro
              usuário, seja da Irê it delivery, motorista ou entregador
              parceiro, restaurante ou outro estabelecimento, com base nessas
              características. Além disso, não é permitido discriminar nenhum
              usuário do app da Irê it ou da Irê it delivery com base no seu
              destino ou local de entrega.
            </p>
            <br />
            <p>
              Sabemos que é importante poder decidir quando fazer entregas ou
              dirigir com a Irê it. No entanto, exceto por motivos de segurança,
              evite recusar ou cancelar solicitações intencionalmente por
              discriminação. Também evite usar recursos nos apps da Irê it para
              deixar de receber solicitações de viagem ou de entrega com a
              finalidade exclusiva de evitar regiões específicas, devido às
              características das pessoas ou dos negócios presentes nesses
              locais.
            </p>
            <br />
            <p>
              Também queremos ajudar a aumentar a disponibilidade de opções de
              mobilidade para usuários com deficiência. Por isso,
              disponibilizamos informações sobre esse assunto para motoristas
              parceiros, usuários dos apps da Irê it. Os motoristas parceiros
              precisam cumprir as leis aplicáveis em vigor relacionadas ao
              transporte de usuários com deficiência, inclusive o transporte de
              animais de serviço.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Danos à propriedade</h8>
            <p>
              Danos à propriedade são sempre inaceitáveis. Por exemplo,
              danificar meios de transporte solicitados pelos apps da Irê it
              (carros de motoristas parceiros, bicicletas e outros), quebrar ou
              vandalizar telefones ou tablets, derramar alimentos ou bebidas
              intencionalmente dentro do veículo do motorista parceiro, fumar
              dentro do carro ou vomitar no carro do motorista parceiro devido
              ao uso excessivo de álcool ou por outro motivo. Se você causar
              danos a alguma propriedade além do desgaste usual, será
              responsável pelo custo de limpeza e/ou reparo.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>
              Contribua com a segurança de todos
            </h8>
            <p>
              Um ambiente seguro requer a colaboração de todos. Por isso, temos
              normas para compartilhamento de contas, idade mínima de titulares
              de conta e muito mais.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Compartilhamento de conta</h8>
            <p>
              É proibido compartilhar contas da Irê it. Para usar qualquer app
              da Irê it, você precisa se registrar e manter a conta ativa. Por
              motivos de segurança, não deixe que outra pessoa use sua conta e
              nunca compartilhe informações pessoais, como nome de usuário,
              senha e dados biométricos.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Menores de 18 anos</h8>
            <p>
              Você precisa ter pelo menos 18 anos para ter uma conta da Irê it.
              Titulares de conta não podem solicitar viagens nem fazer pedidos
              para menores de 18 anos que não estarão acompanhados de um adulto
              durante a viagem ou o recebimento da entrega. Isso também se
              aplica à locação de bicicletas para menores de 18 anos utilizarem.
              Esta seção se aplica a todos os casos, a menos que haja uma
              divergência expressa no código, nos termos ou em outras políticas
              da Irê it.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Passageiros extras/pacotes</h8>
            <p>
              Em viagens pelo app da Irê it, apenas o usuário solicitante e seus
              convidados podem estar no veículo do motorista parceiro. Durante
              entregas de carro pela Irê it delivery, não pode haver outros
              usuários no veículo. Em viagens solicitadas pelo app da Irê it, o
              titular da conta é responsável pelo comportamento de todos os seus
              convidados. Se você solicitou uma viagem, fez um pedido ou algum
              outro serviço para outro adulto, você é responsável pelo
              comportamento dele durante a viagem, a prestação de serviço ou
              entrega.
            </p>
            <br />
            <p>
              Além disso, lembre-se de que o app da Irê it não é recomendado
              para ser usado como serviço de entregas. Os motoristas parceiros
              têm o direito de recusar ou cancelar a viagem, caso o usuário a
              solicite com o propósito de entregar um item ou pacote. Se optar
              por usar os apps da Irê it para entregar itens ou pacotes, você
              será o único responsável por tudo que ocorrer com eles. A Irê it
              não oferece seguro para esse tipo de item.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Informações do veículo</h8>
            <p>
              Para facilitar a partida ou a entrega, os apps da Irê it repassam
              aos usuários do app da Irê it informações que possibilitam
              identificar motoristas e entregadores parceiros (como foto do
              perfil e nome) e os veículos que eles usam (como placa, marca e
              modelo do carro). Informações imprecisas ou desatualizadas
              confundem usuários do app da Irê it e podem prejudicar a
              experiência com os nossos apps.
            </p>
            <br />
            <p>
              Motoristas e entregadores parceiros só podem usar veículos
              aprovados. Para oferecermos informações mais precisas, nos
              comunique sempre os dados do seu veículo e atualizações da sua
              documentação, como uma carteira de habilitação prestes a vencer.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Cinto de segurança</h8>
            <p>
              O cinto de segurança é a forma mais eficaz de salvar vidas e
              diminuir ferimentos relacionados a acidentes de trânsito. De
              acordo com a lei aplicável, todos que estiverem em um veículo
              devem usar o cinto, mesmo no banco de trás. O usuário precisa
              solicitar um carro que comporte o número de pessoas que viajarão
              com ele. De acordo com a lei aplicável, motoristas parceiros podem
              recusar a viagem se o número de passageiros ultrapassar a
              quantidade de cintos de segurança disponíveis no carro.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>
              Capacete e outras proteções para bicicletas e motos
            </h8>
            <p>
              Em cumprimento às leis locais e para a sua segurança, use capacete
              e outras proteções obrigatórias quando for andar de bicicleta ou
              moto. O capacete ajuda a proteger você, quando utilizado de acordo
              com as instruções do fabricante. À noite, recomendamos dirigir com
              mais cuidado e usar um colete fluorescente ou lanternas para que
              os outros motoristas possam te visualizar, evitando assim
              possíveis acidentes.
            </p>
            <br />
            <p>
              Para a sua proteção, recomendamos que você tome medidas adicionais
              de segurança conforme as condições climáticas: reduza a velocidade
              em dias de chuva, olhe duas vezes antes de trocar de faixa, teste
              os freios antes de iniciar uma viagem, tome muita água e use
              protetor solar e óculos escuros em dias de sol.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>
              Câmeras e outros aparelhos de gravação de vídeo e áudio
            </h8>
            <p>
              Todos os usuários dos apps da Irê it podem gravar viagens e
              entregas, integralmente ou em parte, na medida estipulada pela
              lei. Por exemplo, para documentar problemas que desejem relatar á
              Irê it ou às autoridades pertinentes. Leis e regulamentos
              aplicáveis podem exigir o consentimento e/ou a notificação de
              todos os presentes na gravação. Informe-se sobre a legislação na
              sua cidade para ver se é esse o caso.
            </p>
            <br />
            <p>
              É proibido transmitir imagens, áudios ou vídeos de outras pessoas.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Esteja sempre alerta</h8>
            <p>
              Faça sua parte no trânsito para promover sua própria segurança e a
              de outras pessoas. Mantenha a atenção no trânsito, não dirija
              cansado e fique alerta a situações que exijam ação rápida. Nós
              analisamos os relatos de usuários relacionados à direção perigosa.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Manutenção adequada do veículo</h8>
            <p>
              Por motivos de segurança, motoristas e entregadores parceiros
              deverão manter freios, cintos de segurança e pneus em boas
              condições, cumprindo com as normas legais de segurança e
              manutenção do setor.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Trânsito consciente</h8>
            <p>
              Trânsito seguro significa agir com segurança, por exemplo, ter
              cuidado com todas as pessoas, não importando seu meio de
              transporte.
            </p>
            <br />
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <h3>Outras diretrizes para a Irê it delivery</h3>
            <p>
              Além de seguir todas as regras do Código de Conduta da Irê it, as
              normas abaixo aplicam-se a pedidos e entregas pela Irê it
              delivery.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>
              Forma correta de lidar com pedidos
            </h8>
            <p>
              Restaurantes e outros estabelecimentos precisam cumprir com todos
              os requisitos de funcionamento aplicáveis, assim como a legislação
              referente à segurança e à higiene alimentar, às boas práticas do
              setor e às políticas da Irê it. Além disso, precisam manter sempre
              em dia seus alvarás e licenças.
            </p>
            <br />
            <p>
              Muitos alimentos são perecíveis e podem causar doenças quando
              manuseados incorretamente. Sem controles de tempo e temperatura
              antes da retirada do pedido, eles podem se tornar perigosos à
              saúde.
            </p>
            <br />
            <p>
              Para ajudar a garantir que os pedidos cheguem aos usuários da Irê
              it em segurança, recomendamos aos restaurantes e outros
              estabelecimentos parceiros a usar embalagens com lacre à prova de
              violações. Restaurantes e outros estabelecimentos devem agir com
              responsabilidade ao passar informações relacionadas a alergias,
              intolerâncias ou outras restrições alimentares aos usuários da Irê
              it e rejeitar todas as solicitações que não puderem cumprir.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Entrega adequada de pedidos</h8>
            <p>
              Restaurantes e outros estabelecimentos podem definir diretrizes
              com o objetivo de promover a segurança alimentar, cumprir as
              normas ou acomodar as restrições alimentares de usuários da Irê
              it. Por exemplo, eles podem pedir ao entregador parceiro da Irê it
              para manter os pedidos de comida dela separados dos demais. Por
              sua vez, entregadores parceiros da Irê it precisam sempre seguir
              as diretrizes dos restaurantes e de outros estabelecimentos.
            </p>
            <br />
            <p>
              Mochilas térmicas ajudam a proporcionar melhores experiências aos
              usuários da Irê it. Confira os requisitos a serem cumpridos na sua
              cidade. Para entregas com bicicleta, sugerimos usar uma mochila
              especial para evitar derramar o pedido e protegê-lo das
              intempéries.
            </p>
            <br />
            <p>
              Recomendamos que os entregadores parceiros da Irê it entreguemos
              pedidos com segurança, de acordo com padrões e diretrizes,
              observações ou solicitações dos restaurantes e outros
              estabelecimentos e/ou usuários da Irê it. Por exemplo, o usuário
              pode solicitar a entrega na calçada, ou o restaurante ou outro
              estabelecimento pode solicitar que os entregadores parceiros da
              Irê it tirem as mochilas térmicas das costas em espaços pequenos.
              Além disso, é importante que não sejam violadas nem abertas as
              embalagens durante a entrega. Isso pode causar contaminação dos
              alimentos, colocando a segurança alimentar dos usuários da Irê it
              em grande risco.
            </p>
            <br />
            <p>
              Ao entrar em um restaurante ou outro estabelecimento, sugerimos
              tirar o capacete, capuz ou outros itens que possam incomodar os
              clientes ou os funcionários do restaurante, estabelecimento ou
              praça de alimentação. Apresente-se à equipe e informe o pedido que
              veio retirar. Carregue sempre um documento oficial para poder se
              identificar, se necessário. Também sugerimos que você aguarde o
              pedido em um local que não interfira na operação do
              estabelecimento.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>
              Recebimento adequado de pedidos
            </h8>
            <p>
              Se um usuário da Irê it tiver problemas com o pedido, recomendamos
              entrar em contato com o parceiro responsável pela entrega e
              fornecer referências claras. Lembre-se de que o entregador
              parceiro não conhece o endereço e que essas orientações podem ser
              importantes para garantir que a entrega ocorra dentro do prazo.
              Lembre-se de que dar um valor extra é voluntário. É um
              agradecimento ao entregador e não implica em contrapartidas ou
              solicitações de serviços adicionais à entrega.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Espaço seguro para retiradas</h8>
            <p>
              Restaurantes e outros estabelecimentos devem ter uma área segura
              para retirada de pedidos e espera-se que eles e seus colaboradores
              se comportem adequadamente e com bom senso para receber os
              entregadores parceiros da mesma forma como ocorre com seus
              clientes. Por exemplo, restaurantes e outros estabelecimentos
              devem trabalhar para evitar incidentes violentos nas suas
              instalações que possam colocar a segurança dos entregadores
              parceiros em risco.
            </p>
            <h8 style={{fontWeight: 'bold'}}>Entrega de bebidas alcoólicas</h8>
            <p>
              Todos os pedidos que contêm bebida alcoólica precisam cumprir as
              leis e restrições aplicáveis com relação à entrega dessas bebidas
              fora do estabelecimento e às horas de serviço. Apenas usuários da
              Irê it maiores de idade (18 anos) que não estiverem sob efeito de
              álcool ou drogas podem pedir e receber entregas com bebidas
              alcoólicas, caso o estabelecimento as comercialize. Os usuários da
              Irê it deverão apresentar um documento oficial com foto para
              confirmar sua idade e identidade ao receber a entrega com bebida
              alcóolica. Se você não apresentar um documento oficial com foto,
              ou parecer estar sob efeito de drogas ou álcool, não poderá
              receber bebidas alcóolicas, que serão devolvidas ao vendedor no
              seu nome e às suas custas. Os usuários da Irê it não poderão fazer
              pedidos que contenham bebidas alcoólicas se o endereço de entrega
              for um local público onde é proibido portar garrafas abertas ou
              outra área onde a lei proíba a posse e o consumo de álcool.
            </p>
            <br />
            <p>
              A lei proíbe que entregadores parceiros entreguem bebidas
              alcóolicas a usuários da Irê it abaixo da idade legal mínima para
              consumi-las. É responsabilidade dos entregadores parceiros cumprir
              os requisitos locais para entregas de bebidas alcoólicas, que
              muitas vezes incluem pedir um documento ao usuário da Irê it para
              confirmar a idade e a identidade, não entregar caso a pessoa seja
              menor de idade ou pareça estar sob efeito de álcool ou drogas e
              devolver a bebida alcóolica não entregue ao restaurante ou outro
              estabelecimento. Os restaurantes e outros estabelecimentos podem
              ser solicitados a aceitar a devolução de bebidas alcoólicas que
              não puderam ser entregues, por qualquer motivo.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>
              Criação de uma experiência positiva para entregadores parceiros e
              usuários da Irê it
            </h8>
            <p>
              É importante que restaurantes e outros estabelecimentos,
              entregadores parceiros e usuários da Irê it tratem os entregadores
              parceiros e usuários da Irê it com respeito.
            </p>
            <br />
            <p>
              Restaurantes e outros estabelecimentos devem atender corretamente
              aos pedidos do usuário, pois receber itens errados ou pedidos
              incompletos pode prejudicar muito a experiência deles.
              Restaurantes e outros estabelecimentos devem ter a atenção de
              incluir os itens corretos nos pedidos dos usuários.
            </p>
            <br />
            <p>
              Tempos longos de preparo e disponibilização do pedido ao
              entregador parceiro (o tempo que leva para o entregador parceiro
              entrar e sair do restaurante ou outro estabelecimento com o
              pedido, inclusive o tempo de espera), pouco tempo online (o tempo
              de disponibilidade online do restaurante ou outro
              estabelecimento), demora na aceitação do pedido e taxa alta de
              cancelamentos também criam uma experiência negativa para os
              usuários e entregadores parceiros da Irê it .
            </p>
            <br />
            <p>
              Se essas métricas, ou outras, forem significativamente piores que
              a média dos demais restaurantes e estabelecimentos da cidade, elas
              poderão afetar negativamente as experiências das outras partes com
              o app da Irê it. Por isso, elas deveriam estar, preferencialmente,
              dentro dessa média. Informaremos se suas métricas estiverem abaixo
              do padrão mínimo. Os restaurantes e outros estabelecimentos podem
              monitorar suas métricas quando quiserem. Basta entrar em contato
              com a nossa equipe em caso de dúvidas.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Itens proibidos</h8>
            <p>
              Restaurantes e outros estabelecimentos devem vender apenas itens
              contemplados expressamente no contrato deles com a Irê it. Itens
              controlados ou ilícitos e medicamentos que não precisam de
              prescrição médica não podem ser oferecidos no cardápio ou catálogo
              da Irê it. A Irê it poderá remover qualquer item que considerar
              proibido ou inadequado do cardápio ou catálogo da Irê it. Também
              poderemos limitar de outra forma a capacidade de nossos parceiros
              de publicar tais itens nos seus cardápios.
            </p>
            <br />
            <p>
              Os usuários do app da Irê it devem deixar a condução a cargo do
              motorista ou entregador parceiro. Por exemplo, não toque no
              volante, no câmbio nem em outros botões ou componentes usados para
              operar o veículo. Não peça ao motorista ou entregador parceiro
              para acelerar ou fazer paradas, desembarques ou manobras ilegais.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Animais de serviço</h8>
            <br />
            <p>
              A lei exige que motoristas parceiros transportem pessoas
              acompanhadas de animais de serviço. Recusar uma viagem por causa
              de um animal de serviço pode resultar na perda de acesso aos apps
              da Irê it.
            </p>
            <br />
            <p>
              De acordo com a lei, animais de serviço devem estar devidamente
              identificados, caso contrário, o motorista parceiro pode solicitar
              a apresentação desses documentos.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Drogas e bebidas alcoólicas</h8>
            <p>
              É proibido consumir drogas e portar bebidas alcoólicas abertas
              durante o uso dos apps da Irê it.
            </p>
            <br />
            <p>
              Se você for um motorista, mototaxista ou entregador parceiro, é
              proibido por lei conduzir qualquer tipo de veículo sob efeitos do
              álcool. A lei proíbe você de dirigir, pilotar ou andar de
              bicicleta sob efeito de álcool, drogas ou qualquer substância que
              prejudique sua capacidade de operar um veículo com segurança. Se
              encontrar um usuário embriagado ou fora de si, você tem o direito
              de recusar a viagem para sua própria segurança.
            </p>
            <br />
            <p>
              Se você for um usuário e achar que o seu motorista está embriagado
              ou sob efeito de drogas, peça para interromper a viagem
              imediatamente. Saia do carro e ligue para 190. Depois de sair do
              veículo, relate sua experiência á Irê it.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Proibição de armas</h8>
            <p>
              Usuários e seus acompanhantes, além de motoristas e entregadores
              parceiros, não podem portar armas de fogo ou de qualquer outro
              tipo de arma (ex.: facas e tasers) durante o uso dos apps da Irê
              it, conforme a lei aplicável.{' '}
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Fraude e atividades ilegítimas</h8>
            <p>
              Além de serem perigosas, fraudes podem prejudicar a confiança. É
              proibido falsificar informações ou assumir a identidade de outra
              pessoa, por exemplo, durante o cadastro ou a checagem de
              antecedentes. É importante apresentar informações corretas ao
              relatar incidentes, criar e acessar suas contas da Irê it,
              contestar cobranças e taxas, bem como solicitar créditos. Solicite
              apenas valores e reembolsos a que você tem direito e use
              corretamente as ofertas e promoções. Não conclua transações
              inválidas propositalmente.
            </p>
            <br />
            <p>
              Alguns exemplos de atividades fraudulentas: enviar documentos
              adulterados ou modificados; aumentar de propósito o tempo ou a
              distância de uma viagem ou entrega; aceitar solicitações de
              viagem, pedido ou entrega sem intenção de concluí-las, inclusive
              provocar o cancelamento dos usuários; criar contas falsas;
              reivindicar taxas ou cobranças fraudulentas, como taxas de limpeza
              falsas; solicitar, aceitar ou concluir de propósito viagens ou
              entregas fraudulentas ou falsificadas; declarar que concluiu uma
              entrega sem ter retirado o pedido; retirar um pedido e ficar com
              parte dele ou não entregá-lo; agir com intenção de prejudicar ou
              manipular o funcionamento normal dos apps da Irê it, inclusive
              manipulação das configurações no telefone para impedir o
              funcionamento correto do app e do sistema de GPS; abusar de
              promoções e/ou não usá-las para o propósito destinado; contestar
              cobranças por motivos fraudulentos ou ilegítimos; ou criar contas
              duplicadas indevidas; ou falsificar documentos, registros ou
              outros dados com propósitos fraudulentos.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>
              Solicitação de viagens fora da plataforma e outras atividades
              inaceitáveis
            </h8>
            <p>
              Para maior segurança nas viagens, são proibidos embarques
              organizados fora do app. A lei proíbe a solicitação de viagens
              fora da nossa plataforma durante o uso dos apps da Irê it. Por
              isso, recomendamos que você nunca solicite nem aceite pagamentos
              fora do sistema da Irê it. Os usuários não devem solicitar viagens
              fora do sistema da Irê it. Para pagamentos em dinheiro, usuários
              do app da Irê it precisam ter a quantia correta em mãos para
              cobrir o custo da viagem ou da entrega. Motoristas e entregadores
              parceiros, por sua vez, precisam pagar a taxa de serviço da Uber
              referente a pagamentos em dinheiro dentro do prazo.
            </p>
            <br />
            <p>
              Nunca use a marca registrada nem a propriedade intelectual da Irê
              it sem permissão, pois isso pode ser prejudicial à empresa e à
              marca. Se for exigido pela lei local, motoristas e entregadores
              parceiros devem usar somente itens com o logotipo da Irê it
              adquiridos conosco. O uso de itens de terceiros sem autorização
              (como luzes, placas, sinais ou similares com o nome ou o logotipo
              da Irê it) pode confundir usuários do app da Irê it.
            </p>
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <h3>Seus comentários são importantes</h3>
            <p>
              Teve uma experiência ruim? Quer fazer um elogio? Compartilhe com a
              gente. Nossa equipe trabalha sempre para melhorar nossos padrões,
              e seus comentários são importantes para podermos acompanhar a
              evolução da nossa tecnologia. Ao final de cada viagem ou entrega,
              avalie sua experiência como motorista parceiro, entregador ou
              usuário. Comentários honestos ajudam a garantir que todos se
              responsabilizem por suas próprias condutas. Essa responsabilidade
              ajuda a criar um ambiente respeitoso e seguro. Se algo acontecer,
              como um acidente de trânsito, e você quiser reportar, toque em
              “Ajuda” no app ou envie um e-mail para o suporte@ireit.com.br para
              que nossa equipe de suporte possa entrar em contato com você. Em
              caso de emergência ou perigo imediato, alerte o serviço de
              emergência ou as autoridades locais antes de avisar á Irê it.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>Avaliações</h8>
            <p>
              Em todos os nossos apps, usuários, motoristas, mototaxistas e
              entregadores parceiros, restaurantes e outros estabelecimentos
              podem fazer e receber avaliações, além de escrever comentários
              sobre a viagem ou entrega. Esse sistema de avaliações ajuda a
              aumentar a responsabilidade e criar um ambiente respeitoso, seguro
              e transparente para todos. Motoristas, mototaxistas e entregadores
              parceiros podem ver sua avaliação atual na seção “Comentários” do
              app. Os restaurantes e outros estabelecimentos podem entrar no
              Portal do Painel do Administrador para ver as avaliações feitas
              pelos entregadores parceiros e usuários da Irê it. Os usuários
              podem encontrar sua avaliação no menu do app, embaixo do nome.
            </p>
            <br />
            <p>
              Cada cidade tem uma avaliação média mínima, pois pode haver
              diferenças culturais na maneira de as pessoas se avaliarem.
              Usuários, motoristas e entregadores parceiros, restaurantes e
              outros estabelecimentos que não atingirem a avaliação média mínima
              da cidade poderão perder o acesso ao app da Irê it. Informaremos
              se sua avaliação estiver abaixo da média mínima da sua cidade ou
              se ela estiver se aproximando desse limite, com informações para
              te ajudar a melhorá-la.
            </p>
            <br />
            <p>
              A avaliação dos motoristas e mototaxistas parceiros é a média das
              últimas 500 viagens avaliadas ou a média de todas as viagens
              avaliadas se o número total de viagens for inferior a 500. A
              avaliação dos usuários é a média de todas as viagens avaliadas. A
              avaliação dos entregadores parceiros é a média das últimas 100
              avaliações dos usuários, restaurantes e outros estabelecimentos
              parceiros da Irê it, ou a média de todas as avaliações se o número
              total delas estiver entre 10 e 99.
            </p>
            <br />
            <p>
              Para manter uma boa avaliação média, recomendamos tratar todas as
              pessoas com respeito e educação. Nossos motoristas e entregadores
              parceiros normalmente prestam um atendimento excelente aos
              usuários do app da Irê it, e a maioria desses usuários é educada e
              respeitosa. Assim, a maior parte das viagens e entregas ocorre sem
              problemas. Porém, sabemos que algumas vezes acontecem
              contratempos. Por isso, levamos em consideração uma média das suas
              avaliações.
            </p>
            <br />
            <p>
              Se você acha que um erro causou a perda de acesso à sua conta,
              entre em contato com a equipe de suporte da Irê it. Os usuários
              que perderem acesso à conta da Irê it não poderão acessar o app da
              Irê it. Fale com a equipe local da Irê it ou envie um e-mail para
              suporte@ireit.com.br para saber mais.
            </p>
            <br />
            <h8 style={{fontWeight: 'bold'}}>
              Aceitação de entregas, pedidos e viagens
            </h8>
            <p>
              Se você for um motorista, mototaxista ou entregador parceiro e não
              quiser aceitar solicitações de entrega ou viagem, basta ficar
              offline. Se você for um restaurante ou outro estabelecimento, você
              pode ficar com o status fechado na aba principal do gestor ou
              marque itens específicos como indisponíveis. Isso ajuda a manter o
              bom funcionamento do sistema para todos os usuários.
            </p>
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
            marginBottom: 40,
          }}
        >
          <div>
            <h3>Como a Irê it aplica o Código de Conduta</h3>
            <p>
              Perder o acesso aos apps da Irê it pode atrapalhar sua vida e seus
              negócios. Por isso, acreditamos ser importante ter normas claras
              que expliquem as circunstâncias que podem levar à perda do acesso
              aos apps da Irê it. Você pode perder o acesso aos apps da Irê it
              se violar os termos de uso aplicáveis, o acordo contratual que
              aceitou durante o cadastro para uma conta com á Irê it ou o Código
              de Conduta. Se você tiver mais de uma conta da Irê it, como uma
              conta de usuário e uma de motorista parceiro, a violação do Código
              de Conduta poderá levar à perda de acesso a todas as contas da Irê
              it.
            </p>
            <br />
            <p>
              A Irê it recebe comentários por vários canais, examina os relatos
              de violação do Código de Conduta enviados à nossa equipe de
              suporte e pode iniciar uma investigação com uma equipe
              especializada. Se formos informados sobre algum comportamento
              considerado problemático, poderemos entrar em contato com você
              para analisar o caso. Podemos, a nosso critério, desativar sua
              conta durante essa análise e sempre que houver indicações de
              possíveis violações ao nosso Código de Conduta.
            </p>
            <br />
            <p>
              Você pode perder o acesso às suas contas da Irê it se não seguir
              qualquer uma de nossas diretrizes. Isso pode incluir certas ações
              fora do app, se determinarmos que elas são uma ameaça à segurança
              da comunidade da Irê it, aos nossos colaboradores ou
              terceirizados, ou prejudicam a marca, a reputação ou os negócios
              da Irê it. Se os problemas levantados forem graves ou repetidos,
              ou se você se recusar a cooperar, poderá perder o acesso aos apps
              da Irê it. Qualquer comportamento que envolva violência, assédio,
              discriminação ou atividade ilegal ao usar os apps da Irê it pode
              resultar na perda imediata do acesso a eles. Além disso, quando
              autoridades policiais/jurídicas estiverem envolvidas, nós
              cooperaremos com a investigação de acordo com nossas Diretrizes
              para atendimento a exigências de autoridades policiais/jurídicas.
            </p>
            <br />
            <p>
              Finalmente, por motivos de segurança, todos os motoristas,
              mototaxistas e entregadores parceiros que quiserem usar os apps da
              Irê it precisarão passar por um processo de cadastro, que inclui a
              checagem de antecedentes criminais. Um motorista, mototaxista ou
              entregador parceiro perderá o acesso às suas contas da Irê it se a
              checagem de antecedentes ou outra verificação revelar uma violação
              do Código de Conduta da Irê it ou de outros critérios exigidos
              pelos reguladores locais.
            </p>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Body;
