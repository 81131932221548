/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {GoogleMap, useJsApiLoader} from '@react-google-maps/api';
import {Marker} from '@react-google-maps/api';

const ContentMap = ({isVerySmall, isSmall, marker, setMarker}) => {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyADIHr5UwS75lsAsHpdTHG2u5i9y2nBpO4',
  });

  const [zoom, setZoom] = useState(18.35);
  const [center, setCenter] = useState({lat: 0, lng: 0});
  const [map, setMap] = React.useState(null);
  const mapRef = useRef(GoogleMap);

  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds();
    //map.fitBounds(bounds);
    setMap(map);
    setZoom(18.35);
  }, []);

  useEffect(() => {
    setCenter({lat: marker.lat, lng: marker.lng});
  }, [marker]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  function handleClick(e) {
    setMarker({lat: e.latLng.lat(), lng: e.latLng.lng()});
    setCenter({lat: e.latLng.lat(), lng: e.latLng.lng()});
  }

  return isLoaded ? (
    <div
      style={{
        display: 'flex',
        width: 'auto',
        marginLeft: 10,
        marginRight: 10,
        alignItems: 'center',
        marginTop: 20,
      }}
    >
      <GoogleMap
        mapContainerStyle={{
          width: isVerySmall || isSmall ? '55%' : '100%',
          height: 300,
        }}
        center={{lat: center.lat, lng: center.lng}}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        ref={mapRef}
        onClick={e => handleClick(e)}
      >
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            top: isVerySmall || isSmall ? 50 : 50,
            left: 0,
            zIndex: 2,
            marginLeft: isVerySmall ? 0 : 10,
            marginRight: isVerySmall ? 0 : '0%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        ></div>
        <Marker
          icon={{
            // eslint-disable-next-line no-undef
            path: google.maps.SymbolPath.CIRCLE,
            scale: 6,
          }}
          position={marker}
        />
        <></>
      </GoogleMap>
    </div>
  ) : (
    <div style={{width: '100%', height: '500px'}}></div>
  );
};

export default ContentMap;
