import React, {useCallback, useState} from 'react';
import {Box} from '@mui/system';
import {TextField, Button} from '@mui/material';
import MuiPhoneInput from 'material-ui-phone-number';
import {Link} from 'react-router-dom';
import {isFullName} from '../../Utils/nameValidator';
import {isValidEmail} from '../../Utils/emailValidator';
import {isValidPhoneNumber} from 'libphonenumber-js';
import {useDispatch} from 'react-redux';
import * as ActionStore from '../../store/actions/setStore';

// eslint-disable-next-line react/prop-types
const RegisterBox = ({setOpenItem}) => {
  const dispatch = useDispatch();
  const [nome, setNome] = useState('');
  const [nomeError, setNomeError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [number, setNumber] = useState('');
  const [numberError, setNumberError] = useState(false);

  const onClickRegister = useCallback(() => {
    let hasError = false;

    if (isFullName(nome)) {
      setNomeError(false);
    } else {
      setNomeError(true);
    }

    if (isValidEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
      hasError = true;
    }

    if (isValidPhoneNumber(number)) {
      setNumberError(false);
    } else {
      setNumberError(true);
      hasError = true;
    }

    if (!hasError) {
      dispatch(ActionStore.setFirstStep(nome, email, number));
      setOpenItem(true);
    }
  }, [setOpenItem, nome, email, number]);

  return (
    <div>
      <Box
        boxShadow={3}
        borderRadius={5}
        style={{
          backgroundColor: '#F4F4F4',
          flex: 1,
          height: 'auto',
          zIndex: 5,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '90%',
          padding: 10,
          flexDirection: 'column',
        }}
      >
        <p
          style={{
            color: '#3d3d3d',
            fontSize: 28,
            fontWeight: 'bold',
            marginBottom: 0,
            marginLeft: 15,
          }}
        >
          Cadastre seu estabelecimento
        </p>
        <p
          style={{
            color: '#3d3d3d',
            fontSize: 16,
            fontWeight: 'normal',
            marginTop: 0,
            marginLeft: 15,
          }}
        >
          Aproveite a logística e a agilidade que a Irê it pode oferecer
        </p>
        <TextField
          style={{
            marginTop: 30,
            width: '90%',
            marginBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
          inputProps={{
            style: {fontSize: 20, padding: 6, color: '#393939'},
            maxLength: 100,
          }}
          InputLabelProps={{
            style: {fontSize: 20, marginTop: -8, color: '#797979'},
          }}
          id="nome"
          variant="outlined"
          label="Nome completo"
          color="primary"
          value={nome}
          onChange={event => {
            setNome(event.target.value);
          }}
          error={nomeError}
          helperText={nomeError ? 'Preencha o campo nome corretamente' : ''}
        />
        <TextField
          style={{
            marginTop: 10,
            width: '90%',
            marginBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
          inputProps={{
            style: {fontSize: 20, padding: 6, color: '#393939'},
            maxLength: 100,
          }}
          InputLabelProps={{
            style: {fontSize: 20, marginTop: -8, color: '#797979'},
          }}
          id="email"
          variant="outlined"
          label="Email"
          color="primary"
          value={email}
          onChange={event => {
            setEmail(event.target.value);
          }}
          error={emailError}
          helperText={emailError ? 'Preencha o campo email corretamente' : ''}
        />
        <MuiPhoneInput
          label="Contato"
          variant="outlined"
          value={number}
          style={{
            marginLeft: 10,
            marginTop: 10,
            marginBottom: 10,
            width: '90%',
          }}
          defaultCountry="br"
          regions={['america']}
          onChange={value => setNumber(value)}
          error={numberError}
          helperText={
            numberError ? 'Por favor, insira um número de telefone válido' : ''
          }
        />
        <Button
          style={{
            width: '95%',
            marginTop: 20,
            marginBottom: 0,
            textTransform: 'none',
            fontWeight: '100',
            fontStyle: 'normal',
            fontSize: 24,
            justifyContent: 'center',
            alignSelf: 'center',
          }}
          sx={{
            color: '#ffffff',
            bgcolor: '#06448e',
            ':hover': {
              bgcolor: '#FF6600',
              color: '#ffffff',
            },
          }}
          onClick={() => onClickRegister()}
        >
          Cadastrar
        </Button>
        <p
          style={{
            color: '#3a3a4a',
            fontSize: 16,
            fontWeight: 'normal',
            marginTop: 10,
            marginLeft: 15,
            marginBottom: 20,
          }}
        >
          Ao clicar no botão cadastrar você concorda em receber mensagens da Irê
          it. Confira nossa{' '}
          <Link
            style={{
              color: '#06448E',
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
            to="/politica_de_privacidade"
          >
            política de privacidade
          </Link>{' '}
        </p>
      </Box>
    </div>
  );
};

export default RegisterBox;
