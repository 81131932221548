import React from 'react';
import {useMediaQuery} from 'react-responsive';
import bannerimage from '../../Assets/Law.jpg';

const Hearder = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  return (
    <div>
      {isVerySmall || isSmall ? (
        <div
          style={{
            width: '100%',
            height: 300,
            marginTop: 60,
            marginBottom: 0,
            display: 'flex',
            backgroundImage: `url(${bannerimage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              width: '100%',
              fontSize: 36,
              color: '#FFFFFF',
              marginTop: 0,
              background: 'rgba(0, 0, 0, 0.5)',
              paddingBottom: 0,
              marginBottom: 0,
              paddingTop: 0,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            Termos de uso
          </p>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: 650,
            marginTop: 10,
            display: 'flex',
            backgroundImage: `url(${bannerimage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              width: '100%',
              fontSize: 60,
              color: '#FFFFFF',
              marginTop: 120,
              background: 'rgba(0, 0, 0, 0.5)',
              paddingTop: 0,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            Termos de uso
          </p>
        </div>
      )}
    </div>
  );
};

export default Hearder;
