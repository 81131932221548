/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  CircularProgress,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import InputMask from 'react-input-mask';
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {validarCNPJ} from '../../../../Utils/cnpjValidator';
import MuiPhoneInput from 'material-ui-phone-number';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(() => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
}));

const Estabelecimento = ({
  cnpj,
  setCnpj,
  razaoSocial,
  setRazaoSocial,
  estNome,
  setEstNome,
  phone,
  setPhone,
  cat,
  setCat,
  cnpjError,
  setCnpjError,
  cnpjErrorMsg,
  fetchRazaoSocial,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const cashOwner = useSelector(state => state.setstore);

  const cats = [
    {
      title: 'Mercado',
    },
    {
      title: 'Lanches',
    },
    {
      title: 'Pizza',
    },
    {
      title: 'Gelados',
    },
    {
      title: 'Doces',
    },
    {
      title: 'Salgados',
    },
    {
      title: 'Açaí',
    },
    {
      title: 'Refeição',
    },
    {
      title: 'Oriental',
    },
    {
      title: 'Natural',
    },
    {
      title: 'Hortifruti',
    },
    {
      title: 'Açougue',
    },
    {
      title: 'Cafeteria',
    },
    {
      title: 'Frios',
    },
    {
      title: 'Padaria',
    },
    {
      title: 'Bebidas',
    },
    {
      title: 'Gás e Água',
    },
    {
      title: 'Farmácia',
    },
    {
      title: 'Roupa',
    },
    {
      title: 'Cosmédico',
    },
    {
      title: 'Eletrônicos',
    },
    {
      title: 'Livraria',
    },
    {
      title: 'Pet',
    },
    {
      title: 'Variedades',
    },
    {
      title: 'Mercearia',
    },
  ];

  useEffect(() => {
    setPhone(cashOwner.contato);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();

  const handleChange = event => {
    const valorCnpj = event.target.value;
    setCnpj(valorCnpj);

    if (validarCNPJ(valorCnpj)) {
      fetchRazaoSocial(valorCnpj);
    } else {
      setCnpjError(true);
    }
  };

  const handleCatChange = event => {
    setCat(event.target.value);
  };

  useEffect(() => {}, [razaoSocial]);

  return (
    <main>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={classes.root}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{}}>
          {isSmall ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: isVerySmall
                  ? width * 0.73
                  : isSmall
                  ? width * 0.675
                  : width,
                marginLeft: 0,
                marginTop: 10,
              }}
            >
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Informações do estabelecimento
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontSize: 16,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Preencha as informações sobre seu negócio
              </p>
              <InputMask
                mask="99.999.999/9999-99" // Máscara para o CEP
                value={cnpj}
                onChange={handleChange}
              >
                {() => (
                  <TextField
                    style={{
                      marginTop: 20,
                      width: '90%',
                      marginBottom: 20,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                    inputProps={{
                      style: {fontSize: 16, padding: 6, color: '#393939'},
                      maxLength: 100,
                    }}
                    InputLabelProps={{
                      style: {fontSize: 16, marginTop: -8, color: '#797979'},
                    }}
                    id="cnpj"
                    variant="outlined"
                    label="CNPJ do estabelecimento"
                    color="primary"
                    value={cnpj}
                    placeholder="Digite o CNPJ do estabelecimento... "
                    error={cnpjError}
                    helperText={cnpjError ? 'CNPJ inválido' : ''}
                  />
                )}
              </InputMask>
              <TextField
                style={{
                  marginTop: 10,
                  width: '90%',
                  marginBottom: 15,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="razaoSocial"
                variant="outlined"
                label="Razão Social"
                color="primary"
                value={razaoSocial}
                placeholder="Digite a Razão Social do CNPJ"
                onChange={event => {
                  setRazaoSocial(event.target.value);
                }}
              />
              <TextField
                style={{
                  marginTop: 10,
                  width: '90%',
                  marginBottom: 15,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="razaoSocial"
                variant="outlined"
                label="Nome da loja (Como será mostrado no App)"
                color="primary"
                value={estNome}
                placeholder="Digite o nome da sua loja"
                onChange={event => {
                  setEstNome(event.target.value);
                }}
              />
              <MuiPhoneInput
                label="Contato do estabelecimento"
                variant="outlined"
                value={phone}
                style={{
                  marginLeft: 10,
                  marginTop: 10,
                  marginBottom: 10,
                  width: '90%',
                }}
                defaultCountry="br"
                regions={['america']}
                onChange={value => setPhone(value)}
              />
              <FormControl
                style={{width: '90%', marginTop: 20, marginLeft: 10}}
              >
                <InputLabel style={{marginTop: -8}}>Categoria</InputLabel>
                <Select value={cat} onChange={handleCatChange}>
                  {cats.map(item => (
                    <MenuItem key={item.title} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Informações do estabelecimento
              </p>
              <p
                style={{
                  marginLeft: 10,
                  color: '#3d3d3d',
                  fontSize: 16,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Preencha as informações sobre seu negócio
              </p>
              <InputMask
                mask="99.999.999/9999-99" // Máscara para o CEP
                value={cnpj}
                onChange={handleChange}
              >
                {() => (
                  <TextField
                    style={{
                      marginTop: 20,
                      width: '90%',
                      marginBottom: 20,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                    inputProps={{
                      style: {fontSize: 16, padding: 6, color: '#393939'},
                      maxLength: 100,
                    }}
                    InputLabelProps={{
                      style: {fontSize: 16, marginTop: -8, color: '#797979'},
                    }}
                    id="cnpj"
                    variant="outlined"
                    label="CNPJ do estabelecimento"
                    color="primary"
                    value={cnpj}
                    placeholder="Digite o CNPJ do estabelecimento... "
                    error={cnpjError}
                    helperText={cnpjError ? 'CNPJ inválido' : ''}
                  />
                )}
              </InputMask>
              <TextField
                style={{
                  marginTop: 10,
                  width: '90%',
                  marginBottom: 15,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="razaoSocial"
                variant="outlined"
                label="Razão Social"
                color="primary"
                value={razaoSocial}
                placeholder="Digite a Razão Social do CNPJ"
                onChange={event => {
                  setRazaoSocial(event.target.value);
                }}
              />
              <TextField
                style={{
                  marginTop: 10,
                  width: '90%',
                  marginBottom: 15,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 16, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 16, marginTop: -8, color: '#797979'},
                }}
                id="razaoSocial"
                variant="outlined"
                label="Nome da loja (Como será mostrado no App)"
                color="primary"
                value={estNome}
                placeholder="Digite o nome da sua loja"
                onChange={event => {
                  setEstNome(event.target.value);
                }}
              />
              <MuiPhoneInput
                label="Contato do estabelecimento"
                variant="outlined"
                value={phone}
                style={{
                  marginLeft: 10,
                  marginTop: 10,
                  marginBottom: 10,
                  width: '90%',
                }}
                defaultCountry="br"
                regions={['america']}
                onChange={value => setPhone(value)}
              />
              <FormControl
                style={{width: '90%', marginTop: 20, marginLeft: 10}}
              >
                <InputLabel style={{marginTop: -8}}>Categoria</InputLabel>
                <Select value={cat} onChange={handleCatChange}>
                  {cats.map(item => (
                    <MenuItem key={item.title} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default Estabelecimento;
