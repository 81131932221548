import {Button, Divider} from '@mui/material';
import React, {useLayoutEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import logo from '../Assets/logo.png';
import {IconButton} from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import {Android, Apple} from '@mui/icons-material';
import {Link} from 'react-router-dom';

const Footer = () => {
  const isSmall = useMediaQuery({query: '(max-width: 790.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  // eslint-disable-next-line no-unused-vars
  const [width, height] = useWindowSize();

  return (
    <div
      style={{
        width: '100%',
        background: '#06448E',
        display: 'flex',
        paddingBottom: 60,
        overflowX: 'hidden',
      }}
    >
      {isVerySmall || isSmall ? (
        <div
          style={{
            width: '100%',
            background: '#06448E',
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <div>
            <Button
              component={Link}
              to="/"
              style={{
                width: 95,
                height: 47,
                marginLeft: 12,
                backgroundImage: `url(${logo})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            />
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-around',
                marginBottom: 5,
              }}
            >
              <Button
                style={{
                  textTransform: 'none',
                  width: '45%',
                  height: 40,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#ff6600',
                  ':hover': {
                    bgcolor: '#FFffff',
                    color: '#FF6600',
                  },
                }}
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.ireit&hl=pt"
              >
                <Android />
                <div style={{marginLeft: 5}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 12,
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                  >
                    Play Store
                  </p>
                </div>
              </Button>
              <Button
                style={{
                  textTransform: 'none',
                  width: '45%',
                  height: 40,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  marginTop: 20,
                }}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#ff6600',
                  ':hover': {
                    bgcolor: '#FFffff',
                    color: '#FF6600',
                  },
                }}
                target="_blank"
                href="https://apps.apple.com/us/app/irê-it/id6475050868"
              >
                <Apple />
                <div style={{marginLeft: 5}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 12,
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                  >
                    Apple Store
                  </p>
                </div>
              </Button>
            </div>
          </div>
          <div
            style={{
              marginLeft: 5,
              marginRight: 5,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                color: '#FFFFFF',
                fontWeight: '500',
                marginBottom: 0,
                marginLeft: 5,
              }}
            >
              Ajuda
            </p>
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
              component={Link}
              to="/ajuda"
            >
              Dúvidas frequentes
            </Button>
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
              onClick={() =>
                window.open(
                  'mailto:suporte@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              suporte@ireit.com.br
            </Button>
          </div>
          <div
            style={{
              marginLeft: 5,
              marginRight: 5,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                color: '#FFFFFF',
                fontWeight: '500',
                marginTop: 5,
                marginBottom: 0,
                marginLeft: 5,
              }}
            >
              Empresa
            </p>
            {/*
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
            >
              Sobre
            </Button>
            */}
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
              component={Link}
              to="/politica_de_privacidade"
            >
              Política de privacidade
            </Button>
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
              component={Link}
              to="/termos_de_uso"
            >
              Termos de uso
            </Button>
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
              component={Link}
              to="/codigo_de_conduta"
            >
              Código de conduta
            </Button>
          </div>
          <div
            style={{
              marginLeft: 5,
              marginRight: 5,
              display: 'flex',
              flexDirection: 'column',
              marginTop: 0,
            }}
          >
            <p
              style={{
                color: '#FFFFFF',
                fontWeight: '500',
                marginBottom: 0,
                marginTop: 5,
                marginLeft: 5,
              }}
            >
              Produtos
            </p>
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              component={Link}
              to="/ajuda"
            >
              Solicitar viagem
            </Button>
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 0,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              component={Link}
              to="/ajuda"
            >
              Fazer viagens pela Irê it
            </Button>
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              component={Link}
              to="/ajuda"
            >
              Solicitar entrega
            </Button>
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 0,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              component={Link}
              to="/ajuda"
            >
              Quero fazer entregas pela Irê it
            </Button>
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              component={Link}
              to="/ajuda"
            >
              Como fazer um pedido?
            </Button>
            <Button
              style={{
                color: '#FFFFFF',
                marginTop: 0,
                marginBottom: 5,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              component={Link}
              to="/ajuda"
            >
              Sua loja na Irê it
            </Button>
          </div>

          <Divider style={{marginRight: 10}} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginLeft: -5,
            }}
          >
            <IconButton
              aria-label="instagram"
              disabled
              style={{color: '#ffffff'}}
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              aria-label="facebook"
              disabled
              style={{color: '#ffffff'}}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              aria-label="youtube"
              disabled
              style={{color: '#ffffff'}}
            >
              <YouTubeIcon />
            </IconButton>
            <IconButton
              aria-label="twitter"
              disabled
              style={{color: '#ffffff'}}
            >
              <TwitterIcon />
            </IconButton>
          </div>
          <Divider style={{marginRight: 10}} />
          <div style={{marginLeft: 10, marginRight: 10}}>
            <p style={{fontStyle: 'italic', color: '#ffffff', fontSize: 12}}>
              @2022 Irê it - 38.095.254/0001-03 - Lapão - BA - 44.905-000
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            background: '#06448e',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '20%',
            }}
          >
            <div>
              <Button
                component={Link}
                to="/"
                style={{
                  width: 95,
                  height: 47,
                  marginLeft: 12,
                  backgroundImage: `url(${logo})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              />
              <Button
                style={{
                  textTransform: 'none',
                  width: '80%',
                  height: 40,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 10,
                  marginTop: 20,
                }}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#ff6600',
                  ':hover': {
                    bgcolor: '#FFffff',
                    color: '#FF6600',
                  },
                }}
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.ireit&hl=pt"
              >
                <Android />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 12,
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                  >
                    Play Store
                  </p>
                </div>
              </Button>
              <Button
                style={{
                  textTransform: 'none',
                  width: '80%',
                  height: 40,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  marginTop: 20,
                  marginLeft: 10,
                }}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#ff6600',
                  ':hover': {
                    bgcolor: '#FFffff',
                    color: '#FF6600',
                  },
                }}
                target="_blank"
                href="https://apps.apple.com/us/app/irê-it/id6475050868"
              >
                <Apple />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 12,
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                  >
                    Apple Store
                  </p>
                </div>
              </Button>
            </div>
            <div
              style={{
                marginBottom: 10,
                marginLeft: 10,
                marginRight: 10,
                width: 200,
              }}
            >
              <p
                style={{
                  fontStyle: 'italic',
                  color: '#ffffff',
                  fontSize: 12,
                  marginBottom: 0,
                }}
              >
                @2022 Irê it - 38.095.254/0001-03 - Lapão - BA - 44.905-000
              </p>
            </div>
          </div>
          <div
            style={{
              marginLeft: 5,
              marginRight: 5,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                color: '#FFFFFF',
                fontWeight: '500',
                marginTop: 5,
                marginBottom: 0,
                marginLeft: 5,
              }}
            >
              Empresa
            </p>
            {/*
            <Button
              style={{
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
            >
              Sobre
            </Button>
            */}
            <Button
              style={{
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
              component={Link}
              to="/politica_de_privacidade"
            >
              Política de privacidade
            </Button>
            <Button
              style={{
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
              component={Link}
              to="/termos_de_uso"
            >
              Termos de uso
            </Button>
            <Button
              style={{
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
              component={Link}
              to="/codigo_de_conduta"
            >
              Código de conduta
            </Button>
          </div>
          <div
            style={{
              marginLeft: 5,
              marginRight: 5,
              display: 'flex',
              flexDirection: 'column',
              marginTop: 0,
            }}
          >
            <p
              style={{
                color: '#FFFFFF',
                fontWeight: '500',
                marginBottom: 0,
                marginTop: 5,
                marginLeft: 5,
              }}
            >
              Produtos
            </p>
            <Button
              style={{
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
              component={Link}
              to="/ajuda"
            >
              Solicitar viagem
            </Button>
            <Button
              style={{
                marginTop: 0,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
              component={Link}
              to="/ajuda"
            >
              Fazer viagens pela Irê it
            </Button>
            <Button
              style={{
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
              component={Link}
              to="/ajuda"
            >
              Solicitar entrega
            </Button>
            <Button
              style={{
                marginTop: 0,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
              component={Link}
              to="/ajuda"
            >
              Quero fazer entregas pela Irê it
            </Button>
            <Button
              style={{
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
              component={Link}
              to="/ajuda"
            >
              Como fazer um pedido?
            </Button>
            <Button
              style={{
                marginTop: 0,
                marginBottom: 5,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                justifyContent: 'flex-start',
                fontSize: 12,
              }}
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
              component={Link}
              to="/ajuda"
            >
              Sua loja na Irê it
            </Button>
          </div>
          <div
            style={{
              marginLeft: 5,
              marginRight: 5,
              display: 'flex',
              flexDirection: 'column',
              marginTop: 0,
            }}
          >
            <p
              style={{
                color: '#FFFFFF',
                fontWeight: '500',
                marginBottom: 0,
                marginLeft: 5,
                marginTop: 5,
              }}
            >
              Ajuda
            </p>
            <Button
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
              style={{
                marginTop: 5,
                marginBottom: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
              component={Link}
              to="/ajuda"
            >
              Dúvidas frequentes
            </Button>
            <Button
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                },
              }}
              style={{
                marginTop: 0,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 12,
                justifyContent: 'flex-start',
              }}
              onClick={() =>
                window.open(
                  'mailto:suporte@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              suporte@ireit.com.br
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'column',
              marginRight: 20,
            }}
          >
            <Button
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                  borderColor: '#ff6600',
                },
              }}
              variant="outlined"
              startIcon={<InstagramIcon />}
            >
              Instagram
            </Button>
            <Button
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                  borderColor: '#ff6600',
                },
              }}
              variant="outlined"
              startIcon={<FacebookIcon />}
            >
              Facebook
            </Button>
            <Button
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                  borderColor: '#ff6600',
                },
              }}
              variant="outlined"
              startIcon={<YouTubeIcon />}
            >
              YouTube
            </Button>
            <Button
              sx={{
                color: '#ffffff',
                ':hover': {
                  bgcolor: '#06448e',
                  color: '#ff6600',
                  borderColor: '#ff6600',
                },
              }}
              variant="outlined"
              startIcon={<TwitterIcon />}
            >
              Twitter
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
